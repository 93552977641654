html,
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 15px;
  line-height: 24px;
  color: #484848;
  max-width: 2560px;
  margin-right: auto !important;
  margin-left: auto !important;
  font-family: "Roboto", sans-serif;
  overflow: visible !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  text-transform: uppercase;
  color: #484848;
}

h1 {
  font-size: 70px;
  margin: 0;
  line-height: 72px;
}

h2 {
  font-size: 30px;
  margin: 0 0 20px;
}

h3 {
  font-size: 20px;
  margin: 0 0 10px;
}

img {
  width: 100%;
}

a,
button {
  outline: 0 !important;
}

a:hover,
a:focus {
  text-decoration: none;
}

p {
  color: #484848;
}

/* All Transition */

.list-inline.info a,
.list-inline.social_icon a,
nav.navbar.bootsnav ul.navbar-nav > li > a,
.attr-nav > ul > li > a,
.btn.know_btn,
#filters > button,
.portfolio_hover_area,
.portfolio_hover_area .fa,
.testimonial_content,
.testimonial_content p:first-child::before,
#contact_form .form-control,
#contact_form .btn.submit_btn,
footer a,
.footer_social_icon .fa,
.post .date,
#scrollUp {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

#loading {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
  left: 0;
  right: 0;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 150px;
  width: 150px;
  margin-left: -75px;
  margin-top: -75px;
}

.object {
  width: 8px;
  height: 50px;
  margin-right: 5px;
  background-color: #fff;
  -webkit-animation: animate 1s infinite;
  animation: animate 1s infinite;
  float: left;
}

.object:last-child {
  margin-right: 0px;
}

.object:nth-child(10) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.object:nth-child(9) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.object:nth-child(8) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.object:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.object:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.object:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.object:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.object:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.object:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@-webkit-keyframes animate {
  50% {
    -ms-transform: translateX(-25px) scaleY(0.5);
    -webkit-transform: translateX(-25px) scaleY(0.5);
    transform: translateX(-25px) scaleY(0.5);
  }
}

@keyframes animate {
  50% {
    -ms-transform: translateX(-25px) scaleY(0.5);
    -webkit-transform: translateX(-25px) scaleY(0.5);
    transform: translateX(-25px) scaleY(0.5);
  }
}
.top_nav {
  background: #ffcb0f none repeat scroll 0 0;
  overflow: hidden;
  padding: 10px 0;
}

.list-inline.info {
  float: left;
  margin: 0;
}

.list-inline.info > li {
  padding: 0 20px 0 0;
}

.list-inline.info a,
.list-inline.social_icon a {
  color: #222;
  font-family: "futura_ltbook", sans-serif;
}

.list-inline.info a:hover,
.list-inline.social_icon a:hover {
  color: #555;
}

.list-inline.info a .fa,
.list-inline.social_icon a .fa {
  font-size: 20px;
  vertical-align: text-top;
}

.list-inline.social_icon {
  float: right;
  margin: 0;
}

nav.navbar.bootsnav ul.navbar-nav > li > a {
  padding: 16px 15px 10px;
}

.profileDropdown ul li {
  width: 100%;
  text-align: left;
  display: table;
  border: 1px solid #d3d3d3;
}
/* Main Nav */
.language_tranlation {
  width: 7%;
  text-align: center;
  position: relative;
  cursor: pointer;
  float: left;
}
.language_tranlation img {
  width: auto;
}
.menuCategory_Wrapper {
  width: 100%;
  float: left;
  margin-top: 5px;
}
header {
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  float: left;
}
.headerTopBar {
  width: 100%;
  float: left;
}
.headerTopBar .basketSection .libraryBskDiv {
  cursor: pointer;
  margin-top: 0px;
}
.languageSelection {
  float: right;
  width: 50%;
  text-align: right;
}
.modal .signup-model {
  max-width: 850px;
}
.model-title-sub {
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.82;
  letter-spacing: 0.5px;
  text-align: center;
  color: #0a1735;
  margin: 15px 0px;
  text-transform: initial;
}
.signup-model .modal-content {
  background-color: #eaeaf0 !important;
  font-weight: 400;
}
.signup-model .signupWrapper {
  width: 36%;
  margin: 48px auto;
  display: table;
}
.signup-page {
  margin-bottom: 40px;
}
.signup-page .signupWrapper {
  width: 50%;
  margin: 48px auto;
  display: table;
}
.signup-model h4.createAccount {
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-size: 34px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.8px;
  color: #0a1735;
  text-transform: initial;
}
.terms-of-Service {
  display: table;
  text-align: center;
  width: 100%;
  text-transform: initial;
  color: #000;
  padding-top: 40px;
}
.terms-of-Service a {
  color: #212529;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
}
.signup-model .modal-header {
  border-bottom: none;
}

.libraryBskDiv p {
  font-weight: 400;
  font-size: 15px;
  color: #000;
}

.basketSection {
  float: right;
}

.libraryBskDiv p span {
  color: #da145a;
  margin-left: 5px;
  font-weight: 400;
  font-size: 15px;
}
.navbarLeft {
  width: 77%;
  float: left;
  padding: 0px !important;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
}
.desktopMenu ul {
  width: 94%;
  float: left;
  align-items: center;
  justify-content: center;
}

nav.navbar {
  background: #fff none repeat scroll 0 0;
  border: 0 none;
  padding-top: 8px;
  padding-bottom: 14px;
}
.category_section {
  width: 50%;
  float: left;
}
nav.navbar ul.navbar-nav > li > a {
  color: #000000;
}
.browseDropdown a {
  float: left;
}
.browseDropdown .browseMenuWrapper .btn-secondary {
  color: #000;
  background-color: #fff !important;
  border-color: #fff !important;
  height: 17px;
  display: flex;
  flex-direction: column-reverse;
}
.browseMenuWrapper .btn-secondary.focus,
.browseMenuWrapper .btn-secondary:focus {
  box-shadow: none !important;
}
.profileDropdown .nav li.nav-item a .dropdown-item {
  padding: 0px;
}
.browseDropdown .browseMenuWrapper {
  position: inherit;
}
.browseMenuWrapper .dropdown-menu {
  width: 100%;
  top: 50px !important;
  left: 0px !important;
  right: 0px !important;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 0px;
}
.browseMenuWrapper .dropdown-menu {
  transform: none !important;
}
.menuCategory_Wrapper .category_section .dropdown-item {
  padding: 0px;
}
.headerRightCont .navbar-nav li .menuCategory_Wrapper ul li {
  padding: 0px;
}
.headerRightCont .navbar {
  position: inherit;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: transparent !important;
}
.browseMenuWrapper .dropdown-toggle {
  padding: 0px;
}
nav.navbar.bootsnav ul.navbar-nav > li.browseDropdown > a {
  padding-right: 10px;
}
.browseDropdown .fa-sort-down {
  padding-top: 11px;
  display: table;
  cursor: pointer;
}
.headerLeft_Section .navbar {
  position: inherit;
}
.navbar-brand img {
  width: 100%;
}
.topLanguage {
  width: 24%;
  float: right;
  margin-top: 0px;
  border-left: 1px solid #b4b4b4;
  padding-right: 5px;
}
.btmHeader {
  float: left;
  width: 76%;
  padding-left: 0px;
  padding-right: 0px;
}
.browseDropdown .fa-sort-down:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
}
.browseDropDownMenu {
  position: absolute;
  width: 74%;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  background-color: #fff;
  padding: 18px;
  z-index: 99;
  top: 99px;
}
.browseDropDownTitle span {
  margin-left: 10px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 18px;
  margin-top: 10px;
  float: left;
}
.browseDropDownTitle img {
  width: 32px !important;
  float: left;
}
.browseDropDownTitle {
  border-bottom: 1px solid #000;
  float: left;
  padding-bottom: 10px;
  width: 100%;
  padding-top: 20px;
}
.closeBrowseMenu {
  float: right;
  cursor: pointer;
}
nav.navbar ul.navbar-nav > li > a:hover,
nav.navbar ul.navbar-nav > li > a:focus {
  color: #da145a;
}

nav.navbar ul.navbar-nav > li > a.active {
  color: #da145a;
  border-bottom: 2px solid #da145a;
}

.no_button > a:hover {
  color: #da145a;
  border-bottom: 2px solid #da145a;
}
.bootsnav .navbar-brand {
  height: auto;
  margin-top: 0px;
  max-width: 110px;
  padding-top: 0px;
}

#navbar-menu {
  margin: 6px 0;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}
.carousel-control-next,
.carousel-control-prev {
  width: 6% !important;
}

.navbar-nav.navbar-nav.menu {
  float: left;
  max-width: 468px;
  width: 70%;
}

.attr-nav {
  margin: 5px -10px 0 0;
}

.attr-nav > ul > li > a {
  color: #ffcb0f;
  font-size: 20px;
}

.attr-nav > ul > li > a:hover,
.attr-nav > ul > li > a:focus {
  color: #d7a300;
}

.profileDrop {
  float: left;
  margin-top: 0px;
  margin-left: 10px;
}
.languageDropdown .fa-sort-down:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
}
.languageDropdown .fa-sort-down {
  position: relative;
  top: -2px;
  left: 4px;
}
/*******  Sign In ******/

.LoginForm_Wrapper {
  text-align: left;
  max-width: 40%;
  margin: 60px auto;
}

/* Carousel */

.home .carousel {
  position: relative;
  height: auto;
}

.home .carousel .item img {
  width: 100%;
  height: auto;
}

#homePage .owl-nav .owl-prev {
  float: left;
  margin-left: 0px;
}
#homePage .owl-nav .owl-next {
  float: right;
  margin-right: 0px;
}

#homePage .owl-nav {
  top: 230px;
  width: 96%;
  margin: 0 auto;
}
.carousel .overlay {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
}
.carousel .carousel-inner img {
  width: 100%;
}
.carousel-caption {
  left: 7%;
  right: 7%;
  top: 70px;
  text-align: left !important;
}
.popularImage_Wrapper {
  height: 100%;
}
.popularImage_Wrapper img {
  height: 100%;
}
span.cateTitle {
  color: #c0c0c0;
  float: left;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 6px;
}
.carousel-caption p {
  color: #8273eb;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  margin-top: 5%;
}
.mainSlider_button {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  text-align: center;
}
.carousel-caption h3 {
  color: #ffffff;
  font-size: 40px;
  text-transform: inherit;
  width: 36%;
}

.carousel-caption.banner1Caption h1 {
  color: #fff;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 50px;
  line-height: 45px;
}
.carousel-caption.banner1Caption h2.second_heading {
  color: #8273eb;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 40px;
}
.carousel-caption.banner1Caption p a {
  color: #fff;
  text-decoration: underline;
  text-align: left;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 20px;
}

.carousel-caption.banner2Caption h1 {
  color: #000;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 50px;
  line-height: 45px;
}
.carousel-caption.banner2Caption h2.second_heading {
  color: #cd1e1e;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 40px;
}
.carousel-caption.banner2Caption p a {
  color: #fff;
  text-decoration: underline;
  text-align: left;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 20px;
}
.carousel-caption.banner3Caption h1 {
  color: #fff;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 45px;
  max-width: 472px;
  margin: 8% auto 50px;
}
.carousel-caption.banner3Caption h2.second_heading {
  color: #001558;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  max-width: 472px;
  margin: 0 auto 10px;
}
.carousel-caption.banner3Caption p {
  max-width: 472px;
  margin: 0 auto;
  width: 100%;
  text-align: left;
}

.carousel-caption.banner3Caption p a {
  color: #fff;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 0 auto;
}
.carousel-caption.banner4Caption h1 {
  color: #000;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 45px;
  max-width: 306px;
  margin: 0 auto 10px;
}
.carousel-caption.banner4Caption h2.second_heading {
  color: #e91bb1;
  text-transform: inherit;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  max-width: 306px;
  margin: 0 auto 10px;
}
.audioBook_Wrapper img {
  width: auto;
}
.carousel-caption.banner4Caption p a {
  color: #fff;
  text-decoration: underline;
  text-align: left;
  font-family: "Roboto", sans-serif;
  float: left;
  font-size: 20px;
}
.carousel-caption.banner4Caption p {
  max-width: 306px;
  margin: 0 auto;
  width: 100%;
  text-align: left;
}
.carousel.slide {
  width: 100%;
  float: left;
}
.btnDiv {
  width: 100%;
  text-align: center;
}
.btn.know_btn {
  border-radius: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  padding: 15px 60px;
  text-transform: inherit;
  border: 2px solid #ca2ff3;
  margin-right: 20px;
}
.carousel-caption .btn.know_btn {
  margin-top: 30px;
}

.carousel-caption .btn.know_btn:last-child {
  margin-left: 20px;
}

a.btn.know_btn:hover,
a.btn.know_btn:focus {
  color: #ffffff;
  border: 2px solid #0e9cde;
  font-family: "Roboto", sans-serif;
}

.carousel-control {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: -25px;
  top: 50%;
}

.sliderProductCategory .nav-link {
  padding: 0px;
  display: inline;
}

/******  Home Tabs  *******/

.homeTabs hr {
  margin-top: 0px;
  border-top: 2px solid #352e67;
}
.homeTabs .nav-tabs {
  border-bottom: none;
}
.homeTabs .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.homeTabs {
  display: table;
  width: 100%;
  padding: 40px 0px;
}
.tabIcon_image {
  width: 30%;
  float: left;
}
.tabIcon_image img {
  width: auto;
}
.tabIcon_image:first-child {
  width: 49px;
  float: left;
  margin-right: 5px;
}
.tabRight_Content {
  text-align: left;
  width: 90%;
  font-size: 18px;
  padding-top: 0px;
  font-family: "Roboto", sans-serif;
}
.homeTabs .nav-pills .nav-link.active {
  margin-top: -8px;
  padding: 24px 15px;
}
.homeTabs li.nav-item {
  width: 18%;
}
.homeTabs li.nav-item a {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}
.tabRight_Content span {
  color: #da145a;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
}
.homeTabs .tab-content {
  padding-top: 20px;
}

/* App Store */

#snovel-app {
  margin: 85px 0px;
}

.snovelapp-left {
  background-color: #f9f9f9;
  padding: 30px 30px;
  border: 1px solid #e5e5e5;
  text-align: left;
  width: 100%;
  float: left;
}

.snovelapp-left .left-contents h2 {
  font-size: 24px;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.left-contents p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #212433;
}

.app-btn {
  max-width: 580px;
  padding-top: 25px;
}

.app-btn img {
  width: 65px;
  float: left;
  margin-left: -2px;
  margin-top: -2px;
}

.iphone-app-btn {
  width: 280px;
  float: left;
  border: 2px solid #212433;
  border-radius: 50px;
  height: 64px;
  display: flex;
  align-items: center;
}

.iphone-app-btn p {
  float: left;
  width: 150px;
  color: #212433;
  font-weight: bold;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding-left: 20px;
  line-height: 22px;
  margin-bottom: 0px;
}

.android-app-btn {
  width: 280px;
  float: right;
  border: 2px solid #a4c639;
  border-radius: 50px;
  height: 64px;
  display: flex;
  align-items: center;
}

.android-app-btn p {
  float: left;
  width: 150px;
  color: #a4c639;
  font-weight: bold;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding-left: 20px;
  line-height: 22px;
  margin-bottom: 0px;
}

.snovelapp-right {
  background: url("/../../images/application-banner.jpg");
  height: auto;
  background-repeat: no-repeat;
  color: #fff;
  padding: 10px;
  background-size: cover;
  text-align: left;
  width: 100%;
  float: left;
}

.snovelapp-right .right-contents h2 {
  font-size: 24px;
  text-transform: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.gift-icon {
  width: 85px;
  float: left;
  background: #000;
  border-radius: 50%;
  height: 85px;
  padding: 16px;
}

.gift-icon img {
  width: 54px;
}

.gift-text p {
  margin-left: 10px;
  font-size: 18px;
  margin-top: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.gift-text {
  width: auto;
  float: left;
}
.snovelapp-right .right-contents {
  width: 100%;
  float: left;
  padding: 14px;
}
.snovelapp-right .right-contents p {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.collaborate-content h2 {
  text-transform: none;
  color: #352e67;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
}

.collaborate-content p {
  font-size: 16px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.collaboration-points {
  background: url("/../../images/bg-collab.jpg");
  height: 260px;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 34px;
  background-size: 100%;
  width: 100%;
  float: left;
}
.dropdown-toggle::after {
  border: none !important;
}

.headerProfileContainer.dropdown-toggle {
  white-space: inherit;
  text-align: left;
  height: 30px;
}

.collaboration-points .collab img {
  width: 45px;
}

.collaboration-points .director p {
  position: absolute;
  bottom: 61px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  width: 50%;
  left: 40%;
  top: 111%;
}

.collaboration-points .writer p {
  position: absolute;
  bottom: 61px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  width: 50%;
  left: 40%;
  top: 111%;
}

.collaboration-points .publisher p {
  position: absolute;
  bottom: 61px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  width: 50%;
  left: 40%;
  top: 111%;
}

.collaboration-points .voice-artist p {
  position: absolute;
  bottom: 61px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  left: 12%;
  top: 111%;
}

.collaboration-points .collab {
  float: left;
  width: 25%;
  text-align: center;
}
.directCircle {
  border: 1px solid #c8c8c8;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: 35%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collaboration-points .director {
  position: relative;
  top: 34px;
  left: 18px;
}

.collaboration-points .writer {
  position: relative;
  top: 34px;
  left: -31px;
}

.collaboration-points .publisher {
  position: relative;
  top: 34px;
  left: -57px;
}

.collaboration-points .voice-artist {
  position: relative;
  top: 34px;
  left: -101px;
}

/******  Footer Before section  *********/

.track-background {
  background: url("/../../images/back-track-banner.jpg") no-repeat;
  height: 501px;
  background-position: center;
  background-size: cover;
  width: 100%;
  float: left;
  padding: 60px 0px;
}

.track-background .title-section {
  margin-top: 64px;
}

.track-background .title {
  border: 1px solid #b5a8b7;
  padding: 23px 14px 5px;
  text-align: center;
  margin-left: 15px;
  max-width: 31% !important;
}
.track-background_inner {
  max-width: 96%;
  margin: 0 auto;
}

.track-background .title-head h2 {
  color: #fff;
  text-transform: none;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.track-background .title-head p {
  color: #f1cf33;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.track-tab-section {
  margin-top: 30px;
}

.each-track-cont {
  color: #fff;
  max-width: 100%;
  margin-left: 0px;
  text-align: center;
  margin-top: 45px;
}
.each-track-cont-wrapper {
  max-width: 80%;
  margin: 0 auto;
}

.each-track-cont h2 {
  color: #fff;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 15px;
}

.each-track-cont p {
  color: #fff;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}

.track-btn {
  margin: 30px auto 0px;
  max-width: 526px;
  display: table;
}

.track-btn .subsCribeButton {
  margin-right: 15px;
  border: 2px solid #da145a;
  padding: 10px 34px 10px 34px;
  border-radius: 50px;
  float: left;
  background: #da145a;
  color: #fff;
  cursor: pointer;
  width: 228px;
  text-align: center;
}
.ScrollUpButton__Container img {
  width: 60%;
}

.track-btn .subsCribeButton a {
  color: #fff;
}

.track-btn .subsCribeButton:hover {
  background-color: #da145a;
  color: #fff;
}

.track-btn .subsCribeButton:hover a {
  color: #fff;
}

.track-btn .subsCribeButton a,
.track-btn .purchaseButton a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: capitalize;
}

.track-btn .purchaseButton {
  border: 2px solid #da145a;
  padding: 10px 34px 10px 34px;
  float: left;
  border-radius: 50px;
  cursor: pointer;
  width: 228px;
}

.track-btn .purchaseButton:hover {
  background-color: #da145a;
  color: #fff;
  padding: 10px 34px 10px 34px;
}

.track-btn .purchaseButton:hover a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

/* Portfolio */
#portfolio {
  /* background: rgba(0, 0, 0, 0) url("../images/portfolio_bg.jpg") no-repeat scroll 0 0; */
  background-size: cover;
  position: relative;
  padding-bottom: 102px;
  border-bottom: 2px solid #ffcb0f;
}

#portfolio::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(255, 203, 15, 0.85);
  top: 0;
  left: 0;
}

.portfolio_area {
  position: relative;
  color: #fff;
}

.portfolio_area h2 {
  color: #fff;
  margin: 65px 0 34px;
}

/* Portfolio filters */

#filters {
  margin: 52px 0 38px;
}

#filters > button {
  background: transparent none repeat scroll 0 0;
  border: 1px solid transparent;
  border-radius: 18px;
  outline: 0 none;
  padding: 6px 17px 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#filters > button:hover {
  border: 1px solid #282828;
  color: #282828;
}

#filters > button.is-checked {
  background: #282828;
}

#filters > button.is-checked:hover {
  color: #fff;
}

.grid {
  margin: 0 -6px;
}

.grid-item {
  border: 6px solid transparent;
}

.grid-item,
.grid-sizer {
  width: 25%;
}

.grid-item {
  float: left;
  height: 255px !important;
}

.grid-item {
  overflow: hidden;
}

.grid-item--width2 {
  width: 50%;
}

.grid-item--height2 {
  height: 510px !important;
}

.grid-item:hover img {
  transform: scale(1.1);
}

.grid-item img {
  height: 100%;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  -ms-transition: all 0.6s;
  transition: all 0.6s;
}

/* Footer */

footer {
  position: relative;
}

footer::after {
  background: #212433;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.footer_top,
.footer_bottom {
  position: relative;
  z-index: 1;
  color: #fff;
}
.footer_item .nav {
  display: inline;
  list-style: none;
  text-align: left;
}
footer {
  width: 100%;
  float: left;
}
.footer_item {
  margin-top: 40px;
}
.nav-tabs .nav-item {
  margin-bottom: 0px;
}
.footer_item > h4 {
  color: #818183;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  margin-bottom: 15px;
  text-transform: inherit;
  font-weight: 400;
  text-align: left;
}
.footer_item ul li a {
  padding-left: 0px;
}

.footer_item a {
  color: #818183;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.footer_item .list-unstyled > li a {
  color: #818183;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.footer_social_icon {
  display: flex;
}
.footer_social_icon li {
  margin-right: 10px;
}

/* Footer About */

.footer_item .logo {
  margin-bottom: 15px;
  width: 36px;
}

.list-inline.footer_social_icon {
  margin: 24px 0 0;
}

.footer_social_icon .fa {
  background: #51535a none repeat scroll 0 0;
  border-radius: 100%;
  color: #212433;
  font-size: 20px;
  height: 45px;
  padding: 12px;
  text-align: center;
  width: 45px;
}

.seemore {
  float: right;
  margin-top: 30px;
  margin-right: 20px;
}

.seemore a {
  color: #da145a;
  font-size: 16x;
  font-family: "Roboto", sans-serif;
}

.footer_item .footer_social_icon .fa:hover,
.footer_item .footer_social_icon .fa:focus {
  background: #fff none repeat scroll 0 0;
}

/* Footer Explore */

.footer_menu .fa {
  font-size: 10px;
  margin-right: 18px;
}

.list-unstyled.footer_menu > li {
  padding: 0px 0;
}

/* Footer Post */

.list-unstyled.post,
.list-unstyled.footer_contact {
  margin-top: -14px;
}

.post .date {
  border: 2px solid #fff;
  border-radius: 100%;
  display: block;
  float: left;
  font-size: 20px;
  height: 50px;
  line-height: 12px;
  margin-right: 15px;
  padding: 10px;
  text-align: center;
  width: 50px;
}

.footer_item li a:hover .date,
.footer_item li a:focus .date {
  border: 2px solid #aaa;
}

.footer_item li a:hover,
.footer_item li a:focus {
  color: #aaa;
}

.footer_top .ftFirstCol {
  width: 8% !important;
  flex: 0 0 8.666667%;
}
.footer_top .ftFirstCol:nth-child(2) {
  width: 18% !important;
  flex: 0 0 18.666667%;
}

.footer_top .ftSecCol {
  width: 23%;
  flex: 0 0 23.666667%;
  /* max-width: 23.666667%; */
}

.post .date small {
  font-size: 12px;
}

.list-unstyled.post > li,
.list-unstyled.footer_contact > li {
  padding: 14px 0;
  overflow: hidden;
}

/* Footer Contact */

.footer_contact .fa {
  margin-right: 25px;
  text-align: center;
  width: 15px;
  float: left;
  font-size: 18px;
}

.list-unstyled.footer_contact p {
  overflow: hidden;
}

.footer_bottom {
  background: #1a1c27 none repeat scroll 0 0;
  background: #000;
  padding: 28px 0 18px;
  margin-top: 30px;
}

.footer_bottom.player-footer-bottom {
  margin-bottom: 75px;
}

.footer_bottom a {
  color: #818183;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.footer_bottom a:hover,
.footer_bottom a:focus {
  color: #fff;
}

#scrollUp {
  bottom: 20px;
  color: #000;
  height: 40px;
  right: 20px;
  width: 40px;
  opacity: 0.7;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}
.navbar-header {
  width: 22%;
  float: left;
}

.headerRightCont {
  width: 82%;
  float: right;
}
.headerRightCont .navbar {
  padding: 0px;
  padding-bottom: 14px;
  padding-top: 8px;
}

.navbarRight {
  float: left;
  width: 71%;
  padding-left: 0px;
  padding-right: 0px;
}
.audioDiv.bottombar img {
  width: 72px;
}
.searchBoxIsOpen .desktopMenu.navbar-collapse,
.searchBoxIsOpen .language_tranlation {
  display: none !important;
  opacity: 0;
  transition: opacity 1s ease-out;
}
.navbar-toggler {
  display: none;
}

.search-container input[type="text"] {
  padding: 7px 6px 7px 20px;
  font-size: 16px;
  border: 1px solid #b4b4b4;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  outline: 0;
  margin-top: 6px;
}

.search-container button {
  background: none;
  font-size: 17px;
  border: none;
  cursor: pointer;
}
.search-container .searchButton_Wrapper {
  position: absolute;
  right: 10px;
  top: 11px;
}
.searchSuggetion_Wrapper {
  position: absolute;
  width: 100%;
  top: 55px;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.searchSuggetion_Wrapper h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
}
.searchSuggetion_Wrapper .searchAuthor {
  width: 100%;
  float: left;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}
.search-resultFor {
  margin-top: 20px;
  text-transform: initial;
}
.searchSuggetion_Wrapper .SearchProduct {
  width: 100%;
  float: left;
  padding: 16px;
}
.searchSuggetion_Wrapper .SearchProduct span {
  width: 100%;
  float: left;
  padding-bottom: 4px;
  cursor: pointer;
}
.headerLeft_Section {
  width: 82%;
  float: left;
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.headerRight_Section {
  width: 24%;
  float: left;
  position: relative;
  text-align: center;
}
.headerRight_Section .form-group {
  position: absolute;
  top: -13px;
  right: 90px;
  z-index: 9;
  height: 36px;
  margin-bottom: 0px;
}
.form-control:focus {
  box-shadow: none !important;
}
.headerRight_Section .form-group .form-control {
  border: none;
  cursor: pointer;
  padding: 0px;
  font-size: 14px;
  width: 90px;
}
.searchButton_Wrapper img {
  cursor: pointer;
  width: 18px;
  margin-top: -2px;
}
.content_Wrapper {
  min-height: 420px;
  width: 100%;
  float: left;
}
.searchBoxIsOpen .navbarLeft {
  width: 40%;
}
.searchbox-open {
  width: 100%;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
}

.cross-open {
  cursor: pointer;
}

.fa-search {
  color: #b4b4b4;
}
.checkoutIndCnt .pointer-cursor {
  cursor: pointer;
  color: #000;
  font-size: 20px;
}
.cursorPointer {
  cursor: pointer;
}

.browseGenre .nav-link {
  padding: 0px;
  display: inline;
}
.browseGenre span {
  font-size: 16px;
  font-weight: 300;
  color: #000;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  padding-left: 0px;
}
.headerProfileContainer {
  border-left: 1px solid #b4b4b4;
  float: left;
  margin-top: 0px;
  margin-left: 0px;
  position: relative;
  width: 24%;
  margin-bottom: 0px;
}
.headerProfileContainer .signup_Wrapper li:nth-child(2) a {
  padding-right: 12px;
}
.headerProfileContainer .profileDrop .dropdown-toggle {
  padding: 0px;
}
.headerRightCont .navbar-nav li {
  padding: 0px 25px 0px;
}
.headerRightCont .navbar-nav li:nth-child(1) {
  padding-left: 0px;
}

.languageDropdown .dropdown-toggle {
  padding: 0px;
}
/* .headerProfileContainer .dropdownMenu::before {
    content: "";
    color: blue;
    border-left: 2px solid #d3d3d3;
    height: 30px;
    width: 2px;
    float: left;
    position: relative;
    top: 8px;
    } */
.headerProfileContainer ul {
  padding: 0px 0px;
  margin: 0px;
}
.headerProfileContainer ul li {
  display: inline-block;
  border: none;
}
.headerProfileContainer ul li a {
  color: #000;
}
.headerProfileContainer .dropdown-menu {
  min-width: 228px;
}
.headerProfileContainer .dropdown-menu > li {
  padding-top: 0px;
  padding-bottom: 0px;
  display: block;
  margin: 5px 0px;
}
.headerProfileContainer ul.dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}
.headerProfileContainer .dropdown-menu > li > a {
  border-bottom: 1px solid #b4b4b4;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
}
.headerProfileContainer .dropdown-menu > li > a:hover {
  color: #da145a;
  border-bottom: 1px solid #da145a;
}
.headerRight_Section .languageDropdown {
  position: absolute;
  top: -15px;
  right: 90px;
}

.languageDropdown .btn-secondary.focus,
.languageDropdown .btn-secondary:focus,
.profileDrop .btn-secondary:focus {
  box-shadow: none !important;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.languageDropdown .dropdown-toggle img {
  margin-left: 12px;
}
.languageDropdown .dropdown-toggle span {
  font-size: 15px;
}
.languageDropdown .dropdown-toggle,
.profileDrop .dropdown-toggle {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.languageDropdown .btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
.languageDropdown .btn-secondary:not(:disabled):not(.disabled).active,
.languageDropdown .btn-secondary:not(:disabled):not(.disabled):active,
.languageDropdown .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.languageDropdown .dropdown-menu {
  background: #f3f3f4;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  min-width: 7rem;
}
.languageDropdown .btn-secondary:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.languageDropdown ul li a {
  padding: 0px;
  font-size: 15px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.forGotRight a {
  display: initial;
  padding: 0px 2px;
}

.languageDropdown ul li {
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding-bottom: 10px;
}
.languageDropdown ul li:last-child {
  padding-bottom: 0px;
}
.react-multi-carousel-list .audio-player-volume {
  display: none;
}
.react-multi-carousel-list .sliderTrack_Wrap .audio-player #play-icon,
.react-multi-carousel-list .sliderTrack_Wrap img {
  height: 3rem !important;
  cursor: pointer;
}
.react-multi-carousel-list .sliderTrack_Wrap {
  position: absolute;
  top: 40px;
  left: 40%;
  right: 0px;
}
.react-multi-carousel-track .sampleProduct_thumb {
  width: 100%;
  height: 100%;
}
.profileImg {
  margin-left: 12px;
  float: left;
  width: 50px;
}
.snovelSignUp .welcomeBack form {
  padding-bottom: 40px;
}

.profileImg img {
  width: 100%;
  border-radius: 50%;
  margin: 0px;
}

.profileText {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  width: 125px;
  float: left;
  padding: 8px 10px 0px;
  line-height: 15px;
  word-break: break-all;
  text-align: left;
  font-weight: 400;
}

nav.navbar.bootsnav ul.navbar-nav > li > a {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1rem;
  padding: 0px;
  border-bottom: none;
}
.btmHeader .search-container {
  width: 94%;
  position: relative;
}

.mySnovelVideo .genereNameLink a.genreLinkText:hover {
  color: #484848;
}

#exTab1 .tab-content {
  color: white;
  padding: 13px 0px;
}

.giftSubscriptionFrmFull .form-group {
  margin-bottom: 0px;
  /* margin-bottom: 5px;
  margin-top: 10px; */
}
.giftProduct-model .giftSubscriptionFrmFull input {
  border: 0px solid #ccc;
}
.giftProduct-model .giftSubscriptionGift {
  margin: 0px auto;
}
.giftProduct-model .giftSubscriptionGift .ontheWay {
  margin-bottom: 0px;
  border-radius: 0px;
}

#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 20px 0px;
}
.giftProduct-model 
    /* remove border radius for the tab */

    #exTab1 .navbar-nav-pills>li>a {
  border-radius: 0;
  display: inline-block;
}

/* change border radius for the tab , apply corners on top*/

.hrTab {
  margin-top: 0px !important;
  border-top: 2px solid #352e67;
}

.navbar-nav-pills {
  margin-top: 40px;
}

.navbar-nav-pills > li > a {
  border-radius: 0px !important;
  color: #212433;
}

.snovelOriginal {
  background: #eaeaf0;
}

.prodcasts {
  background: #eaeaf0;
  margin-left: 10px;
}

.audioBooks {
  background: #eaeaf0;
  margin-left: 10px;
}

.browseAllDesktop {
  display: block;
  width: 100%;
  color: #da145a;
  cursor: pointer;
  float: left;
  margin-bottom: 30px;
}

.browseAll {
  width: 100%;
  float: left;
  text-align: right;
}
.browseAll a {
  text-decoration: none;
}
.browseAll a:hover {
  text-decoration: none;
}
.browseAll a span {
  color: #da145a;
}

.browseAllDesktop a span {
  float: right;
  color: #da145a;
  text-transform: capitalize;
}

.navbar-nav-pills > li.active > a,
.navbar-nav-pills > li.active > a:focus,
.navbar-nav-pills > li.active > a:hover {
  background-color: #352e67;
  color: #fff;
}
ul.tabtop > li.active > a,
ul.tabtop > li.active > a:focus,
ul.tabtop > li.active > a:hover {
  top: -11px;
  height: 85px;
}
.navbar-nav-pills > li a {
  float: left;
}
.navbar-nav-pills > li {
  height: 73px;
}

#exTab3 .navbar-nav-pills > li > a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 20px 0px;
}

.audioBooks img {
  width: auto;
  float: left;
}

.audioBooks .tabliCntDesktop {
  text-align: right;
  width: 210px;
  float: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #212433;
}

.navbar-nav-pills > li.active > a.tabliCntDesktop {
  color: #fff !important;
}
.homeTabs .nav-pills a.nav-link {
  background: #eaeaf0;
  border-radius: 0px;
  border-color: transparent;
  cursor: pointer;
  padding: 20px 15px;
}

.homeTabs .nav-pills .nav-item {
  margin-right: 12px;
}

.homeTabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #352e67;
}

.audioDiv {
  text-align: center;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  margin-bottom: 41px;
}

.audioDiv h4 {
  color: #212433;
  font-size: 18px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: inherit;
}

.audioDiv img {
  width: auto;
  margin-bottom: 16px;
}

.audioDiv p {
  color: #898989;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.snovelOriginal img {
  width: auto;
  float: left;
}

.snovelOriginal .tabliCntDesktop {
  text-align: right;
  width: 210px;
  float: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #212433;
}

.react-multi-carousel-list ul li {
  margin-left: 12px;
  margin-right: 20px;
  width: 200px !important;
}
.react-multi-carousel-list {
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.innerSlider_Wrapper {
  width: 100%;
  float: left;
  padding: 20px 0px;
}
.familyInnerSlider_Wrapper {
  width: 100%;
  float: left;
}
.newfamilyInnerSlider_Wrapper {
  width: 100%;
  float: left;
}
.innerSlider_Wrapper h4 {
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 18px !important;
  text-transform: capitalize;
  font-weight: bold;
}
.familyInnerSlider_Wrapper h4 {
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-size: 18px !important;
  text-transform: capitalize;
  font-weight: bold;
}
.sliderThumb {
  height: 150px;
  cursor: pointer;
}
.sliderProductTitle {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.sliderProductAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #818183;
  display: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 97%;
}
.sliderProductCategory {
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 97%;
}
.snovelApp_Wrapper {
  width: 100%;
  float: left;
  padding: 30px 0px;
}
.sliderProductCategory span {
  color: #818183;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
.sliderProductCategory div {
  float: left;
}
/****  Popular Title, Author  ****/

.popularComponant_Wrapper {
  width: 100%;
  float: left;
  background: #212433 none repeat scroll 0 0;
  padding-bottom: 20px;
  line-height: 18px;
}
.popularComponant_Wrapper .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  padding-top: 30px;
}

.popularComponant_Wrapper .nav-pills > li > a {
  padding: 10px 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.popularComponant_Wrapper .nav-pills > li > a:hover {
  color: #fff;
  opacity: 0.8;
  border-color: transparent;
}
.popularComponant_Wrapper .nav li {
  margin-right: 50px;
}
.popularComponant_Wrapper .nav-pills > li > a.nav-link.active {
  background-color: transparent;
  color: #fff;
  border-bottom: 4px solid #da145a;
  opacity: 1;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0px;
}
.popularComponant_Wrapper .nav-pills > li > a {
  color: #fff;
  opacity: 0.8;
}
.popularInner_Wrapper {
  padding-top: 30px;
}
.videoDesc h4 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-transform: inherit;
}
.browseLatest_Lauch .videoDesc h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-transform: inherit;
}
.browseLatest_Lauch .videoCnt span.author {
  color: #000;
}
.browseLatest_Lauch .videoCnt {
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.browseLatest_Lauch .videoPrice {
  color: #000;
  margin-top: 30px;
}
.browseLatest_Lauch .videoCnt span.authorDes {
  color: #484848;
}
.productCategoryText a {
  padding-bottom: 0px;
}
.videoDesc {
  float: left;
  margin-top: 0px;
  width: 100%;
}
.productDetailsMain_Wrapper .productDetails_content .genereNameLink a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-left: 6px;
}
.videoCnt {
  float: left;
  width: 100%;
  line-height: 25px;
  color: #fff;
}
.threeDot .videoCnt {
  color: #000;
}
#libraryTabOuter .homeTabs {
  display: block;
}
.purchaseGenere {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popularComponant_Wrapper .videoCnt a {
  padding: 0px;
  display: inline-block;
  padding-right: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  border-right: 1px solid #fff;
  margin-right: 6px;
  line-height: 16px;
}
.videoCnt a {
  padding: 0px;
  display: inline;
  padding-right: 6px !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #484848;
  border-right: 1px solid #484848;
  margin-right: 6px;
  line-height: 16px;
}
.videoCnt a:nth-child(0) {
  padding-left: 8px;
}
.videoCnt a:last-child {
  border-right: none;
}
.popularComponant_Wrapper .videoCnt a:hover {
  color: #fff;
}
.videoCnt a:hover {
  color: #484848;
}
.loopdata a.genreLinkText:hover {
  text-decoration: none;
  color: #484848;
}
.popularComponant_Wrapper
  .popularInner_Wrapper
  .loopdata
  a.genreLinkText:hover {
  text-decoration: none;
  color: #ffffff;
}
.tryFree-snovel-wrapper .tabIcon_image img {
  width: 30px;
  height: 30px;
}
.tryFree-snovel-wrapper .tabIcon_image.chintoo img {
  width: 26px;
  height: 30px;
}
.homeTabs.tryFree-snovel-wrapper .nav-pills a.nav-link {
  padding: 22px 15px;
}
.videoCnt span.author {
  width: 80px;
  float: left;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.videoCnt span.authorDes {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.productDetails_content {
  float: left;
  width: 100%;
  line-height: 25px;
}

.productDetails_content span.author {
  width: 80px;
  float: left;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.productDetails_content span.authorDes {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.product-content-details {
  width: 100%;
  min-height: 156px;
}

.readMoreVideo {
  float: left;
  margin-top: 18px;
}
.blankAudioPlayer {
  pointer-events: none;
  opacity: 0.6;
}

.readMoreVideo a {
  color: #da145a;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.readMoreVideo .readMoreProduct {
  color: #da145a;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.browseTopSliderWrp .shareDiv {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 62px;
  display: flex;
  align-items: center;
  border: none;
  margin-top: 0px;
}
.shareDiv {
  float: left;
  border-bottom: 1px solid rgba(101, 101, 101, 0.6);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 25px;
  border-top: 1px solid rgba(101, 101, 101, 0.6);
}

.starRating {
  float: left;
  width: 38%;
}

.starRating span {
  float: left;
  margin-right: 5px;
}

.browseTopSliderWrp .shareIconDiv {
  float: right;
  width: 30%;
}

.shareIconDiv {
  float: right;
  width: 130px;
  position: relative;
}
.audio-course-product-outer .shareIconDiv {
  margin-top: -36px;
}
.popupShareButton {
  display: block;
  position: absolute;
  background-color: #fff;
  padding: 14px;
  border-radius: 5px;
  top: 36px;
  left: 10px;
  box-shadow: 0px 0px 4px 0px #ccc;
}
.browseTopSliderWrp .popupShareButton {
  display: block;
  position: absolute;
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  top: 30px;
  left: -80px;
  box-shadow: 0px 0px 4px 0px #ccc;
}
.browseTopSliderWrp .popupShareButton:before {
  content: "";
  border-style: solid;
  border-width: 0px 16px 15px 0px;
  border-color: #fff;
  position: absolute;
  top: -6px;
  transform: rotate(-45deg);
  margin: auto;
  left: auto;
  right: 5px;
  width: 10px;
}
.browseLatest_Lauch .genreLinkText {
  margin-left: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #484848;
  display: inline-block;
}
.browseLatest_Lauch a.genreLinkText:hover {
  color: #484848;
}
.browseLatest_Lauch .videoCnt a.genreLinkText:first-child {
  padding-left: 10px;
}
.popupShareButton:before {
  content: "";
  border-style: solid;
  border-width: 0px 16px 15px 0px;
  border-color: #fff;
  position: absolute;
  top: -6px;
  transform: rotate(-45deg);
  margin: 0 auto;
  left: 0px;
  right: 0px;
  width: 10px;
}

.popupShareButton_inline {
  display: inline-flex;
}

span.shareIcon {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

span.likeIcon {
  float: left;
  margin-left: 40px;
}

.browseTopSliderWrp span.shareIcon {
  float: right;
  margin-right: 5px;
}

.browseTopSliderWrp span.likeIcon {
  float: right;
  margin-left: 40px;
}

.videoPrice {
  float: left;
  width: 100%;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 30px;
  margin-top: 30px;
  height: 28px;
  line-height: 28px;
}

.product-author-details {
  width: 100%;
  min-height: 156px;
}

.browse-product-details {
  width: 100%;
  min-height: 156px;
}

.popularComponant_Wrapper .videoPrice {
  margin-top: 40px;
}
.popularComponant_Wrapper .videoPurchase {
  margin-top: 34px;
}

.videoPurchase {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.browseLatest_Lauch .shareDiv {
  margin-top: 22px;
}

.videoPurchase a {
  border: 2px solid #da145a;
  border-radius: 26px;
  padding: 12px 17px;
  color: #da145a;
  float: left;
  width: 214px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.videoPurchase .purchaseButton a {
  padding: 14px 17px;
}

.videoPurchase a:hover {
  background: #da145a;
  color: #fff;
  text-decoration: none;
}

.newsliderContentWrapper .popular-slider-content {
  margin-top: 0px;
}

.popular-slider-content {
  margin-top: 30px;
}
span.subsCribeButton {
  margin-right: 20px;
  float: left;
}

.whiteContent {
  color: #ffffff !important;
}

span.subsCribeButton a {
  background: #da145a;
  color: #fff;
}

.popularImage_Wrap img.popularImage {
  width: 100%;
  margin: 0px;
}
/********* Bread Crumb *********/

.breadCrumbWrp {
  width: 100%;
  float: left;
  background: #212433;
}

ul.breadCrumb {
  padding: 2% 0%;
  margin: 0px;
}

ul.breadCrumb li {
  color: #fff;
  display: inline;
}

ul.breadCrumb li a {
  color: #fff;
}

ul.breadCrumb li a.active {
  color: #da145a;
}

ul.breadCrumb li:nth-child(1) {
  background: url("/../../images/breadcrumb_arrow.png") no-repeat right;
  padding-right: 12px;
  padding-bottom: 4px;
  text-transform: uppercase;
}
.pageTitle {
  color: #352e67;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.pageTitle_Wrapper {
  width: 100%;
  float: left;
  padding: 20px 0px;
}
p {
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
/******  Subscribe Page   *******/

.unlimiTedAnnul {
  background: #e8e9ea;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 55px;
}

.unlimiTedAnnul h2 {
  height: 64px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  text-transform: capitalize;
  color: #000;
  line-height: 30px;
}

.unlimiTedAnnul h3 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  text-transform: initial;
  margin-bottom: 5px;
}

.unlimiTedAnnul span {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-right: 5px;
}

.unlimiTedAnnulBx {
  background: #212433;
  color: #fff;
  padding: 5%;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-top: 25px;
  margin-bottom: 25px;
  min-height: 103px;
}
.unlimiTedAnnulBx p {
  background: #212433;
  color: #fff;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.unlimiTedAnnul ul {
  padding: 0px;
  margin: 0px 0px 35px;
}

.unlimiTedAnnul ul li {
  list-style: none;
  font-style: italic;
  color: #000;
}

.unlimiTedAnnul .subsCribeButton {
  border: 2px solid #da145a;
  padding: 18px 60px 18px 60px;
  border-radius: 50px;
  background: #da145a;
  color: #fff;
  cursor: pointer;
}

.unlimiTedAnnul span.subsCribeButton {
  margin-right: 0px;
  float: none;
  text-transform: uppercase;
  display: table;
  margin: 40px auto 0;
}
.subscribePack_Wrapper {
  padding: 40px 0px;
}
.giftSubsCription {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
}

.giftSubsCription a {
  border: 2px solid #da145a;
  border-radius: 50px;
  padding: 18px 50px 18px 50px;
  color: #da145a;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  transition: 0.3s;
}
.giftSubsCription a:hover {
  text-decoration: none;
}

.giftSubsCription a:hover {
  background: #da145a;
  color: #fff;
}

.giftSubsCription.payment-success {
  padding-top: 20px;
  margin-bottom: 50px;
}
.billProfileLFtLogo img {
  width: 100%;
  border-radius: 50%;
  margin: 0px;
  height: 100%;
}
.giftSubsCription.payment-success a.nav-link {
  display: inline;
}

.visitFaq {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  float: left;
  font-weight: 400;
}

.visitFaq a {
  color: #da145a;
}

.subBenefit_Wrapeer {
  background: url("/../../images/bg_bottom_susbcription.png") no-repeat center;
  float: left;
  width: 100%;
  background-size: cover;
  padding: 40px 0px;
  margin-top: 40px;
}
.subBenefit_Wrapeer.audio-course-product-wrapper {
  margin-top: 0px;
}
.subsCriptionMid {
  border-left: 1px solid #7b6f91;
  border-right: 1px solid #7b6f91;
}
.subBenefit_Wrapeer h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  color: #fff;
  font-weight: 900;
  text-align: left;
  font-style: italic;
}
.subBenefit_Wrapeer span {
  color: #fea425;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}
.subBenefit_Wrapeer p {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 24px;
}
.subsCriBtnBtm {
  text-align: center;
  width: 20%;
  margin: 30px auto;
}
span.subsCribeButton {
  margin-right: 0px;
  text-transform: uppercase;
}
span.newSubscriptButton {
  margin-right: 20px;
}
.subsCribeButton {
  border: 2px solid #da145a;
  border-radius: 50px;
  background: #da145a;
  color: #fff;
  cursor: pointer;
}

.subsCribeButton1 {
  border: 2px solid #da145a;
  border-radius: 20px;
  background: #da145a;
  color: #fff;
  cursor: pointer;
  width: "20vh";
  padding: 10px 20px;
}

.playAll {
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 15px;
}
.subBenefit_Wrapeer .subsCribeButton {
  padding: 16px 60px;
}
.videoPurchase .subsCribeButton {
  margin-right: 20px;
}

/**********    Login  Page  *******/

.welComeWrp {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.welcomeBack {
  background: #eaeaf0;
  border: 1px solid #b4b4b4;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px;
  float: left;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.profileDrop .dropdown-toggle .fa-caret-down {
  font-size: 18px;
  padding-top: 14px;
}
.signup_Wrapper .nav {
  justify-content: flex-end;
  margin-right: 0px;
}

.welcomeBack h1 {
  color: #352e67;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 20px 10px 10px;
}

.welcomeBack form {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
}

.welComeWrp .welcomeBack input[type="text"] {
  width: 100%;
  border: none;
  margin-bottom: 0px;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.form-group {
  margin-bottom: 2px;
}
.loginErrorMsg {
  color: red;
  padding-left: 5px;
  margin-bottom: 0px;
  margin-top: 2px;
}
.welcomeBack input[type="text"]:focus {
  border: 1px solid #da145a;
}
.welcomeBack input[type="password"] {
  width: 100%;
  border: none;
  margin-bottom: 18px;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.form-control {
  padding: 8px 5px;
  margin-bottom: 20px;
}
.welcomeBack input[type="password"]:focus {
  border: 1px solid #da145a;
}

.welcomeBack button[type="submit"] {
  border: 2px solid #da145a;
  padding: 10px 60px 10px 60px;
  border-radius: 50px;
  background: #da145a;
  color: #fff;
  cursor: pointer;
  max-width: 370px;
  width: 100%;
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.orWith {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: 10px 0px;
}

.faceLogin {
  width: 50%;
  float: left;
  background: #475993;
  border-bottom-left-radius: 5px;
}

.signupWrapper .faceLogin {
  width: 100%;
  float: left;
  background: #fff;
  margin-bottom: 15px;
  border-bottom-left-radius: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.signupWrapper .faceLogin span {
  color: #000;
}

.faceLogin img {
  width: 12px;
}

.faceLogin a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.emailSign-upBox .checkbox a {
  color: #212529;
  font-size: 16px;
}
.billCheckBx.emailSign-upBox {
  left: 40px;
}
.billCheckBx.emailSign-upBox .checkmarkbx {
  left: -34px;
}
.signupWithMail {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  padding: 5% 7%;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.signupWithMail img {
  margin-left: 0px;
}
.signupWrapper .signupToLogin {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 10px;
}
.ForgetPassword {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.googleLogin {
  width: 50%;
  float: left;
  background: #dd4b39;
  border-bottom-right-radius: 5px;
}
.popularComponant_Wrapper .videoCnt div a {
  margin-right: 6px;
  padding-right: 6px !important;
}
.signupWrapper .googleLogin {
  width: 100%;
  float: left;
  background: #fff;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.signupWrapper .faceLogin img,
.signupWrapper .googleLogin img {
  width: auto;
  margin-right: 20px;
}
.signupWithMail span {
  margin-left: 16px;
}
.footer_item .footer_item_link {
  padding: 0px;
  text-align: center;
}
.footer_item .footer_item_link .logo-footer {
  width: 100%;
}
.footer_item .footer_item_link img {
  margin-bottom: 4px;
}
.footer_item .footer_item_link img.logo {
  margin-left: 20px;
}
.signupWrapper button.google-login-button {
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
  padding: 5% 6%;
  border-radius: 4px;
}
.signupWrapper .signupToLogin a {
  color: #da145a;
}
.kep-login-facebook span img {
  margin-right: 10px;
  margin-top: -2px;
}
.googleLogin img {
  width: 20px;
  margin-top: -1px;
}
.googleLogin a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  padding: 4% 2%;
  text-decoration: none;
}
.checkoutIndBtnLft .whiteColor {
  color: #fff !important;
  cursor: pointer;
}
.checkoutIndBtnRgt a:hover {
  color: #fff;
  text-decoration: none;
}
.giftSubsCription .subscriptionLink {
  width: 30%;
  margin: 0 auto;
}
.checkoutIndCross .removeProduct {
  padding-top: 6px;
}
.checkoutIndCross .removeProduct:before {
  font-size: 18px;
}
.forGotPass {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  float: left;
  width: 50%;
  margin-top: 20px;
}
.forGotPass span:hover {
  color: #da145a;
}
.forGotRight {
  float: right !important;
  text-align: right;
}
.forGotRight a:hover {
  color: #da145a;
}

.forGotPass a {
  color: #000000;
}
.loginWithOtp {
  display: flex;
  justify-content: center;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  float: none;
  /* width: 50%; */
  /* margin-top: 10px; */
  margin: 0 auto;
  margin-bottom: 20px
}
.loginWithOtp span:hover {
  color: #da145a;
}

.mobileNumberByCountryCode{
  display: flex;
  flex-direction: row;
}
.selectcountry{
  width: 35% !important;
  margin-right: 5px;
  font-size: 12px !important;
}

.loginwithMobilemsg{
  text-align: center;
  /* margin-left: 5px; */
  margin-top: 14px;
  font-size: 14px;
}

.otp-group {
  margin-top: 20px;
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-text{
  margin-top: 20px;
}

.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input {
  -moz-appearance: textfield;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
}

.loginwithotp-error-message{
  color: red;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 400;
  text-align: center;
}

.mobilenumberotp::-webkit-outer-spin-button,
.mobilenumberotp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobilenumberotp {
  -moz-appearance: textfield;
}

.selectcountry{
  padding: 5px !important;
}


.loginWithOtpHeading{
  text-transform: inherit !important;
  text-align: center !important;
  margin-bottom: 5% !important;
}

.showOpt{
  margin-top:10px
}

.forGotPass a {
  color: #000000;
}

.loginwithotpbtn {
  border: 2px solid #da145a !important;
  padding: 10px 60px 10px 60px !important;
  border-radius: 50px !important;
  background: #da145a !important;
  color: #fff !important;
  cursor: pointer !important;
  max-width: 370px;
  width: 100%;
  font-weight: 600 !important;
  margin-top: 10px;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif;
}

.ResendOtp{
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 50%;
  margin-top: 20px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 10px;

}

.ResendOtp span:hover {
  color: #da145a;
}

.signup-crossbtn{
  position: relative;
  margin-left: 90%;
  cursor: pointer;
}



.snovelSignUp .welcomeBack {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  float: none;
}
.signup-error-outer {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  float: none;
}
.snovelSignUp .welcomeBack input[type="email"] {
  max-width: 100%;
}
.snovelSignUp .welcomeBack input[type="password"] {
  max-width: 100%;
}
.snovelSignUp .welcomeBack input[type="submit"] {
  max-width: 100%;
}
.snovelSignUp .welcomeBack h1 {
  color: #da145a;
  text-transform: capitalize;
  margin-top: 30px !important;
  line-height: 30px;
  padding: 0px;
  margin-bottom: 0px;
}

.snovelSignUp p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-style: italic;
  padding-bottom: 30px;
}
.snovelSignUp p strong {
  font-size: 18px;
}
.snovelSignUp {
  margin-top: 40px;
}
.snovelSignUp .form-control {
  margin-bottom: 0px;
}
.snovelSignUp .welcomeBack input[type="password"] {
  margin-bottom: 0px;
  padding: 8px 12px;
}
.snovelSignUpBtm {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  float: none;
  padding-top: 20px;
  clear: both;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  display: table;
}
.snovelSignUpBtm .billCheckBx {
  margin-top: 15px;
  max-width: 410px;
  margin-left: 40px;
}
.checkmarkbx {
  position: absolute;
  top: 0;
  left: -34px;
  height: 25px;
  width: 25px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
}
.billCheckBx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.billCheckBx:hover input ~ .checkmarkbx {
  background-color: #ccc;
}
.billCheckBx input:checked ~ .checkmarkbx:after {
  display: block;
}
.billCheckBx .checkmarkbx:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkmarkbx:after {
  content: "";
  position: absolute;
  display: none;
}
.billCheckBx {
  position: relative;
}

.billCheckBx input:checked ~ .checkmarkbx {
  background-color: #da145a;
}

.checkmarkbx:after {
  content: "";
  position: absolute;
  display: none;
}

.billCheckBx input:checked ~ .checkmarkbx:after {
  display: block;
}

.snovelSignUpBtm label {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #484848;
}

.billCheckBx .checkmarkbx:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropdownMenu {
  position: relative;
}
.menuCategory_Wrapper ul {
  width: 100%;
  float: left;
  list-style-type: none;
  padding-left: 0px;
}
.menuCategory_Wrapper ul li a {
  color: #000;
  padding: 6px 8px;
  display: inline-block;
  font-size: 15px;
}
.menuCategory_Wrapper ul li a:hover {
  color: #da145a;
  background: #fbe7ee;
  text-decoration: none;
}
.brdsnovelSignUpBtm {
  border-bottom: 1px solid #e9e9e9;
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
}

.videoThumbTitleDiv span {
  cursor: pointer;
}
.profileDropdown .nav {
  display: initial;
}
.profileDropdown li a {
  padding-bottom: 0px;
}
.profileDrop .btn-group,
.profileDrop .btn-group-vertical {
  position: inherit;
  display: inline-flex;
  vertical-align: middle;
}
.profileDrop .dropdown-menu {
  top: 62px !important;
  left: -134px !important;
  transform: none !important;
  background-color: #fff;
  min-width: 220px;
  border-radius: 0px;
}
.profileDrop .profileDropdown {
  width: 100%;
  float: left;
  padding-bottom: 10px;
}
.homeTabs.tryFree-snovel-wrapper .nav-pills .nav-link.active {
  padding: 26px 15px;
}
.kep-login-facebook {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: transparent !important;
  border: none !important;
  width: 100%;
  padding: 10px 2% !important;
  text-align: center;
}
.audio-player-track-name {
  display: none;
}
.audioPlayer_Wrapper .audio-player div div {
  padding: 2px 4px;
}
.audio-player {
  width: 100% !important;
}
.audioPlayer_Wrapper {
  background-color: #000000;
  padding: 10px;
}

.audio-player-seeker input[type="range"],
.audio-player-volume input[type="range"] {
  height: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 10px 0;
  width: 100%;
  border-radius: 6px;
  background-color: #ffffff;
  outline: none;
}
.audio-player-seeker input[type="range"]:focus,
.audio-player-volume input[type="range"]:focus {
  outline: none;
}
.audio-player-seeker input[type="range"]::-webkit-slider-runnable-track,
.audio-player-volume input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px !important;
  cursor: pointer;
  background-color: #ffffff !important;
  border-radius: 5px !important;
}
.audio-player-seeker input[type="range"]::-webkit-slider-thumb,
.audio-player-volume input[type="range"]::-webkit-slider-thumb {
  height: 30px;
  width: 15px;
  border-radius: 5px;
  background-color: red !important;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px !important;
}
.audio-player-seeker input[type="range"]::-moz-range-progress,
.audio-player-volume input[type="range"]::-moz-range-progress {
  background-color: red !important;
  height: 6px !important;
}

.audio-player-seeker input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #3071a9;
}
.audio-player-seeker input[type="range"]::-moz-range-track,
.audio-player-volume input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px !important;
  cursor: pointer;
  background-color: #ffffff !important;
  border-radius: 5px !important;
}
.audio-player-seeker input[type="range"]::-moz-range-thumb,
.audio-player-volume input[type="range"]::-moz-range-thumb {
  height: 30px;
  border-radius: 5px;
  background-color: #ffffff !important;
  cursor: pointer;
}
.audio-player-seeker input[type="range"]::-ms-track,
.audio-player-volume input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.audio-player-seeker input[type="range"]::-ms-fill-lower {
  background-color: red !important;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.audio-player-seeker input[type="range"]::-ms-fill-upper {
  background-color: red !important;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.whiteTitle {
  color: #fff;
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .container {
    max-width: 100% !important;
  }
}
.productDetailsMain_Wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}
.productDescript_Wrapper {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.product-summery h4 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-transform: inherit;
  font-weight: 600;
}
.browseDetailsVideo span {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #898989;
  margin: 20px 4px;
  float: left;
  width: 100%;
  font-weight: 600;
}
.product-summery {
  width: 100%;
  float: left;
  padding-left: 33px;
  padding-top: 10px;
}
.productDetailsMain_Wrapper .videoPurchase {
  margin-top: 44px;
}
.productDetailsMain_Wrapper .videoPurchase .purchaseButton a {
  padding: 12px 17px;
}
.productDetailsMain_Wrapper .product-summery .videoPrice {
  margin-top: 45px;
}
.productDetailsMain_Wrapper .product-summery .shareDiv {
  margin-top: 30px;
}
.collaborateText_Wrapper h2 {
  text-transform: none;
  color: #352e67;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
}
.collaborateText_Wrapper p {
  font-size: 16px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.collaborateText_Wrapper {
  width: 100%;
  float: left;
  /* margin-top: 60px; */
}
button.google-login-button {
  background-color: #dd4b39;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 10px 1%;
  border-bottom-right-radius: 5px;
}
#register-form .form-group .form-control {
  padding: 10px;
  height: 46px;
}
.footer_bottom a {
  display: inline-block;
}
.haveQuery {
  background: #f9f9f9;
  float: left;
  border: 1px solid rgba(101, 101, 101, 0.1);
  padding: 2% 5%;
  margin-bottom: 50px;
  width: 100%;
  margin-top: 18px;
}
.haveQuery h2 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}
.haveQuery a {
  color: #da145a;
}
.reviewSlideWrp {
  width: 100%;
  float: left;
  background: #fef5e7;
  border: 1px solid #ffe5bc;
}
.reviewWrp {
  width: 100%;
  float: left;
  padding: 20px 0px 20px 50px;
}
.reviewWrp h3 {
  color: #000;
  width: 115px;
  float: left;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  margin-top: 0px;
  font-weight: 400;
}
.reviewWrp span.reviewCount {
  width: 50px;
  border: 1px solid #eccfa1;
  height: 50px;
  border-radius: 50%;
  float: left;
  text-align: center;
  padding-top: 2%;
  font-family: "robotobold";
  font-size: 18px;
  margin-top: -14px;
}
.reviewSlideWrp .owl-carousel .owl-nav {
  width: 2px;
  float: right;
  position: initial;
  bottom: 2px;
  margin-right: 40px;
}
.reviewSlideWrp .owl-carousel .owl-nav .owl-prev::before {
  font-size: 26px;
  color: #000;
}
.reviewCntTest p:nth-of-type(1) {
  font-weight: 400;
  font-size: 16px;
  color: #484848;
}
.reviewNotFound {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.reviewCntTest h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
}
.reviewTestComment a {
  font-weight: 400;
  font-size: 14px;
  color: #da145a;
}
.reviewSlideWrp .owl-carousel .owl-nav .owl-next::after {
  font-size: 26px;
  color: #000;
}
.reviewSlideWrp .owl-carousel .owl-nav .owl-prev {
  float: left;
  margin-left: -22px;
}
.reviewSlideWrp .owl-carousel .owl-nav .owl-next {
  float: right;
  margin-right: -26px;
}
.removeCupon {
  margin-left: 5px;
  color: #da145a;
  cursor: pointer;
}
.cuponSuccessMessage {
  width: 100%;
  float: left;
  text-align: right;
  font-size: 13px;
  margin-top: 4px;
  color: green;
}
.orderList {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.yourGiftProceed a:hover {
  color: #fff;
  text-decoration: none;
}
.yourGiftCancel a:hover {
  text-decoration: none;
}
.orderImage {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.paymentSuccess.table {
  width: 65%;
  margin-bottom: 1rem;
  color: #212529;
  margin: 0 auto;
}
.orderImage img {
  width: 40%;
}
.notFoundResult {
  font-size: 18px;
  margin-top: 20px;
}
.cuponErrorMessage {
  width: 100%;
  float: left;
  text-align: right;
  font-size: 13px;
  margin-top: 4px;
  color: #da145a;
}
.billFrmInputLft #countryCode {
  max-width: 90%;
}
.reviewCntTest {
  float: left;
  width: 100%;
  border-left: 1px solid #e7e0d5;
  padding: 5%;
  height: 144px;
  margin-bottom: 10px;
  overflow: hidden;
}
.userSubscribeCheckout {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
.readMoreReview {
  font-weight: 400;
  font-size: 14px;
  color: #da145a;
  cursor: pointer;
  float: right;
  margin-right: 20px;
}
.reviewCntTestCondition {
  height: auto;
}
.reviewTestComment {
  float: left;
  padding: 5% 0% 0% 0%;
}
.productPostType {
  color: #727379;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0px 0px 10px;
  float: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

/************************************************
  Browse page design

  ************************************************/
.owl-carousel .owl-stage-outer {
  z-index: 999;
  width: 100%;
  float: left;
}
.topSnovelTitle {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 18px;
}
.browseTopSliderWrp {
  background: #212433 none repeat scroll 0 0;
  width: 100%;
  float: left;
  margin-bottom: 30px;
  padding-top: 30px;
  margin-top: 30px;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  color: #fff;
}
.owl-nav {
  position: absolute;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 160px;
}
.faq .owl-nav {
  position: inherit;
}
.owl-nav .owl-next {
  float: right;
  margin-right: -38px;
}
.owl-nav .owl-prev::before {
  content: "\f053";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 34px;
}
.owl-nav .owl-next::after {
  content: "\f054";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 34px;
}
.owl-nav .owl-prev {
  float: left;
}
.sliderWrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  min-height: 100px;
  bottom: 136px;
  width: 100%;
  padding: 10px;
  left: 0px;
}
.browseTopSliderWrp .owl-carousel .owl-item .sliderTrack_Wrap {
  width: 100%;
  float: left;
  background-color: #000;
  padding: 10px;
  position: inherit;
}
.browseTopSliderWrp .owl-carousel .owl-item .sliderTrack_Wrap img {
  width: 12px;
  float: left;
  cursor: pointer;
  height: 17px;
}
.browseSlideCategory {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}
.browseSlideCategory a {
  color: #fff;
  padding-right: 5px !important;
  margin-right: 5px;
  border-right: 1px solid #fff;
  line-height: 16px;
  font-size: 12px;
}
.browseSlideTxtOne {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.browseSlideTxtTwo {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  width: 100%;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.browseSlideTxtTwo a {
  padding: 0px;
  color: #fff;
  border-right: 1px solid #fff;
  margin-right: 5px;
  line-height: 16px;
  display: inline;
}
.browseSlideTxtTwo a:last-child {
  border-right: none;
}
.browseSlideTxtTwo a:hover {
  text-decoration: none;
  color: #fff;
}
.emptyTrack {
  opacity: 0.6;
}
.shareDiv .priceBrwSlide {
  float: left;
  width: 30%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.shareDiv .starRating img {
  width: 74% !important;
}
.browseType_Wrapper {
  width: 100%;
  float: left;
}

.browseType_Wrapper .homeTabs .innerProductsTab ul {
  border-bottom: 1px solid #ccc;
}
.browseType_Wrapper .homeTabs .innerProductsTab li a.active {
  color: #da145a !important;
  border-bottom: 3px solid #da145a !important;
}
.browseType_Wrapper .homeTabs .innerProductsTab li a:hover {
  color: #da145a !important;
  border-bottom: 3px solid #da145a !important;
}
.browseType_Wrapper .homeTabs .innerProductsTab li a span {
  color: #da145a !important;
}
.browseType_Wrapper .homeTabs .innerProductsTab li.nav-item {
  width: auto;
}
.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link:focus,
.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link:hover {
  border: none;
}
.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none;
  padding: 0px 30px 10px !important;
}
.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link {
  padding: 0px 30px 10px !important;
}
.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link:hover {
  padding: 0px 30px 10px !important;
}
.headerLanguageSelection {
  width: 30%;
  position: absolute;
  right: 0px;
  left: 0px;
  top: -10px;
  margin: 0 auto;
  z-index: 1;
  cursor: pointer;
  font-size: 15px;
}
.headerLanguageSelection span img {
  float: right;
  margin-top: 7px;
}
.languageDropdown ul {
  padding: 10px 12px;
}
.innerProductsTab li a {
  float: left;
  font-size: 18px;
  color: #b4b4b4 !important;
  padding-bottom: 12px;
}
.videoThumbDiv {
  position: relative;
  float: left;
  margin-top: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.videoThumbTitleDiv {
  float: left;
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  width: 100%;
}
.videoThumbTitleDiv .briefTitle {
  font-size: 12px;
  font-weight: 400;
}
.videoThumbCateDiv span.cateMusic {
  color: #818183;
  font-size: 11px;
}
.audioBook_Wrapper .videoThumbDiv img {
  width: 100%;
  -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
  transition: width 2s;
  margin: 0px;
  height: 100%;
}
.audioBook_Wrapper .videoThumbDiv .sliderTrack_Wrap img {
  width: 100%;
  -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
  transition: width 2s;
  margin: 0px;
  height: 100%;
}
.audioBook_Wrapper .videoThumbDiv .starRatingBrw img {
  width: auto;
  margin: 3px;
}

.showDotPop {
  display: none;
  position: absolute;
  background: #fff;
  left: 110%;
  z-index: 999;
  width: 399px !important;
  -webkit-box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  padding-bottom: 30px;
  top: -42px;
  -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
  transition: width 2s;
}
.leftHoverArw {
  position: absolute;
  left: -23px;
  top: 39%;
}
.leftHoverArw img {
  width: 23px !important;
}
.threeDot .treeDotTop {
  background: #000;
  float: left;
  width: 100%;
  padding: 10px 0% 0px 35px;
  margin-bottom: 30px;
}
.threeDot .videoCnt span.authorDes {
  margin-left: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #000;
}
.threeDot .videoCnt span.author {
  color: #000 !important;
  padding: 0px 0% 5px 20px;
  width: 114px;
  text-align: left;
}
.videoCnt .headingText,
.productDetails_content .headingText {
  padding-left: 0px !important;
  width: 80px;
  float: left;
}
.videoCnt .colon-symbol,
.productDetails_content .colon-symbol {
  padding-left: 0px !important;
  padding-right: 10px;
  float: left;
}
.productDetails_content .loopdata a.genreLinkText:last-child {
  border-right: none;
}
.productDetails_content .loopdata a.genreLinkText {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  display: inline-block;
  padding-right: 4px !important;
  margin-right: 4px;
  border-right: 1px solid #000;
  line-height: 16px;
}
.videoCnt .loopdata {
  padding-left: 0px !important;
  float: left;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.productDetails_content .loopdata {
  float: left;
  width: 80%;
}
.popover-inner .popover-body {
  padding: 0px;
}
.videoThumbDiv img:hover + .showDotPop {
  display: block;
}
.threeDot .starRatingBrw {
  width: 54%;
  float: left;
  padding-left: 30px;
  padding-top: 10px;
}
.threeDot .videoPrice {
  width: 38%;
  margin-top: 0px;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
}
#libraryTabOuter .mySnovelVideo .playButn {
  position: absolute;
  top: 34%;
  left: 38%;
  z-index: 99;
  cursor: pointer;
}
.playButn img {
  height: 3rem;
}
.threeDotImg {
  position: absolute;
  right: 16px;
  bottom: 40px;
}
.browseLatest_Lauch {
  width: 100%;
  float: left;
}
.browseLatest_Lauch .owl-carousel .owl-item img {
  width: 50px;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  margin-left: 0px;
}
.browseTopSliderWrp .owl-carousel .owl-item .topBrowseImage img {
  height: 100%;
}
.browseSlideCategory a:last-child {
  border-right: none;
}
.browseLatest_Lauch .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 84px;
}
.browseLatest_Lauch .owl-nav .owl-prev,
.browseLatest_Lauch .owl-nav .owl-next {
  color: #000;
}
.browseLatest_Lauch .owl-carousel .owl-item .videoThumbDiv img {
  width: 100%;
  height: 100%;
}
.browseLatest_Lauch .popularProduct_Slider {
  margin-bottom: 16px;
}
.readyBrw {
  background: #f9f9f9;
  float: left;
  border: 1px solid #b4b4b4;
  padding: 2% 5%;
  margin-bottom: 50px;
  width: 100%;
}
.browseLatest_Lauch .sliderTrack_Wrap {
  position: relative;
  top: 46px;
}
.readyBrw h2 {
  font-size: 36px;
  font-weight: 100;
  text-transform: capitalize;
}
.readyBrw p {
  color: #818183;
  font-weight: 100;
  font-size: 14px;
}
.readyBrw .track-btn {
  max-width: 100%;
}
.browseReady_Begin {
  width: 100%;
  float: left;
  margin-top: 36px;
}
.readyBrw .track-btn .purchaseButton:hover a {
  color: #fff;
}
.readyBrw .track-btn .purchaseButton a {
  color: #da145a;
  font-size: 16px;
}
.readyBrw .track-btn .purchaseButton {
  border: 2px solid #da145a;
  padding: 10px 36px 10px 36px;
  float: left;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
}
.browseType_Wrapper .homeTabs {
  padding: 40px 0px 10px;
}
.browseLatest_Lauch h2 {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #212433;
  margin-top: 50px;
  margin-bottom: 15px;
}
.browseLatest_Lauch .popularImage_Wrapper img {
  width: 100%;
  height: 100%;
  margin-left: 0px;
}
.videoThumbCateDiv {
  width: 100%;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
  margin-top: 3px;
}
.videoThumbCateDiv div {
  float: left;
}
.videoThumbCateDiv span {
  color: #818183;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 0px;
}
.videoThumbCateDiv a {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  display: inline;
  padding: 0px 4px 0px 0px;
  margin-right: 4px;
  border-right: 1px solid #818183;
  line-height: 14px;
}
.videoThumbCateDiv a:last-child {
  border-right: none;
}
.homeTabs .innerProductsTab li.nav-item a,
.homeTabs .innerProductsTab li.nav-item a:hover {
  padding: 0px !important;
  border: none;
  cursor: pointer;
}

/* Library Page */
.common-modal.modal-dialog {
  max-width: 1200px;
  width: 100%;
}
.common-modal.modal-dialog .modal-content {
  padding-bottom: 50px;
  background-color: #f9f9f9 !important;
}
.common-modal.modal-dialog .modal-header .close {
  font-size: 21px;
  opacity: 0.2;
}
.common-modal.modal-dialog .modal-header {
  border-bottom: 0px;
}
.common-modal.modal-dialog .modal-body {
  padding: 13px;
  padding-bottom: 0px;
}
.common-modal.modal-dialog .modal-body h4.modal-title {
  color: #da145a;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  float: left;
  width: 100%;
  margin-left: 35px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.myprofilePopLft {
  float: left;
  width: 80px;
}

.myprofilePopLft img {
  width: 80px;
  margin-top: 42px;
  border-radius: 50%;
}

.myprofilePopRgt {
  max-width: 1085px;
  float: left;
  width: 100%;
  color: #000 !important;
}

.myprofilePopRgtTop {
  max-width: 1085px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.myprofilePopTitle {
  color: #352e67;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  float: left;
  width: 100%;
  margin-left: 35px;
  margin-bottom: 30px;
}

.myprofilePopRgt .planNameDiv p:nth-of-type(1) {
  margin: 0px;
}

.myprofilePopRgt .planNameDiv p:nth-of-type(2) {
  margin-top: 10px;
}

.myprofilePopLocation {
  width: 100%;
  float: left;
}

.myProfilePopLocDiv {
  width: 90%;
  float: left;
  font-size: 16px;
  font-weight: 300;
  margin-left: 35px;
}

.myProfilePopLocDiv span.spanCon {
  width: 60px;
  float: left;
}

.myProfilePopLocDiv span.spanInd {
  /*width: 100px;*/
  float: left;
}

.myProfilePopLocDiv span.spanConBtm {
  width: 100px;
  float: left;
  margin-bottom: 10px;
}

.myProfilePopLocDiv span.spanConBtm:nth-of-type(1) img {
  width: 24px;
  margin-right: 20px;
}

.myProfilePopLocDiv span.spanConBtm:nth-of-type(2) img {
  width: 24px;
  margin-right: 20px;
}

.myProfilePopLocDiv span.spanIndBtm {
  max-width: 600px;
  width: 100%;
  float: left;
}

.myprofilePopLocation span.subsCribeButton a {
  border: 2px solid #da145a;
  border-radius: 30px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 100px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

.myProfilePopLocDiv a {
  color: #000;
}

.myprofilePopLocation span.purchaseButton a img {
  margin-right: 10px;
  width: 18px;
}

.myprofilePopLocation span.purchaseButton a {
  color: #da145a;
}

.myprofilePopLocation span.purchaseButton {
  border: 2px solid #da145a;
  border-radius: 30px;
  padding: 14px 17px;
  color: #da145a;
  float: left;
  width: 186px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.changePasswordWrap {
  width: 50%;
  margin-bottom: 30px;
}
.myprofilePopLocation span.purchaseButton.changePasswordBtn {
  width: 200px;
  margin-left: 20px;
}

.myProfilePopLocDiv .billCheckBx {
  margin-top: 0px;
  margin-bottom: 50px;
}

.myProfilePopLocDiv span.subsCribeNews {
  margin-left: 17px;
}

.billCheckBx {
  width: 100%;
  float: left;
  margin-left: 30px;
  margin-top: 34px;
}

.billCheckBx label {
  font-weight: 300;
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.billCheckBx .checkmarkbx {
  left: -29px;
}
a.visit-faq-link {
  padding: 0;
  display: inline-block;
}
.row.left-right-margin-none {
  margin-left: 0;
  margin-right: 0;
}

.billingTopWrp {
  float: left;
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-top: 40px;
  line-height: 24px;
}

.billingTopWrp .col-lg-9 {
  padding: 3%;
}

.billingTopWrp .col-lg-3 {
  padding: 2%;
}
.paymentSuccess {
  border-bottom: 1px solid #ccc;
}

.billProfileLFtLogo {
  background: #352e67;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
}

.billProfileLFtLogo span {
  color: #fff;
  display: block;
  text-align: center;
  padding-top: 40px;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
}

.planNameDiv {
  float: left;
  margin-left: 35px;
}

.planNameDiv p:nth-of-type(1) {
  font-weight: 400;
  font-size: 16px;
  color: #212433;
  margin-bottom: 10px;
}

.planNameDiv h1 {
  color: #352e67;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
}

.planNameDiv p:nth-of-type(2) {
  font-weight: 600;
  font-size: 16px;
  color: #212433;
  margin-bottom: 10px;
}

.planNameDiv p:nth-of-type(2) span {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

.billingProfile a {
  float: left;
  width: 100px;
  margin-left: 83px;
  font-weight: 400;
  font-size: 16px;
  color: #dd4b39;
}

.billingBell {
  position: relative;
  float: left;
  width: 40px;
  cursor: pointer;
}

.billingBell img {
  width: 24px !important;
}

.billingBell span {
  position: absolute;
  background: #dd4b39;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  right: -7px;
  top: -12px;
}

.mySnovelFaq {
  float: left;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0px;
  margin: 20px 0px 30px;
  line-height: 24px;
}

.mySnovelFaq .visitFaq {
  font-weight: 400;
  font-size: 15px;
}

.mySnovelFaq .visitFaq span {
  font-weight: 400;
  font-size: 16px;
  color: #da145a;
}

.mySnovelFaq .emailRight {
  text-align: right;
  display: block;
}
/* .popover-inner .popover-body {
    display: inline-flex;
    } */
.SocialMediaShareButton {
  margin-right: 10px;
}
.alert-modal.modal-dialog .modal-content {
  padding-bottom: 0px;
}
.alert-modal.modal-dialog .modal-body {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0;
}
.alert-modal.modal-dialog .modal-header {
  padding: 15px;
}
.alert-modal.modal-dialog .modal-body h4.modal-title {
  margin-left: 0px;
}
.alertTopDiv {
  float: left;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.alertTopDiv h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 10px;
}

.alertTopCol {
  max-width: 100%;
  float: left;
  width: 100%;
  margin-right: 20px;
}
.alertTopDiv .owl-carousel .owl-nav {
  top: 92px;
}
.alertTopDiv .owl-carousel .owl-nav .owl-next {
  background-color: #212433;
  border-radius: 50%;
  padding: 8px 12px;
  width: 40px;
  height: 40px;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.alertTopDiv .owl-carousel .alertTopCol .alertTopColImg img {
  height: 240px;
}
.alertTopDiv .owl-carousel .owl-nav .owl-prev {
  background-color: #212433;
  border-radius: 50%;
  padding: 8px 8px;
  width: 40px;
  height: 40px;
}
.alertTopDiv .owl-carousel .owl-nav .owl-next::after {
  font-size: 26px;
}
.alertTopDiv .owl-carousel .owl-nav .owl-prev::before {
  font-size: 26px;
}

.alertTopColImg {
  float: left;
  width: 100%;
  position: relative;
}
.alertTopColImg img {
  margin: 0px;
}

.newImage {
  position: absolute;
  width: 76px;
  height: 66px;
  left: 0px;
  top: 0px;
  background: url("/../../images/new_strip.png") no-repeat;
}

.newImage .newAlert {
  -ms-transform: rotate(20deg);
  /* IE 9 */
  -webkit-transform: rotate(20deg);
  /* Safari 3-8 */
  transform: rotate(-42deg);
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  margin-top: -4px;
}

.alertTopCol p {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.alertTopCol p span {
  font-size: 13px;
  color: #b4b4b4;
  font-weight: 400;
}

.mediaNewsLft {
  float: left;
  width: 50%;
  padding-right: 2%;
  border-right: 1px solid #b4b4b4;
  margin-bottom: 3%;
}
.accolades_news .mediaNewsLft:nth-child(even) {
  padding-left: 2%;
  border-right: none;
  margin-bottom: 3%;
}
.accolades_news {
  width: 100%;
}
.mediaNewsLft p,
.mediaNewsRgt p {
  color: #484848;
}
.accolades_news_wrap {
  display: flex;
}

.mediaNewsTitle {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
}

.mediaNewsTitle p {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

.mediaNewsMore {
  float: left;
  width: 50%;
  font-size: 15px;
}

.mediaNewsMore a {
  color: #da145a;
}

.mediaNewsDate {
  float: left;
  width: 100%;
  text-align: right;
  font-size: 15px;
}

.mediaNewsRgt {
  float: left;
  width: 48%;
  padding-left: 2%;
}
#slidecontrols {
  float: left;
  width: 80%;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}
.topLanguage .libraryBskDiv {
  margin-top: 0px;
  float: left;
  margin-left: 11px;
  width: 70px;
  cursor: pointer;
}
.libraryBskDiv p {
  margin-bottom: 0px;
}
.libraryBskDiv img {
  width: 24px !important;
  float: left;
  margin-right: 10px;
}

.mySnovel p {
  font-size: 18px;
  font-weight: 400;
  color: #484848;
  margin-bottom: 10px;
}

.mySnovelVideo {
  border: 1px solid #bcbdc1;
  float: left;
  padding-bottom: 35px;
  margin-top: 10px;
  width: 100%;
}

.mySnovelVideo .col-lg-10 {
  float: left;
}

.mySnovelVideo .videoThumbDiv {
  width: 178px;
  float: left;
  margin-top: 32px;
}

.mySnovelVideo .videoDesc {
  width: 79%;
  margin-top: 32px;
  padding-left: 33px;
}

.mySnovelVideo .videoDesc h4 {
  color: #000;
  font-weight: 600;
}

.mySnovelVideo .videoDesc h4 span {
  cursor: pointer;
}

.mySnovelVideo span.cateTitle {
  color: #da145a;
}

.freeTrailMpbileVideo .playButn {
  position: absolute;
  top: 34%;
  left: 35%;
  z-index: 99;
  cursor: pointer;
}
.tryFree-snovel-wrapper .freeTrailMpbileVideo .playButn img {
  width: 50px;
}
.purchaseAction-wrap {
  width: 100%;
  float: left;
}
.videoPurchase .loginErrorMsg {
  margin-top: 5px;
  width: 100%;
  float: left;
}

.mySnoveltrackDiv {
  float: left;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #352e67;
  border: 1px solid #000;
  margin-left: 9px;
  margin-top: 35px;
}

.mySnoveltrackDiv span {
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-top: 18px;
  line-height: 14px;
}

.mySnoveltrackDiv span strong {
  font-size: 26px;
  font-weight: 600;
}

.mySnoveldurDiv {
  float: left;
  width: 100%;
  margin-left: 0px;
}

.mySnoveldurDiv span {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  text-align: center;
  margin: 10px 0px 16px 0px;
  float: left;
}

.mySnoveldurDiv span strong {
  font-size: 16px;
  font-weight: 600;
  margin: 8px;
}

.mySnovelBx {
  border: 1px solid #bcbdc1;
  border-radius: 5px;
  line-height: 24px;
}

.mySnovel h3 {
  font-size: 18px;
  color: #000;
  text-transform: inherit;
  font-weight: 600;
}

.mySnovelBx p {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-left: 50px;
}

.mySnovelBx p:nth-child(1) {
  padding-top: 60px;
  margin-bottom: 0px;
}

.mySnovelBx p:nth-child(3) {
  padding-bottom: 60px;
}

.mySnovelBx p:nth-child(2) {
  margin-top: 0px;
}

.mySnovelBx p strong {
  font-weight: 400;
  font-size: 36px;
  color: #000;
}

.mySnovelBx p small {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  margin-left: 10px;
}

.mySnovelBxSec p:nth-child(1) {
  padding-top: 80px;
  padding-bottom: 88px;
  margin-left: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.mySnovelBxSec p:nth-child(1) span {
  font-weight: 400;
  font-size: 48px;
}

.mySnovelBxThird p:nth-child(1) {
  margin-left: 30px;
  padding-top: 14px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
}

.mySnovelBxThird p:nth-child(1) strong {
  font-size: 24px;
  font-weight: 400;
}

.mySnovelBxThird p:nth-child(2) {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2px;
}

.mySnovelBxThird p:nth-child(2) span {
  font-weight: 600;
  font-size: 16px;
  color: #da145a;
}

.mySnovelBxThird p:nth-child(3) {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 2px;
}

.mySnovelBxThird p:nth-child(3) span {
  font-weight: 600;
  font-size: 16px;
  color: #da145a;
}

.mySnovelBxThird p:nth-child(4) {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
}

.mySnovelBxThird p:nth-child(4) span {
  font-weight: 600;
  font-size: 16px;
  color: #da145a;
}

.mySnovelBxThirdLast {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  padding: 15px 20px;
}

.mySnovelBxFourth p:nth-child(1) {
  width: 215px;
  margin: 0 auto;
  border-bottom: 1px solid #bcbdc1;
  padding-bottom: 7px;
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  padding-top: 40px;
}

.mySnovelBxFourth p:nth-child(1) span {
  font-size: 16px;
  font-weight: 400;
}

.mySnovelBxFourth p:nth-child(2) {
  width: 215px;
  margin: 0 auto;
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 40px;
}

.mySnovelBxFourth p:nth-child(2) span {
  font-size: 16px;
  font-weight: 400;
}

.mySnovelBx p.snovel-run-time {
  padding-top: 98px;
  padding-bottom: 98px;
}

.library-player {
  margin-bottom: 100px;
}

.left-padding-none {
  padding-left: 0px !important;
}

.mySnovelVideo .videoCnt span.author,
.mySnovelVideo .videoCnt span.authorDes,
.mySnovelVideo .videoCnt {
  color: #484848;
}

.mySnovelVideo .starRating {
  width: 130px;
}

.mySnovelVideo .starRating img {
  margin: 0px;
}

.playBar img {
  margin: 0px;
  width: 100%;
}
.videoBar {
  width: 100%;
  float: left;
  background: #000;
  padding: 14px 50px;
  font-weight: 400;
}
.videoBar {
  width: 100%;
  float: left;
  background: #000;
  padding: 14px 50px;
  font-family: "robotoregular";
}

.videoShuffle {
  float: left;
  width: 25px;
  margin-right: 60px;
}

.videoBack {
  float: left;
  width: 25px;
}

.videoPlay {
  float: left;
  width: 25px;
  margin: 0px 20px;
}

.videoForward {
  float: left;
  width: 25px;
}

.videoSlide {
  float: left;
  width: 645px;
  margin: 0px 45px;
  position: relative;
}

.lftTimer {
  position: absolute;
  left: 0px;
  top: -12px;
  color: #fff;
  font-size: 12px;
}

.rgtTimer {
  position: absolute;
  right: 0px;
  top: -12px;
  color: #fff;
  font-size: 12px;
}

.videoVol {
  float: left;
  width: 25px;
  margin-right: 60px;
}

.videoDot {
  float: left;
  width: 9px;
  position: relative;
}
.searchAuthour a:hover {
  color: #484848;
  text-decoration: none;
}
.showShareDiv {
  display: none;
  position: absolute;
  right: 0%;
  background: #fff;
  width: 180px;
  top: 41px;
  -webkit-box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 6px 20px -6px rgba(0, 0, 0, 0.75);
  z-index: 1;
  padding: 20px;
  border-radius: 5px;
  left: -89px;
}
.mySnovelVideo .mySnovelVideo-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.showShareTop {
  position: absolute;
  top: -23px;
  left: 45%;
}

.showShareTop img {
  width: 27px;
}

.showSharDivTop {
  float: left;
  width: 100%;
  border-bottom: 1px solid #bcbdc1;
  padding: 10px 0px;
}

.showSharDivTop img {
  width: 16px;
}

.showSharDivTop span {
  margin-left: 20px;
  font-size: 16px;
  color: #000;
}

.library-subscription .unlimiTedAnnul h2 {
  font-weight: 600;
}

.library-subscription .unlimiTedAnnulBx {
  background: #212433;
  color: #fff;
  padding: 5%;
  margin-left: -15px;
  margin-right: -15px;
  font-size: 22px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 25px;
  line-height: 24px;
  min-height: auto;
}

.mySnovel .unlimiTedAnnul {
  width: 32%;
  padding-bottom: 0px;
  float: left;
  max-width: 32%;
  line-height: 24px;
}

.mySnovel .unlimiTedAnnul ul li {
  font-size: 15px;
}

.mySnovel .libraySubs {
  padding-bottom: 44px;
  padding-left: 40px;
}

.libraySubs {
  margin-left: 2%;
  margin-right: 2%;
  color: #000;
}

.libraySubs p:nth-child(1) {
  font-weight: 400;
  font-size: 16px;
  text-align: left !important;
  color: #000;
}

.libraySubs p:nth-child(1) span {
  text-align: center;
  font-weight: 400;
  font-size: 28px;
  text-transform: capitalize;
  color: #000;
}

.libraySubs p:nth-child(2) {
  font-weight: 400;
  font-size: 16px;
  text-align: left !important;
  margin-bottom: 50px;
  color: #000;
}

.libraySubs p:nth-child(2) span {
  text-align: center;
  font-size: 28px;
  text-transform: capitalize;
  color: #000;
  font-weight: 600;
}

.libraySubs p:nth-child(3) {
  font-weight: 400;
  font-size: 16px;
  text-align: left !important;
  font-style: italic;
  color: #000;
}

.libraySubs p:nth-child(3) span {
  text-align: center;
  font-size: 28px;
  text-transform: capitalize;
  color: #da145a;
  font-weight: 400;
  font-style: italic;
}

.libraySubs p:nth-child(4) {
  font-weight: 400;
  font-size: 16px;
  text-align: left !important;
  font-style: italic;
  color: #000;
}

.libraySubs p:nth-child(4) span {
  text-align: center;
  font-size: 28px;
  text-transform: capitalize;
  color: #da145a;
  font-weight: 400;
  font-style: italic;
}

.experience {
  color: #000;
}

.experience p {
  color: #000;
  padding-left: 25px;
}

.experience h2 {
  text-align: left !important;
  padding-left: 25px;
  margin-bottom: 16px;
}

.experience p {
  font-weight: 400;
  font-size: 24px;
  text-align: left !important;
  line-height: 30px;
}

.experience p strong {
  font-size: 30px;
  font-weight: 600;
  margin-right: 10px;
}

.experience p em {
  font-weight: 400;
  font-size: 14px;
}

.letsBegin {
  background: #352e67;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-top: 30px;
}

.letsBegin a {
  color: #ffffff !important;
}
a.inline-nav-link {
  display: inline;
}

.liraryBtnFav p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 30px;
}

.liraryBtnFav {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.liraryBtnFav a {
  border: 2px solid #da145a;
  border-radius: 20px;
  padding: 12px 17px;
  color: #da145a;
  /* width: 300px;*/
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none !important;
}

.liraryBtnFav span.subsCribeButton {
  float: none;
  margin-right: 0px;
  border: none;
}

.planNameDiv p.no-subscription {
  margin-top: 40px;
}

.field-section-outer {
  width: calc(100% - 35px);
  clear: both;
  margin-left: 35px;
}
.profile-error-message {
  color: red;
  margin-bottom: 15px;
  font-weight: 400;
}
.profile-input-field {
  margin-bottom: 0px;
}

.goToLib {
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 16px;
  padding-top: 15px;
}

a.goToLib {
  color: #da145a !important;
  cursor: pointer;
}

label.custom-male-radio,
label.custom-other-radio {
  width: 76px;
}

label.custom-female-radio {
  width: 94px;
}

label.gender-custom-label {
  margin-bottom: 1rem;
}

label.custom-male-radio input,
label.custom-female-radio input,
label.custom-other-radio input {
  left: auto !important;
}

.react-datepicker-wrapper input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
span.uploadButton {
  margin-right: 0;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  text-transform: none;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
span.uploadButton input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  font-size: 100px;
}

img.purchase-library-image {
  max-width: 100%;
}

.video-js {
  width: 100%;
  height: 43px;
}

.footer-hls-player {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1050;
}

.trackListBackground {
  width: 100%;
  height: 200px;
  background-color: #000000;
  opacity: 0.5;
}

.videoPlayList {
  float: left;
  width: 100%;
  font-weight: 400;
  padding: 15px 30px 10px;
  border-bottom: 1px solid #b4b4b4;
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
}

.videoPlayList:hover,
.videoPlayList.active-play-list {
  background: #f8dbe5;
}

.videoPlayP {
  width: 15px;
  float: left;
  cursor: pointer;
}

.videoPlayTitle {
  max-width: 860px;
  width: 100%;
  float: left;
  margin-left: 90px;
  color: #000;
  font-size: 18px;
}

.videoPlayTitle span {
  margin-right: 20px;
}

.videoPlayDuration {
  max-width: 90px;
  width: 100%;
  float: left;
  text-align: center;
  margin-top: -10px;
  font-size: 15px;
}

.videoPlayDuration span {
  color: #000;
}
.footer-player-heading {
  width: 100%;
  clear: both;
  height: 30px;
  align-items: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
}
.footer-player-heading .image-outer {
  float: left;
  height: 29px;
  width: 29px;
}
.footer-player-heading .image-outer img {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.footer-player-heading .previous-track-outer,
.footer-player-heading .next-track-outer {
  float: left;
  height: 17px;
  width: 17px;
  cursor: pointer;
  margin-right: 13px;
}
.footer-player-heading .next-track-outer {
  margin-right: 10px;
  margin-left: 10px;
}
.footer-player-heading .next-track-outer img,
.footer-player-heading .previous-track-outer img {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.footer-player-heading .title-outer {
  float: left;
  width: 300px;
  padding-top: 3px;
  height: 29px;
  cursor: pointer;
}
.footer-player-heading .title-outer .nav-link {
  padding: 0;
  color: #ffffff;
}
.footer-player-heading .title-outer:hover {
  text-decoration: underline;
}
.product-name {
  margin-top: 10px;
  overflow: hidden;
  height: 1.5em;
  cursor: pointer;
}
.product-name:hover {
  text-decoration: underline;
}
.track-list {
  height: 300px;
  position: relative;
  background-color: white;
  padding-bottom: 30px;
}
.close-button {
  width: 100%;
  height: 25px;
}
.close-image {
  height: 25px;
  width: 25px;
  float: right;
  cursor: pointer;
}
.tracks-background {
  max-height: 100%;
  overflow: auto;
}
.track-background-div {
  width: 100%;
}
.player-background {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  background-color: rgb(53, 46, 103);
}
.product-artwork {
  height: 75px;
  width: 75px;
  background-color: rgb(33, 33, 52);
}
.artwork {
  height: 75px;
  width: 75px;
}
.product-info {
  height: 75px;
  width: 100%;
  display: flex;
  margin-left: 10px;
  align-items: center;
  flex-direction: column;
  color: white;
}
.hls-background {
  width: 100%;
  height: 30px;
}
/* Library Page */

/********  Forget Password  ********/

.ForgetPassword input[type="submit"] {
  border: 2px solid #da145a;
  padding: 10px 60px 10px 60px;
  border-radius: 50px;
  background: #da145a;
  color: #fff;
  cursor: pointer;
  max-width: 370px;
  width: 100%;
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}
.forgetPass .modal-content {
  background-color: #eaeaf0 !important;
  font-weight: 400;
}
.forgetPass .modal-body h4 {
  color: #352e67;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin: 0px;
  padding: 0px;
  line-height: 45px;
  text-transform: capitalize;
}
.forgetPass .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.forgetPass {
  margin-top: 8% !important;
}
.forgetPass .modal-body {
  max-width: 68%;
  margin: 0 auto;
  padding-top: 2px;
  width: 100%;
  padding-bottom: 60px;
}
/* .forgetPass .modal-content{
  width: 96.5%;
} */
.forgetPass .modal-body h4.createAccount {
  margin-bottom: 15px;
}
.forgetPass .modal-body p {
  color: #484848;
}
.resetPasswordSuccess {
  color: green;
}
.successMessage {
  color: green;
  width: 100%;
  float: left;
  text-align: center;
}
.forGotPass span {
  cursor: pointer;
}
.ForgetPassword #register-form .form-group,
.ForgetPassword #register-form .form-group input {
  margin-bottom: 0px;
}
.browseSlideCategory div {
  float: left;
}
.browseSlideCategory .nav-link {
  padding: 0px;
  display: inline;
}
.browseSlideCategory span {
  color: #fff;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 0px;
  margin-right: 4px;
}
.videoThumbTitleDiv .briefTitle a.authorTile {
  color: #818183;
}
.briefTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.briefTitle a.nav-link {
  display: inline;
  padding: 0px;
}
.confirmPasswordForm {
  padding-bottom: 40px;
}
.popover-body .treeDotTop h4 {
  color: #fff;
  text-transform: inherit;
}
.popover.show {
  max-width: 400px;
}
.videoThumbDiv .sliderTrack_Wrap {
  position: absolute;
    bottom: 10px;
    float: right;
    right: 10px;
    margin: 0;
    width: 34px;
    height: 34px;
    cursor: pointer;
}
.ScrollUpButton__Container {
  transition: opacity 0.5s ease-in-out 0s, right;
  position: absolute;
  bottom: 0px;
}
.ScrollUpButton__Container.ScrollUpButton__Toggled {
  position: fixed;
  bottom: 20px;
  width: 42px;
  transition: opacity 0.5s ease-in-out 1s, right;
  cursor: pointer;
  opacity: 0.7;
  right: 20px;
  z-index: 1000;
  overflow: hidden;
  height: 24px;
  border: none;
  box-shadow: none;
  outline: none;
}
.ScrollUpButton__Container.ScrollUpButton__Toggled.toggle-with-player {
  bottom: 87px;
}

.selectedTabTxtDiv {
  width: 100%;
  float: left;
}
.homeTabs .tab-content {
  width: 100%;
  float: left;
}
.selectedTabTxtBrd {
  border: 1px solid #b4b4b4;
  padding: 6px 10px;
  border-radius: 25px;
  width: 200px;
  float: left;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.selectedTabTxtBrd .fa-close {
  float: right;
  margin-top: 3px;
  cursor: pointer;
}
.disableTab {
  pointer-events: none;
}
.breadCrumbMenu {
  margin-left: 5px;
}

/**********    About Us Page   ************/

.aboutTopWrp {
  background: url("/../../images/bg_about_us.jpg") no-repeat;
  float: left;
  width: 100%;
  padding-top: 54px;
  padding-bottom: 54px;
  font-weight: 400;
  color: #fff;
  background-position: center;
}

.aboutTopWrp h1 {
  color: #da145a;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  width: 100%;
  margin-bottom: 0px;
}

.aboutTopWrp h3 {
  color: #ffffff;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.aboutTopWrp p {
  font-weight: 400;
  color: #fff;
  max-width: 96%;
}

.aboutUsmiddlewrp {
  float: left;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #b4b4b4;
  margin-bottom: 50px;
}

.aboutUsmiddlewrp h4 {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: #484848;
}

.aboutUsmiddlewrp p {
  font-weight: 400;
  font-size: 14px;
}

.aboutUsmiddlewrp span {
  color: #da145a;
  font-weight: 400;
  font-size: 16px;
}

.abtImg {
  float: left;
  width: 100%;
}

.abtImg img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 972px;
}

.happyUsers h3 {
  color: #da145a;
  font-weight: 400;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 30px;
}

.happyUsers span img {
  max-width: 25px;
}

.happyUserComment .col-lg-6 {
  padding-left: 0px;
}

.firstComment {
  border-left: 2px solid #da145a;
  float: left;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
}

.firstComment p {
  color: #484848;
}

.firstComment span strong {
  color: #000;
  font-size: 16px;
}
.firstComment.one {
  border-left: 2px solid #da145a;
}
.firstComment.two {
  border-left: 2px solid rgb(53, 46, 103);
}
.firstComment.three {
  border-left: 2px solid rgb(27, 180, 237);
}
.firstComment.four {
  border-left: 2px solid rgb(113, 218, 20);
}
.firstComment.five {
  border-left: 2px solid rgb(20, 203, 201);
}
.firstComment.six {
  border-left: 2px solid rgb(113, 218, 20);
}
.firstComment.seven {
  border-left: 2px solid rgb(238, 132, 37);
}
.firstComment.eight {
  border-left: 2px solid #da145a;
}

/************  contact us  ****************/

.contactusTopWrp {
  background: #352e67;
  padding: 50px 50px;
  width: 100%;
  float: left;
}

.contactFormDiv h4 {
  text-transform: initial;
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 15px;
}

.topcontentDiv {
  border-left: 1px solid #fff;
  float: left;
}

.contactLogo {
  float: left;
}

.contactLogo img {
  float: right;
  width: 110px;
  margin-top: 58px;
}

.topcontentDiv h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.topcontentDiv h5 {
  text-transform: initial;
  color: rgb(27, 180, 237);
  font-size: 14px;
}

.topcontentDiv h4 {
  color: #da145a;
  text-transform: initial;
  font-weight: 600;
  margin-bottom: 20px;
}

.emiilDiv {
  float: left;
  width: 180px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 2px;
}

.emiilDiv a {
  color: #fff;
  font-weight: 400;
}
.contactAddress {
  width: 100%;
  float: left;
}
.phoneDiv {
  float: left;
  margin-top: 2px;
}
.phoneDiv a {
  color: #fff;
  font-weight: 400;
}

.emiilDiv a:hover,
.phoneDiv a:hover {
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

.emiilDiv span {
  margin-left: 5px;
}

.phoneDiv span {
  margin-left: 5px;
}

.fa-envelope-o {
  color: #fff;
  vertical-align: middle;
}

.fa-phone {
  color: #fff;
  vertical-align: middle;
}

.topcontentDiv p {
  color: #fff;
  margin-top: 10px;
  font-weight: 400;
}

.contactFormDiv {
  margin-top: 50px;
  font-weight: 400;
  width: 100%;
  float: left;
}

.contactform {
  background: #e8e9ea;
  padding: 20px 20px;
  margin-bottom: 50px;
  border-radius: 5px;
  float: left;
  width: 100%;
  font-weight: 400;
}

.formDivleft {
  width: 48%;
  float: left;
  font-weight: 400;
}
.formDivleft label,
.formDivright label,
.formfullwidth label {
  font-weight: 600;
  color: #484848;
  margin-bottom: 0px;
  padding-left: 3px;
}
.contactform .form-control {
  margin-bottom: 3px !important;
}
.contactform .loginErrorMsg {
  margin-bottom: 20px;
}

.formDivright {
  width: 48%;
  float: right;
  font-weight: 400;
}
.placeOrderDisable {
  pointer-events: none;
  opacity: 0.6;
}
.contactform input[type="text"],
select {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-weight: 400;
  height: 50px;
}

textarea {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-weight: 400;
}
.contactform input[type="submit"] {
  background: #da145a;
  border-radius: 50px;
  border: 1px solid #da145a;
  padding: 12px 44px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.contactSubmit button[type="submit"] {
  background: #da145a;
  border-radius: 50px;
  border: 1px solid #da145a;
  padding: 12px 44px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  float: left;
}
.project-audition-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  margin-bottom: 20px;
}
.project-audition-wrapper .auditionScript {
  background: #da145a;
  border-radius: 50px;
  border: 1px solid #da145a;
  padding: 12px 44px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  float: left;
}
.project-audition-wrapper .auditionScript:hover {
  background: #da145a;
  border: 1px solid #da145a;
}
.contactSubmit .success {
  float: left;
  padding: 10px 30px;
  color: green;
}

.contactTopleft {
  margin-left: 50px;
}
.topContainer {
  width: 100%;
  float: left;
}
.formfullwidth {
  text-align: left;
  width: 100%;
  float: left;
}
.contactSubmit {
  width: 70%;
  float: left;
  padding-top: 14px;
}
.contact-button-wrapper {
  width: 100%;
  float: left;
}
.contact-button-wrapper #g-recaptcha {
  width: 30%;
  float: left;
}

.billProfileLFtLogo {
  background: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
}

.billProfileLFtLogo span {
  color: #fff;
  display: block;
  text-align: center;
  padding-top: 40px;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
}

.planNameDiv {
  float: left;
  margin-left: 35px;
}

.planNameDiv p:nth-of-type(1) {
  font-weight: 400;
  font-size: 16px;
  color: #212433;
}

.planNameDiv h1 {
  color: #352e67;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.planNameDiv p:nth-of-type(2) {
  font-weight: 600;
  font-size: 16px;
  color: #212433;
}

.planNameDiv p:nth-of-type(2) span {
  font-weight: 400;
  font-size: 16px;
}

.billingProfile a {
  float: left;
  width: 100px;
  margin-left: 83px;
  font-weight: 400;
  font-size: 16px;
  color: #dd4b39;
}
.billingProfile a:hover {
  color: #dd4b39;
}

.billingBell {
  position: relative;
  float: left;
  width: 40px;
  cursor: pointer;
}

.billingBell img {
  width: 24px !important;
}

.billingBell span {
  position: absolute;
  background: #dd4b39;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  right: -7px;
  top: -12px;
}

.billingTerms {
  float: left;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #dd4b39;
  border: 1px solid #dd4b39;
  margin: 10px 0px 35px;
  padding: 5px 0%;
}

.formBillWrp {
  margin-bottom: 100px;
  display: table;
}

.formBillWrp h2 {
  font-weight: 600;
  font-size: 30px;
  color: #000;
  text-transform: capitalize;
}

.formBillLft {
  float: left;
  background: #e8e9ea;
  width: 70%;
  padding: 3%;
}

.formBillLft {
  font-weight: 400;
  font-size: 16px;
}

.billFrmInputLft {
  float: left;
  width: 50%;
  margin-bottom: 14px;
}
.billFrmInputRgt {
  width: 50%;
  float: left;
  margin-bottom: 14px;
}

.billFrmInputLft input[type="text"] {
  max-width: 90%;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 8px 10px;
  border-radius: 5px;
}

.billFrmInputRgt input[type="email"] {
  max-width: 90%;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 8px 10px;
  border-radius: 5px;
}

.billFrmInputLft input[type="tel"] {
  max-width: 90%;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 8px 10px;
  border-radius: 5px;
}

.billFrmInputRgt input[type="text"] {
  max-width: 90%;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 8px 10px;
  border-radius: 5px;
}

.billCheckBx {
  width: 100%;
  float: left;
  margin-left: 30px;
  margin-top: 34px;
}

.billCheckBx label {
  font-weight: 400;
}

.formBillLft p {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
}

.formBillLft p a {
  color: #da145a;
}

.formBillRgt {
  width: 28%;
  float: right;
  border: 1px solid #e8e9ea;
}

.yourOrder {
  float: left;
  background: #212433;
  color: #fff;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  padding: 20px 0px 20px 20px;
}

.yourProdctDiv {
  float: left;
  background: #e8e9ea;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding: 20px 0px 20px 20px;
}

.yourPrdLft {
  float: left;
  width: 70%;
}

.yourPrdRgt {
  float: left;
  width: 30%;
}

.yourProdctDivName {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 0px 8px 20px;
  color: #000;
}

.yourPrdNameLft {
  float: left;
  width: 70%;
}

.yourPrdNameRgt {
  float: left;
  width: 30%;
}

.yourPrdNameCheckLft {
  float: left;
  width: 50%;
}

.yourPrdNameCheckRgt {
  float: left;
  width: 50%;
}

.yourPrdNameCheckRgt input {
  border: 1px solid #000;
  border-radius: 5px;
  width: 120px;
  padding: 2px 5px;
}

.paymentMode {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 0px 8px 20px;
  color: #000;
  border-top: 1px solid #e8e9ea;
  margin-top: 20px;
}

.placeOrder {
  float: left;
  background: #da145a;
  width: 100%;
  padding: 2% 0px;
}

.placeOrder input[type="submit"] {
  border: none;
  background: #da145a;
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.paymentMode [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.paymentMode label {
  font-weight: 400;
}

.paymentMode [type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.paymentMode [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
}

.paymentMode [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: url("/../../images/radio_checked_img.png") no-repeat;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.paymentMode [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.paymentMode [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.paypmentTerms {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  width: 100%;
}

.paypmentTerms a {
  color: #da145a;
}

.paypmentTerms .checkbox {
  margin-bottom: 0px;
}

/* Hide the browser's default checkbox */

.paypmentTerms input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 16px;
  height: 25px;
  width: 25px;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */

.paypmentTerms:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.paypmentTerms input:checked ~ .checkmark {
  background-color: #da145a;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.paypmentTerms input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.paypmentTerms .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*susbcribe checkbox */

.billCheckBx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmarkbx {
  position: absolute;
  top: 0;
  left: -30px;
  height: 25px;
  width: 25px;
  border: 1px solid #000;
  background: #fff;
}

/* On mouse-over, add a grey background color */

.billCheckBx:hover input ~ .checkmarkbx {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.billCheckBx input:checked ~ .checkmarkbx {
  background-color: #da145a;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmarkbx:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.billCheckBx input:checked ~ .checkmarkbx:after {
  display: block;
}

/* Style the checkmark/indicator */

.billCheckBx .checkmarkbx:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*checkout modelpopup*/

#checkOutPop .modal-dialog {
  max-width: 822px !important;
  width: 100%;
}

#checkOutPop .modal-header {
  background: #212433;
}

#checkOutPop .modal-header img {
  width: 24px !important;
}

#checkOutPop .modal-header span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
  text-transform: capitalize;
}

#checkOutPop .close {
  color: #fff;
  opacity: 1;
}

.billCheckoutLft {
  float: left;
  max-width: 344px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
}

.billCheckoutLft .unlimiTedAnnulBx {
  margin-left: 0px;
  margin-right: 0px;
}

.billCheckoutLft .unlimiTedAnnul h3 {
  color: #da145a;
}

.billCheckoutRgt {
  float: left;
  width: 290px;
  margin-top: 140px;
}
.billCheckOutTop {
  float: left;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-bottom: 15px;
  padding-left: 20px;
}
.billCheckOutTop span {
  font-family: "robotobold";
  font-size: 16px;
}
.billCheckOutBtm {
  float: left;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-top: 15px;
  padding-left: 20px;
}
.billCheckOutBtm span {
  font-weight: 600;
  font-size: 16px;
}
.checkOutSub {
  float: left;
  width: 100%;
  border-bottom: 1px solid #b4b4b4;
  border-top: 1px solid #b4b4b4;
  padding: 10px 0px;
  margin-top: 20px;
}
.checkOutSub span {
  float: right;
  width: 344px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.checkOutSub span strong {
  font-size: 24px;
  font-weight: 600;
}
.checkOutBtn {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.checkOutBtn span {
  background: #da145a;
  border-radius: 25px;
  padding: 17px;
  color: #fff;
  width: 170px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.checkOutBtn span a {
  color: #fff;
}
.product-summery .videoPrice {
  color: #000;
}
.product-summery span.subsCribeButton {
  text-transform: capitalize;
  border: none;
}
.popupShareButton.productDetailShare {
  display: block;
  border: 1px solid #ccc;
}

#checkoutInd .modal-title {
  text-transform: capitalize;
  text-align: center;
  font-size: 25px;
  color: #000 !important;
}

.checkoutIndWrp {
  float: left;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 20px 0px;
}

.checkoutIndImg {
  width: 230px;
  float: left;
}

.checkoutIndImg img {
  width: 178px;
}

.checkoutIndCnt {
  width: 375px;
  float: left;
  font-size: 20px;
}

.checkoutIndCnt h3 {
  text-transform: capitalize;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  margin-bottom: 0px;
}

.checkoutIndCnt span {
  color: #484848;
  font-size: 18px;
}

.checkoutIndPrice {
  width: 110px;
  float: left;
  font-size: 20px;
}

.checkoutIndCross {
  width: 20px;
  float: right;
}

.checkoutIndCross a {
  color: #000;
}

.checkoutIndTotalWrp {
  float: left;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 20px 0px;
}

.checkoutIndTotal {
  float: left;
  width: 41%;
  font-size: 20px;
  text-align: right;
}
.leftMarginNone {
  margin-left: 0px;
}

.checkoutIndTotalPrice {
  float: left;
  width: 20%;
  font-size: 20px;
  margin-left: 38%;
}
.mySnovel .unlimiTedAnnulBx p {
  color: #fff;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}
.checkoutIndBtnWrp {
  float: left;
  width: 100%;
  padding: 30px 0px;
}

.checkoutIndBtnLft {
  float: left;
  width: 50%;
}

.applyCoupen {
  border: none !important;
  background: #da145a;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 4px !important;
  margin-top: 6px;
}

.checkoutIndBtnLft a {
  border-radius: 20px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: #da145a;
}

.checkoutIndBtnRgt {
  float: right;
  width: 50%;
}

.checkoutIndBtnRgt a {
  border-radius: 20px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: #da145a;
  float: right;
}

.cart-modal.modal-dialog {
  max-width: 800px;
  width: 100%;
}
.cart-modal.modal-dialog .modal-content {
  background-color: #f9f9f9 !important;
}
.cart-modal .modal-header h5.modal-title {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  color: #000;
}
/*Search page */

.searchLftdivWrp {
  float: left;
  margin-top: 100px;
}

.searchLftdiv {
  float: left;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.searchLftdiv span {
  margin-left: 10px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  margin-top: 3px;
  float: left;
  color: #352e67;
}

.serachLftImg {
  width: 200px;
  float: left;
}

.serachLftImg img {
  width: 32px !important;
  float: left;
}

.serachCount {
  float: left;
  width: 44px;
  background: #352e67;
  border-radius: 25px;
  padding: 2px 16px;
  text-align: center;
  color: #fff;
}

.serachCount span {
  text-align: center;
  color: #fff;
  margin: 0px !important;
}

.serachCross {
  width: 15px;
  float: right;
}

.btmSearch {
  float: left;
  width: 100%;
  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  font-weight: 400;
  padding: 30px 0px 10px;
}
.btmSearch .starRating .star-container {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.btmSearch h2 {
  font-size: 28px;
  color: #000;
  font-weight: normal;
  text-transform: capitalize;
}

.advBtmDiv {
  float: left;
  margin-bottom: 30px;
}

.advBtmImgDiv {
  float: left;
  width: 100px;
}

.advBtmImgDiv img {
  width: 79px;
  cursor: pointer;
}

.advBtmCntDiv {
  float: left;
  width: 150px;
  margin: 0px;
}

.searchCateAdv .searchCateAdvName {
  margin-right: 1%;
  display: inline;
}
.advBtmCntDiv h4 {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
  cursor: pointer;
}

.searchAdvRgtDivWrp {
  float: left;
  width: 100%;
  font-weight: 400;
  margin-top: 40px;
}

.searchAdvRgtDivWrp h3 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
}

.searchAdvRgtDivInnerWrp {
  border: 1px solid #b4b4b4;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.searchAdvRgtDiv {
  float: left;
  width: 100%;
  max-width: 658px;
  padding: 30px;
}

.searchAdvRgtDivImg {
  float: left;
  max-width: 30%;
  width: 100%;
  position: relative;
}

.searchAdvRgtDivImg img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0px;
}

.searchAdvRgtDivCnt {
  float: left;
  max-width: 66%;
  margin-left: 4%;
  width: 100%;
}
.genreLinkText {
  margin-left: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  display: inline-block;
  padding: 0px !important;
}
.genreLinkTextBrowse a {
  padding-bottom: 0px;
}
a.genreLinkText:hover {
  text-decoration: none;
  color: #fff;
}
.authorDes.genereNameLink .genreLinkText span {
  padding-left: 0px;
}
.genereNameLink {
  display: inline-flex;
  padding-left: 10px;
}
.browseGenre .authorDes.genereNameLink span {
  padding-left: 0px;
}
.searchAdvRgtDivCnt h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 5px;
  cursor: pointer;
}

.searchAuthour {
  float: left;
  font-size: 16px;
  width: 100%;
  color: #484848;
}
.searchAuthour a {
  color: #484848;
}

.searchCateAdv {
  float: left;
  width: 100%;
  font-size: 16px;
  margin-bottom: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchCateAdv span {
  color: #352e67;
}

.searchRead {
  float: left;
  max-width: 215px;
  width: 100%;
}

.searchRead a {
  color: #da145a;
}

.searchStar {
  float: left;
}

.searchPrice {
  float: right;
  background: #352e67;
  padding: 38px 0px;
  width: 210px;
}

.searchStar .starRating .star-container {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.searchPricDiv {
  float: left;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 30px;
  margin-bottom: 30px;
}

.searchPricDivLink {
  float: left;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 18px;
}

.searchPricDivLink a {
  color: #fff;
  text-decoration: underline;
}
.searchStar .starRating,
.advBtmCntDiv .starRating {
  width: 100%;
}
.searchCateAdv a.nav-link {
  padding: 0px;
  font-size: 16px;
  display: inline;
}
.searchRead a:hover {
  color: #da145a;
  text-decoration: none;
}
.searchPricDivLink a:hover {
  color: #fff;
}
/*************   Author  **********/
.authorDivWrp {
  float: left;
  width: 100%;
  background: #e8e9ea;
  padding: 40px;
  font-weight: 400;
  margin-top: 40px;
}
.authorDivImg {
  width: 30%;
  float: left;
}
.authorDivImg img {
  width: 275px;
  border: 1px solid #000;
  max-width: 100%;
}
.authorDivCnt {
  float: left;
  width: 70%;
  color: #000;
}
.authorDivCnt span {
  font-size: 15px;
}
.authorDivCnt h1 {
  font-size: 24px;
  text-transform: capitalize;
  color: #000;
  line-height: 30px;
}
.authorDivCnt p {
  margin-top: 40px;
}
.moreAuthor {
  float: left;
  margin-top: 30px;
}
.moreAuthor a {
  color: #da145a !important;
  cursor: pointer;
}
.authorProdcutSlider_Wrap .adveTitleDiv {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 30px;
}
.sliderProductAuthor a.authorTile {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #818183;
}
.sliderProductAuthor a.authorTile:hover {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-decoration: none;
}
.authorSlider {
  width: 100%;
  float: left;
}
.authorSlider .owl-nav .owl-next,
.authorSlider .owl-nav .owl-prev {
  color: #000;
  height: 183px;
  top: -52px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popularProduct_Slider .owl-nav .owl-next,
.popularProduct_Slider .owl-nav .owl-prev {
  color: #000;
  height: 183px;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authorProdcutSlider_Wrap {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.authorProdcutSlider_Wrap .videoThumbCateDiv span {
  margin-top: 0px;
}
.authorSlider .owl-nav {
  top: 10px;
}
#services h2 {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #212433;
  margin-bottom: 0px;
}
.authorSlider .playButn {
  position: absolute;
  top: 26%;
  left: 10%;
  z-index: 99;
  cursor: pointer;
  width: 48px;
}
.authorSliderDefualt img {
  width: 100%;
  height: 214px;
}
.cartIsEmpty {
  padding-top: 60px;
  padding-bottom: 100px;
}
.browseLatest_Lauch .briefTitle a.authorTile {
  color: #818183;
  text-decoration: none;
}
.videoThumbTitleDiv .briefTitle {
  color: #818183;
}
.browseSlideTxtTwo a.authorTile {
  color: #fff;
}
/*************  FAQ page **************/

.faqTitleWrp {
  font-weight: 400;
  margin-bottom: 50px;
  margin-top: 20px;
}

.faqTitleWrp h1 {
  color: #352e67;
  font-weight: 400;
  font-size: 30px;
  text-transform: capitalize;
}

.faq {
  font-weight: 400;
}

.faq .tab-content {
  margin-top: 40px;
}

.faq .nav-tabs > li > a {
  padding: 0px;
}
.accordian-wrap .card-body {
  padding: 10px;
  font-size: 15px;
}
.faq ul.nav-tabs li {
  width: 23%;
}

.faq ul.nav-tabs li h4 {
  font-size: 16px;
}

.faq ul.nav-tabs li {
  font-size: 14px;
  padding: 0px !important;
  margin: 0px 2.6% 0px 0px !important;
}

.faq ul.nav-tabs li:last-child {
  margin-right: 0px !important;
}

.faq ul.nav-tabs li a div:hover {
  background: #352e67;
  color: #fff !important;
}

.faq ul.nav-tabs li a div:hover h4 {
  color: #fff !important;
}

.faq ul.nav-tabs li a {
  background: #f9f9f9;
  color: #000;
}

.faq ul.nav-tabs {
  border: none;
}

.faq ul.nav-tabs li a div {
  padding: 30px 20px;
  border: 1px solid #b4b4b4;
}

.faq ul.nav-tabs li.active a div {
  border: none !important;
}

.faq ul.nav-tabs li.active a {
  border: 1px solid #352e67;
}

.faq ul.nav-tabs li.active a div h4 {
  color: #fff !important;
}

.faq ul.nav-tabs li.active a div {
  background: #352e67;
  color: #fff !important;
}

#owlFaq .owl-item .item {
  cursor: pointer;
  padding: 30px 20px;
  border: 1px solid #b4b4b4;
  background: #f9f9f9;
}
#owlFaq .owl-nav {
  top: 36px;
}
#owlFaq {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.faqContact span a {
  text-decoration: none;
}
.faqContact p {
  color: #000;
}
#owlFaq .owl-nav .owl-prev {
  float: left;
  margin-left: -18px;
}
#owlFaq .owl-nav .owl-next {
  float: right;
  margin-right: -18px;
}
#owlFaq .owl-item .item h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: none;
}
#owlFaq .owl-item .item.activetab p {
  color: #fff;
  margin-bottom: 0px;
}
#owlFaq .owl-item .item p {
  color: #000;
  margin-bottom: 0px;
}
#owlFaq.owl-carousel .owl-nav button.owl-next {
  font-size: 20px;
}

#owlFaq.owl-carousel .owl-nav button.owl-next:hover {
  color: #000;
}

#owlFaq.owl-carousel .owl-nav button.owl-prev {
  font-size: 20px;
}

#owlFaq.owl-carousel .owl-nav button.owl-prev:hover {
  color: #000;
}

#owlFaq.owl-carousel .owl-nav button.owl-next span {
  margin-bottom: 10px;
}

#owlFaq.owl-carousel .owl-nav .owl-next {
  right: 0px;
  top: 34%;
  background: #f9f9f9;
  border: 1px solid #b4b4b4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px 10px;
  position: absolute;
}

#owlFaq .owl-nav .owl-next::after {
  font-size: 14px;
  color: #000;
}

#owlFaq .owl-nav .owl-prev::before {
  font-size: 14px;
  color: #000;
}
.faqTitleWrp p {
  margin-bottom: 0px;
  color: #000;
}

#owlFaq.owl-carousel .owl-nav .owl-prev {
  left: 0px;
  top: 31%;
  background: #f9f9f9;
  border: 1px solid #b4b4b4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px 8px;
  position: absolute;
  z-index: 999;
}

#owlFaq.owl-theme .owl-dots {
  display: none;
}

.activetab {
  background: #352e67 !important;
  color: #fff !important;
}

.activetab div h4 {
  color: #fff !important;
}

.accordian-display-block {
  display: block !important;
  width: 100%;
  float: left;
}

.accordian-display-none {
  display: none;
}
.accordian .ui-state-active {
  background: #352e67 !important;
  color: #fff !important;
}

.faq .ui-state-hover {
  background: #352e67 !important;
  color: #fff !important;
}

.ui-accordion-content-active {
  height: auto !important;
  display: block !important;
}

.accordian .ui-accordion-header {
  background: transparent;
  color: #352e67;
  text-transform: capitalize;
  padding: 15px 10px !important;
  font-weight: 400;
  width: 100%;
  text-align: left;
  border: 1px solid #c5c5c5;
  margin-bottom: 0px;
  font-size: 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  position: relative;
}
.accordian-wrap {
  width: 100%;
  float: left;
  margin-bottom: 3px;
}
.collapse.show .card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px;
}

.ui-widget-content {
  font-weight: 400;
}

.ui-accordion .ui-accordion-content {
  padding: 10px !important;
}

.faqContact {
  float: left;
  margin-top: 40px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 50px;
}

.faqContact span {
  color: #000;
  font-size: 16px;
}

.faqContact span a {
  color: #da145a;
}
.ui-accordion-header .fa-sort-down:before,
.ui-accordion-header .fa-sort-up:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
}
.ui-accordion-header .fas {
  float: right;
}

.search-container ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.search-container ::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.search-container :-ms-input-placeholder {
  /* IE 10+ */
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.search-container :-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.singleSliderDefualt img {
  height: 208px;
}
.singlePage-productSlider {
  width: 100%;
  float: left;
}
.productDescript_Wrapper h1 {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}
.singlePage-productSlider h2 {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #212433;
  margin-bottom: 0px;
}
.productDetails_content span.authorDesLink {
  cursor: pointer;
}
.purchasedLibraryWrap .genereNameLink a {
  color: #484848;
}
.genereNameLink a {
  color: #484848;
}
.authorDesLink {
  cursor: pointer;
}
.videoThumbTitleDiv .briefTitle a {
  color: #818183;
  text-decoration: none;
}
.authorDescription {
  cursor: pointer;
}
.snovelSignUpBtm .visitFaq {
  padding: 0px;
}
.snovelSignUpBtm .visitFaq.emailRight {
  text-align: right;
}

/*Giftsubscription */
.giftSubscriptionWrp {
  /* background: url("/../../images/bg_giftsusbcription.jpg") repeat; */
  float: left;
  width: 100%;
  background-position: center;
  background-size: cover;
  font-weight: 400;
  padding-bottom: 50px;
}
.giftSubscriptionForm {
  max-width: 500px;
  width: 100%;
  margin: 50px auto;
}
.giftSubscriptionForm form {
  background: #352e67;
  float: left;
  width: 100%;
  padding: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #fff;
}

.giftSubscriptionHeader {
  float: left;
  width: 100%;
}
.giftSubscriptionFrmLft {
  float: left;
  width: 48%;
  margin-bottom: 20px;
}
.giftSubscriptionFrmLft input {
  width: 220px;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.giftSubscriptionFrmLfRgt {
  float: right;
  width: 48%;
  margin-bottom: 20px;
}
.giftSubscriptionFrmLfRgt input {
  width: 220px;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.giftSubscriptionFrmFull {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.extra-bottom-space {
  margin-bottom: 30px;
}
.giftSubscriptionFrmFull textarea {
  width: 100%;
}
.giftSubscriptionFrmFull input {
  width: 100%;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.cart-modal .modal-header .close {
  display: none;
}
.giftSubscriptionGift .yourGiftCnt p {
  color: #fff;
}
.cart-modal .modal-header .close.closeReview {
  display: block;
}
.select-style {
  border: 1px solid #ccc;
  width: 120px;
  border-radius: 3px;
  overflow: hidden;
  background: #fafafa url("/../../images/select_down-arrow.png") no-repeat 95%
    50% !important;
}

select.select-style {
  padding: 5px 8px;
  width: 103%;
  border: none;
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  margin-bottom: 10px;
  margin-top: 0px;
}

select.select-style:focus {
  outline: none;
}

.giftSubscriptionFrmLftSubmit {
  float: left;
  width: 200px;
}
.giftSubscriptionFrmLftSubmit input {
  border: 2px solid #da145a;
  border-radius: 40px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: #da145a;
}

.giftSubscriptionFrmFull textarea {
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
  min-height: 140px;
  margin-top: -10px;
}

.giftSubscriptionFrmRgtSubmit {
  float: right;
  width: 200px;
}
.giftSubscriptionFrmRgtSubmit input {
  border: 2px solid #da145a;
  border-radius: 40px;
  padding: 12px 17px;
  color: #da145a;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: transparent;
}
.giftSubscriptionFrmRgtSubmit input:hover {
  color: #fff;
  background: #da145a;
}

.giftSubscriptionGift {
  max-width: 500px;
  width: 100%;
  margin: 50px auto;
  font-weight: 400;
}
.giftSubscriptionGift .ontheWay {
  background: #352e67;
  padding: 30px;
  float: left;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 50px;
}
.giftSubscriptionGift .giftSubscriptionHeader img {
  width: 178px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.yourGift {
  background: url("/../../images/brd-yougift.png") center no-repeat;
  background-position: bottom;
  text-align: center;
  color: #fff;
  font-size: 25px;
  padding-bottom: 20px;
}
.yourGiftCnt {
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  padding: 50px 0px;
}
.yourGiftProceed {
  width: 370px;
  margin: 20px auto 10px;
  display: table;
}
.yourGiftProceed a {
  border: 2px solid #da145a;
  border-radius: 50px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 370px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: #da145a;
  text-transform: uppercase;
}
.yourGiftCnt .paypmentTerms {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  float: none;
  width: 80%;
  margin: 0 auto;
}
.yourGiftCnt .paypmentTerms span.checkmark {
  border: 1px solid #fff;
}

.privacrPolicy h2 {
  margin-top: 20px;
  text-transform: inherit;
}
.privacrPolicy p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.yourGiftCancel {
  width: 370px;
  margin: 10px auto 20px;
  display: table;
}

.yourGiftCancel a {
  border: 2px solid #da145a;
  border-radius: 50px;
  padding: 12px 17px;
  color: #da145a;
  float: left;
  width: 370px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin: 20px 0px;
}
.yourGiftCancel a:hover {
  color: #fff;
  background: #da145a;
}
.giftSubscriptionHeader img {
  margin: 0px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.entry-content {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}
.entry-content h2,
.entry-content h3 {
  text-transform: inherit;
  margin-top: 20px;
}
.giftSubscriptionFrmFull select.select-style {
  width: 100%;
}
.giftSubscriptionWrp .form-control {
  margin-bottom: 0px;
}
.purchaseButton.gift-button {
  margin-left: 15px;
  display: inline-block;
}
.giftSubmit.giftSubmitWrap {
  background: #da145a;
  color: #fff;
  width: 40%;
  border-radius: 40px;
  padding: 10px;
}
.giftProduct-model .modal-header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
}
.giftProduct-model .modal-header {
  background: url("/../../images/application-banner.jpg") center no-repeat;
}
.giftProduct-model .form-control {
  margin-bottom: 0px;
}
.giftProduct-model #userEmail {
  width: 100%;
}
.giftProduct-model .modal-body {
  padding: 0px;
}
.giftProduct-model .giftSubscriptionForm {
  margin: 0px auto;
}
.modal-dialog.reviewProduct-model {
  max-width: 800px;
}
.reviewProduct-model .modal-header h5 {
  width: 100%;
}
.reviewProduct-model .modal-header {
  background-color: #dee2e6;
}
.reviewProduct-model .modal-title {
  text-transform: capitalize;
  text-align: left;
}
.reviewProduct-model .checkoutIndCnt h4 {
  text-transform: initial;
}
#sharefeedbackpopup .checkoutIndCnt h3 {
  font-size: 18px;
  padding-top: 10%;
}
#sharefeedbackpopup .checkoutIndCnt span {
  font-size: 15px;
}
#sharefeedbackpopup .modal-header {
  background: #e8e9ea;
  padding-left: 45px;
}

#sharefeedbackpopup .checkoutIndWrp {
  background: #e8e9ea;
}
#sharefeedbackpopup {
  font-weight: 400;
  color: #000;
}
#sharefeedbackpopup .starRating {
  width: 200px;
}
#sharefeedbackpopup .close {
  opacity: 1;
}
.shareFeedIcon {
  width: 27px;
  margin-right: 20px;
}
.starCntFeed {
  float: left;
  width: 30%;
  text-align: right;
  margin-bottom: 20px;
  padding-top: 0.5%;
  clear: both;
  font-size: 16px;
}

.starCntFeedRating {
  float: left;
  width: 68%;
  margin-left: 2%;
  margin-bottom: 20px;
}
.starCntFeedRating span.maxiRight {
  font-style: italic;
  font-size: 12px;
  float: right;
  margin-top: -20px;
}

#feedsuccessMess img {
  width: 93px;
}

#feedsuccessMess .modal-dialog {
  max-width: 800px;
  width: 100%;
}

#feedsuccessMess .welcomeBack {
  background: none;
  border: none;
  text-align: center;
  width: 70%;
  margin: 15% auto;
  float: none;
  padding-bottom: 15%;
}

#feedsuccessMess .liraryBtnFav a {
  padding: 12px 45px;
}

#feedsuccessMess .welcomeBack .access {
  margin-top: 20px;
}

#feedsuccessMess p strong {
  font-size: 18px;
}

#feedsuccessMess .modal-header {
  border-bottom: none;
}
.starCntFeedRating input[type="submit"],
.starCntFeedRating input[type="button"] {
  border-radius: 25px;
  padding: 12px 17px;
  color: #fff;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: #da145a;
  border: none;
}
#feedsuccessMess .modal-header {
  background-color: transparent;
}
.starCntFeedRating input[type="button"].cancelfeed {
  background: none;
  color: #000;
  border: none;
  margin-left: 10px;
}

.starCntFeedRating input[type="button"].cancelfeed:hover {
  border-radius: 25px;
  padding: 10px 17px;
  color: #000;
  float: left;
  width: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border: 2px solid #da145a;
  margin-left: 10px;
}

.startCntFeedWrp {
  width: 100%;
  float: none;
}

#sharefeedbackpopup .modal-content {
  width: inherit;
  height: inherit;
  margin: 0 auto;
}

#sharefeedbackpopup {
  text-align: center;
  padding: 0 !important;
}

#sharefeedbackpopup:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

#sharefeedbackpopup .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
#sharefeedbackpopup .checkoutIndImg {
  width: 30%;
}
#feedbackTextarea {
  margin-bottom: 10px;
}
.marginBtm {
  margin-bottom: 22px !important;
}
.myFeedbackTextarea {
  margin-bottom: 10px;
}
.myFeedbackTextarea textarea {
  margin-bottom: 0px;
}
#sharefeedbackpopup .checkoutIndCnt {
  width: 70%;
  float: left;
  font-size: 20px;
  padding-top: 6%;
}
#sharefeedbackpopup .modal-body {
  width: 100%;
  float: left;
}
.starCntFeedRating .form-control {
  margin-bottom: 0px;
}
#sharefeedbackpopup .loginErrorMsg {
  text-align: left;
}
.giftProduct-model .modal-header h5 {
  text-transform: capitalize;
  color: #fff;
}
.giftSubscriptionFrmLfRgt input {
  width: 100%;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.giftSubscriptionFrmFull textarea {
  width: 100%;
}
.giftSubscriptionFrmLft input {
  width: 100%;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.privacrPolicy .legal-txt-box {
  margin-bottom: 15px;
}
.privacrPolicy ul {
  list-style-type: none;
}
/* .singlePage-productSlider .owl-item .videoThumbDiv img {
      height: 208px;
    } */
.alert-modal .alertTopColImg img {
  width: 100%;
}
.singlePage-productSlider .authorSlider .owl-nav {
  position: relative;
}
.singlePage-productSlider .authorSlider .owl-nav .owl-prev {
  position: absolute;
  top: 0px;
  padding: 5px;
}
.singlePage-productSlider .authorSlider .owl-nav .owl-next:after,
.singlePage-productSlider .authorSlider .owl-nav .owl-prev:before {
  color: #fff;
  font-size: 24px;
}
.singlePage-productSlider .authorSlider .owl-nav .owl-next {
  position: absolute;
  top: 0px;
  right: 38px;
  padding: 5px;
}
.product-details-image-slider {
  height: 183px;
}
.tryFree-snovel-wrapper .videoThumbDiv img {
  height: auto;
}
.aboutMedia {
  float: left;
  width: 100%;
  border-top: 1px solid #b4b4b4;
  padding-top: 50px;
}
.aboutMedia h3 {
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 5px;
}
.aboutMedia span {
  margin-bottom: 20px;
}
.aboutMedia p {
  margin: 40px 0px;
}
.aboutMedia p strong {
  font-style: italic;
  color: #000;
}
.popularInner_Wrapper .genereNameLink a {
  color: #fff;
}
.orders-table__header.imageHeader {
  width: 20%;
}
.topBrowseImage img {
  height: 100%;
}
.mySnovelVideo.purchasedLibraryWrap .videoThumbDiv {
  width: 178px;
  float: left;
  margin-top: 32px;
}
.popularProduct_Slider .sliderThumb {
  height: 183px;
  cursor: pointer;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.owl-carousel .owl-item .sliderTrack_Wrap {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.owl-carousel .owl-item .sliderTrack_Wrap.custom-player-section {
  top: 61px;
}
.popularProduct_Slider .owl-nav {
  position: inherit;
  top: 30px;
}
.popularProduct_Slider .owl-carousel .owl-item .sliderTrack_Wrap {
  top: 33%;
}
.popularProduct_Slider .owl-nav .owl-prev,
.popularProduct_Slider .owl-nav .owl-next {
  position: absolute;
  padding: 5px;
}
.popularProduct_Slider .owl-nav .owl-prev::before,
.popularProduct_Slider .owl-nav .owl-next::after {
  font-size: 24px;
  color: #fff;
}
.popularProduct_Slider .owl-nav .owl-next {
  right: 38px;
}
.kids-main-wrapper .baner-section .owl-nav .owl-prev,
.kids-main-wrapper .baner-section .owl-nav .owl-next {
  background-color: transparent;
  height: auto;
  top: 0px;
}
.family-main-wrapper .baner-section .owl-nav .owl-prev,
.family-main-wrapper .baner-section .owl-nav .owl-next {
  background-color: transparent;
  height: auto;
  top: 0px;
}
.family-main-wrapper .baner-section .owl-nav .owl-next::after,
.family-main-wrapper .baner-section .owl-nav .owl-prev::before {
  font-size: 34px;
}
.family_category_view_all_button{
  position: relative !important;
  z-index: 999 !important;
  cursor: pointer;
  margin-bottom: 5px;
}
.innerSlider_Wrapper .owl-nav .owl-prev,
.familyInnerSlider_Wrapper .owl-nav .owl-prev,
.kids-main-wrapper .owl-nav .owl-prev {
  color: #ffffff;
  position: absolute;
  width: 30px;
  right: 0px;
  text-align: center;
  padding: 8px 0px;
  left: 0px;
  height: 183px;
  top: -52px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newfamilyInnerSlider_Wrapper .owl-nav .owl-prev{
  color: #ffffff;
  position: absolute;
  width: 30px;
  right: 0px;
  text-align: center;
  padding: 8px 0px;
  left: 0px;
  height: 183px;
  top: -52px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerSlider_Wrapper .owl-nav .owl-next,
.familyInnerSlider_Wrapper .owl-nav .owl-next,
.kids-main-wrapper .owl-nav .owl-next {
  color: #ffffff;
  float: right;
  width: 30px;
  position: absolute;
  right: 38px;
  text-align: center;
  border-radius: 50%;
  padding: 8px 14px;
  height: 183px;
  top: -52px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newfamilyInnerSlider_Wrapper .owl-nav .owl-next{
  color: #ffffff;
  float: right;
  width: 30px;
  position: absolute;
  right: 38px;
  text-align: center;
  border-radius: 50%;
  padding: 8px 14px;
  height: 183px;
  top: -52px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerSlider_Wrapper .owl-nav .owl-next::after,
.innerSlider_Wrapper .owl-nav .owl-prev::before {
  font-size: 22px;
}
.kids-main-wrapper .owl-nav .owl-next::after,
.kids-main-wrapper .owl-nav .owl-prev::before {
  font-size: 22px;
}
.familyInnerSlider_Wrapper .owl-nav .owl-next::after,
.familyInnerSlider_Wrapper .owl-nav .owl-prev::before {
  font-size: 22px;
}
.newfamilyInnerSlider_Wrapper .owl-nav .owl-next::after,
.newfamilyInnerSlider_Wrapper .owl-nav .owl-prev::before {
  font-size: 22px;
}
.innerSlider_Wrapper .owl-nav,
.familyInnerSlider_Wrapper .owl-nav,
.kids-main-wrapper .owl-nav {
  position: inherit;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 52px;
}
.newfamilyInnerSlider_Wrapper .owl-nav{
  position: inherit;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 52px;
}
.sliderContentWrapper {
  width: 100%;
  float: left;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.newSliderContentWrapper{
  width: 100%;
  float: left;
}
.signupWrapper .kep-login-facebook {
  text-align: left;
  padding: 5% 6% !important;
}
.custom-disable-field.form-control:disabled,
.custom-disable-field.form-control[readonly] {
  border: 1px solid #ced4da;
}
.loggedUserWrap {
  float: right;
  margin-right: 0px;
  width: 40%;
}
.try-free-snovel-nav {
  float: left;
  width: 60%;
  align-items: center;
  height: 50px;
}
.review-form-wrapper {
  width: 94%;
}
.subscription-expired {
  text-transform: capitalize;
  color: #212433;
}
.changePasswordWrap label {
  font-weight: 400;
}
.changePasswordBtn .fa-key {
  color: #000;
}
.popover .popover-inner {
  padding-bottom: 15px;
  width: 100%;
  float: left;
}
.popover-inner .threeDot .videoPrice {
  padding: 0px;
}
.sliderProductAuthor span,
.briefTitle span,
.browseSlideTxtTwo span {
  margin-right: 6px;
}
.sliderProductAuthor div,
.briefTitle div,
.browseSlideTxtTwo div {
  float: left;
}
.sliderProductAuthor div .nav-link,
.briefTitle div .nav-link,
.browseSlideTxtTwo div .nav-link {
  float: left;
  padding: 0px;
  color: #818183;
}
.browseSlideTxtTwo div .nav-link {
  color: #fff;
}
.sliderProductAuthor div .nav-link span,
.briefTitle div .nav-link span,
.browseSlideTxtTwo div .nav-link span {
  margin-right: 0px;
}
.popularComponant_Wrapper .videoCnt div {
  padding-left: 8px;
}
.browseLatest_Lauch .videoCnt div {
  display: inline;
  padding-left: 8px;
}
.popularComponant_Wrapper .videoCnt div a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.browseLatest_Lauch .videoCnt div a {
  padding: 0px !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.browseLatest_Lauch .videoCnt div a {
  color: #484848 !important;
  padding-right: 6px !important;
}
#myCookies .modal-dialog {
  max-width: 300px;
  width: 100%;
}

#myCookies .modal-content {
  background-color: #f9f9f9 !important;
}

#myCookies .leftCookies {
  float: left;
  width: 35px;
}
#myCookies {
  top: 30%;
}
#myCookies .rightCookies {
  float: left;
  width: 230px;
  color: #484848;
  padding-left: 10px;
}

#myCookies .rightCookies h3 {
  font-size: 16px;
  text-transform: capitalize;
  color: #484848;
  font-weight: 600;
}

#myCookies .rightCookies p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

#myCookies .rightCookies p a {
  color: #484848;
  text-decoration: underline;
}

#myCookies .close {
  float: left;
  background: #ed145b;
  font-size: 12px;
  color: #fff;
  opacity: 1;
  padding: 7px 10px;
  border-radius: 30px;
  font-weight: 400;
  text-shadow: none;
  font-weight: 400;
}
#myCookies .modal-header {
  border-bottom: 0px;
}
.product-description {
  width: 100%;
  float: left;
  height: 204px;
  overflow: hidden;
}
.product-description.large {
  height: auto;
}
.readMoreDesc {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 14px;
  margin-bottom: 20px;
}
.readMoreDesc span {
  color: #fff;
  font-size: 14px;
  background: #da145a;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
}
.alertTopDiv .owl-carousel {
  width: 96%;
  margin: 0 auto;
}
.alertTopDiv .mediaNewsTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.modal .news-notification-modal {
  max-width: 1000px;
}
.news-notification-modal img {
  width: 100%;
}
.news-notification-modal .modal-header {
  border-bottom: none;
}
.news-notification-modal .modal-header {
  display: initial;
}
.mobile-show-content {
  display: none;
}
.searchSuggetion_Wrapper .searchAuthor span {
  width: 100%;
  float: left;
  padding-bottom: 4px;
  cursor: pointer;
}
.mediaSection-wrapper .alertTopDiv:last-child {
  border-bottom: none;
}
.happy-user-comment {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.firstComment.one0 {
  border-left: 2px solid #da145a;
}
.firstComment.one1 {
  border-left: 2px solid rgb(20, 203, 201);
}
.firstComment.one2 {
  border-left: 2px solid rgb(53, 46, 103);
}
.firstComment.one3 {
  border-left: 2px solid rgb(113, 218, 20);
}
.firstComment.one4 {
  border-left: 2px solid rgb(27, 180, 237);
}
.firstComment.one5 {
  border-left: 2px solid rgb(238, 132, 37);
}
.sliderProductAuthor a {
  padding-right: 5px;
  border-right: 1px solid #818183;
  line-height: 16px;
  margin-right: 5px;
  color: #818183;
  padding-left: 2px;
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
}
.sliderProductAuthor a:hover {
  color: #818183;
}
.sliderProductAuthor a:last-child {
  border-right: none;
}
.sliderProductAuthor span {
  margin-right: 0px;
}
.sliderProductCategory a.nav-link {
  padding: 0px;
  display: inline;
  padding-right: 4px;
  margin-right: 4px;
  line-height: 14px;
  border-right: 1px solid #818183;
}
.selectedProductSlider {
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  margin: 0 auto;
  width: 24px;
  top: 3px;
}
.selectedProductSlider img {
  width: 30px;
  height: 30px;
}
.sliderProductCategory a:last-child {
  border-right: none;
}
.alertTopCol p span.notificationTitle {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 18px;
  cursor: pointer;
}
.browseLatest_Lauch .popularProduct_Slider .videoThumbDiv {
  margin-top: 0px;
}
.browseLatest_Lauch .owl-carousel .owl-item .selectedProductSlider img {
  width: 100%;
}
.popover-inner .videoCnt .loopdata {
  padding-left: 0px !important;
  float: left;
  width: 76%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popover-inner .threeDot .videoCnt .loopdata span.authorDes {
  margin-left: 0px;
}
.pagenot-found-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
.pagenot-found-wrapper .subsCribeButton {
  padding: 10px 40px;
}
.pagenot-found-wrapper h2 {
  font-size: 3rem;
}
.pagenot-found-wrapper h5 {
  text-transform: initial;
  width: 56%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.notification-model-wrapper .alertTopDiv {
  padding: 0px 24px;
}

.productDetailsMain_Wrapper .videoPurchase .purchaseButton.gift-button a {
  width: 160px;
}
.custom-lets-begin {
  margin-top: 0px;
  border-radius: 5px;
}
.custom-unlimited-annul {
  background-color: transparent;
  padding-top: 0px;
}
.authorSlider .owl-carousel .owl-item .sliderTrack_Wrap {
  top: 27%;
  left: -8px;
}
.legal-sub-hd h3 {
  font-size: 18px;
}
.maintanaceMode {
  background-color: #da145a;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.maintanaceMode h1 {
  color: #fff;
}
.audioPlayerProducts .audio-player-volume {
  display: none;
}
.audioPlayerProducts .audio-player #play-icon {
  height: 3rem !important;
}
.pricelinethrow {
  text-decoration: line-through;
  margin-right: 5px;
  font-size: 20px;
}
.loginErrorMsg.green {
  color: #2b6d04;
}
.snovelRunTime-wrapper {
  margin-top: 50px;
}
#bannerModel-wrapper {
  max-width: 600px;
}
#bannerModel-wrapper.modal-dialog {
  vertical-align: middle;
}
#bannerModel-wrapper .rightCookies img {
  width: 100%;
}
#bannerModel-wrapper .rightCookies img.banner-model-close {
  width: 20px;
  float: right;
  margin-bottom: 10px;
  cursor: pointer;
}
.allSubscriptionList .libraySubs {
  margin-left: 0%;
  margin-right: 1%;
  color: #000;
  margin-bottom: 20px;
}
.copyright-section {
  font-size: 11px;
  color: #818183;
}
#bannerModel-wrapper .rightCookies img {
  width: 100%;
}
#bannerModel-wrapper .rightCookies img.banner-model-close {
  width: 20px;
  float: right;
  margin-bottom: 10px;
  cursor: pointer;
}
.audition-wrapper .form-group {
  width: 48%;
  float: left;
  margin-right: 15px;
  position: relative;
  min-height: 96px;
  margin-bottom: 0px;
}
.fullwidth-label {
  width: 100%;
  float: left;
}
.radioSelection {
  padding-left: 20px;
}
.audition-wrapper .react-datepicker-wrapper {
  display: block;
}
.audition-wrapper {
  width: 100%;
  float: left;
  margin: 40px 0px 100px;
}
.audition-wrapper .loginErrorMsg {
  position: absolute;
  bottom: 1px;
  left: -5px;
}
.audition-wrapper .form-group .billCheckBx {
  margin-left: 0px;
}
.termandconstion-model {
  max-width: 60% !important;
}
.termandconstion-model .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.termandconstion-model ul {
  padding-left: 5px;
  list-style-type: none;
}
.audition-wrapper .emailSign-upBox .loginErrorMsg {
  position: absolute;
  bottom: -24px;
  left: -40px;
}
.audition-wrapper .react-datepicker__input-container {
  width: 100%;
}
.uploadsize {
  font-size: 12px;
  color: gray;
}
.text-termsandcondition {
  font-weight: bold;
  cursor: pointer;
}
.audition-wrapper .loginErrorMsg.uploadsizeError {
  bottom: -8px;
}
.form-group .billCheckBx #g-recaptcha {
  min-height: 110px;
}
.product-description p br {
  display: none;
}
.yourPrdNameCheckLft.left__wrapper {
  width: 30%;
  color: #ffffff;
  padding-top: 3px;
}
.yourPrdNameCheckRgt.right__wrapper {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.right__wrapper input[type="text"] {
  width: 53%;
}
.right__wrapper .applyCoupen {
  margin: 0px 0 0 10px;
}
.yourPrdNameCheckRgt.right__wrapper .cuponErrorMessage {
  width: 98%;
}
.episodsWrapper {
  width: 100%;
  float: left;
  max-height: 380px;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
}
.episod__wrap {
  width: 100%;
  float: left;
  margin-bottom: 12px;
}
.episod__wrap .episod__left {
  width: 25%;
  float: left;
}
.episod__wrap img.sampleProduct_thumb {
  width: 100%;
  object-fit: cover;
}
.episod__wrap .sliderThumb {
  height: 120px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  overflow: hidden;
}
.episod__wrap .episod__right {
  width: 75%;
  float: left;
  padding-left: 20px;
}
.episod__wrap .videoThumbDiv .sliderTrack_Wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  margin: 0;
}
.allSubscriptionList span.active_subscription {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 13px;
  background: #da145a;
  padding: 2px 19px;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
}
.first__track__player img {
  padding: 0 10px;
}
.purchaseButton {
  margin-bottom: 10px;
}
.aboutUs-videoWrap .video-react {
  padding-top: 40% !important;
}
.aboutUs-videoWrap .video-react.video-react-fluid {
  width: 100%;
  max-width: 70%;
  height: 0;
  margin: 0 auto;
}
.sliderThumb {
  height: 183px;
}
.owl-carousel .owl-item .sliderTrack_Wrap {
  bottom: 10px;
}
.error__wrapper {
  display: block;
  width: 100%;
  float: left;
}
.error__wrapper .loginErrorMsg {
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center;
}
/************   Kids Section  *********/

.kids-main-wrapper .baner-section img {
  max-width: 100%;
  margin: 0px;
}
.kids-main-wrapper .baner-section .owl-stage-outer {
  margin-bottom: 40px;
}
.kids-main-wrapper .baner-section .owl-stage-outer {
  margin-bottom: 20px;
}
.desktopMenu .navbar-nav li.active a {
  color: #da145a;
}
.category-section {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.kids-category-slider {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.sliderThumb {
  position: relative;
}
span.freeProduct {
  position: absolute;
  bottom: 0px;
  background-color: #da145a;
  color: #fff;
  font-size: 14px;
  padding: 2px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.popularImage_Wrapper span.freeProduct {
  bottom: 48px;
}
.kidsCategorySliders .owl-carousel {
  margin-bottom: 15px;
  float: left;
}
.auditionformModal h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  text-transform: initial;
  margin-bottom: 20px;
}
#myCookies .auditionformModal .close {
  float: left;
  background: #ed145b;
  font-size: 14px;
  color: #fff;
  opacity: 1;
  padding: 9px 24px;
  border-radius: 30px;
  font-weight: 400;
  text-shadow: none;
  font-weight: 400;
}
.modal-dialog.modal-dialog-centered .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.modal-dialog.modal-dialog-centered .modal-header .modal-body {
  padding-bottom: 40px;
}
.kidsCategoryMain-wrapper button {
  float: right;
  border: none;
  outline: none;
  border: 2px solid #da145a;
  border-radius: 20px;
  color: #da145a;
  padding: 2.5px 15px;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
}
.float-left {
  float: left;
}
button.view-all-button {
  margin-top: -15px;
  margin-right: -15px;
}
.category-view-all-button {
  margin-top: -7.5px;
}
.custom-category-title {
  font-size: 18px !important;
  text-transform: capitalize;
  font-weight: bold;
}
.kidsCategoryMain-wrapper {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.kidsCat-wrapper .owl-item {
  position: relative;
  padding: 0px 10px;
  margin-bottom: 20px;
}
.kidsCat-wrapper .owl-item .sliderTrack_Wrap {
  position: absolute;
    bottom: 10px;
    float: right;
    right: 20px;
    margin: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.kidsCat-wrapper .owl-item .sliderTrack_Wrap_prod_details{
  position: absolute;
  top: 22%;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.kidsCat-wrapper {
  margin-top: 20px;
}
.kidsCat-wrapper .owl-item img {
  width: 100%;
  height: 100%;
  margin: 0px;
}
.mainCategory-Name {
  font-size: 30px;
  letter-spacing: 0.05px;
  color: #212433;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 20px;
}
.excludeGst {
  height: 27px;
}
.price-through {
  position: relative;
}
.price-through::after {
  content: "";
  width: 95%;
  height: 2px;
  background: #db235a;
  position: absolute;
  left: 0;
  top: 10px;
}
.browse-space {
  margin-bottom: 20px;
}
.newUserOffers {
  text-transform: initial;
  font-size: 16px;
  font-weight: bold;
}
.kidsHomePage-Banner {
  position: relative;
}
.ebook-wrapper {
  position: absolute;
  margin-top: 15.3%;
  left: 25%;
  z-index: 1;
}
.ebook-wrapper .ebook-button {
  border: 2px solid #da145a;
  border-radius: 20px;
  color: #da145a;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  background-color: #fff;
}
.ebook-wrapper a:hover {
  background-color: #da145a;
  color: #fff;
  border: 2px solid #da145a;
}
.custom-signup-section {
  margin-bottom: 60px;
  padding-top: 20px;
}
.custom-signup-section div {
  border-right: 2px dotted #352e67;
  color: #353e67;
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.custom-signup-section div:last-child {
  border-right: none;
}
.custom-signup-section .vertical-center-section {
  padding-top: 22.5px;
  padding-bottom: 22.5px;
}
.register-now-try-free-snovel-outer {
  line-height: 80px;
  margin-top: 20px;
}
.register-now-try-free-snovel-outer span {
  border: 2px solid #353e67;
  padding: 13px 60px 13px 60px;
  border-radius: 50px;
  background: #353e67;
  color: #ffffff !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  text-decoration: none !important;
}
.sign-up-first-section {
  margin: 40px 0px;
}
.sign-up-heading-text {
  color: #353e67;
  margin-bottom: 15px;
  text-transform: none;
}
.sign-up-sub-heading-text {
  color: #353e67;
  text-transform: none;
  margin-top: 30px;
}
.snovel-app-subscription-section {
  margin-top: 45px;
  margin-bottom: 60px;
}
.new-sign-up-navbar-brand.navbar-brand {
  margin-left: auto;
  margin-right: auto !important;
  text-align: center;
}
.giftFromOuter,
.giftToOuter {
  background-color: #ffffff;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -30px;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  font-weight: bold;
  clear: both;
}
.custom-breadcrumb-outer .nav-link {
  padding: 0px;
  color: #da145a;
}
.custom-breadcrumb-outer .breadcrumb {
  background-color: transparent;
  margin: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-left: -5px;
  padding-top: 20px;
}
.nav-item .nav-link.subscribe-nav-link {
  background-color: #da145a;
  color: #ffffff !important;
  padding: 2.5px 10px !important;
  border-radius: 15px;
  padding-top: 2px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.language-lab-outer .categoryBannerPnl img {
  width: 100%;
  height: a;
}

.contentMainWrap {
  padding: 20px 0;
}

.language-lab-outer .categoryCommonTitle {
  font-size: 24px;
  font-weight: 700;
  color: #212433;
  text-transform: none;
}

.contentMainWrap p {
  font-size: 16px;
}

.language-lab-outer .purpleStrip {
  background-color: #342b62;
  color: #fff;
  font-size: 22px;
  text-align: center;
  padding: 13px 0 18px;
  margin: 35px 0;
}

.language-lab-outer .catTabsPnl h3 {
  color: #352e67;
  font-size: 24px;
  font-weight: 700;
  text-transform: none;
}

.language-lab-outer .catLinks {
  margin: 31px 0 0 0;
  padding: 0;
  width: 166px;
}

.language-lab-outer .catLinks li {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
}

.language-lab-outer .catLinks li a {
  margin: 0;
  padding: 0;
  color: #212433;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 1px solid #d2d1dd;
  display: block;
  padding: 6.5px 0 8.5px 11px;
  line-height: 16px;
}

.language-lab-outer .catLinks li a:hover {
  text-decoration: none;
  color: #da145a;
  background: #f1f1f1;
}

.language-lab-outer .catLinks li a.active {
  background: #f1f1f1;
  color: #da145a;
}

.language-lab-outer .catContentPnl h3 {
  color: #212433;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #d2d2d2;
  padding: 0 0 10px 0;
  margin-bottom: 23px;
}

.language-lab-outer .nav.nav-tabs {
  display: block;
}
.language-lab-outer .nav-tabs .nav-link {
  border-bottom: 1px solid #d2d1dd;
  border-radius: 0;
}

.language-lab-outer .nav-tabs .nav-link.active {
  color: #da145a;
  background-color: #f1f1f1 !important;
  border-bottom: 1px solid #da145a !important;
}

/* .language-lab-outer .nav-tabs .nav-link {

} */

.language-lab-outer .nav-tabs .nav-item {
  margin-bottom: 0;
}
.language-lab-outer .tab-content > .active {
  display: block;
}
.language-lab-outer .nav.nav-tabs {
  display: block;
  border-bottom: 0;
  border-right: 1px solid transparent;
}

.language-lab-outer .thmbWrp {
  width: 195px;
  float: left;
  position: relative;
}

.language-lab-outer .tabrgtContent {
  max-width: 573px;
  width: 100%;
  float: left;
  padding: 0 0 0 19px;
}

.language-lab-outer .clr {
  clear: both;
}

.language-lab-outer .tabContentWrp,
.audio-course-product-outer .tabContentWrp {
  margin: 0 0 25px 0;
}

.language-lab-outer .tabrgtContent h4 {
  font-size: 24px;
  color: #212433;
  font-weight: 500;
  line-height: 21px;
  text-transform: none;
}

.language-lab-outer .tabrgtContent h4 a {
  color: #212433;
  text-decoration: none;
}

.language-lab-outer .timeCounterPnl,
.audio-course-product-outer .timeCounterPnl {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  margin: 11px 0 15px 0;
}

.language-lab-outer .timeCounterPnl img,
.audio-course-product-outer .timeCounterPnl img {
  position: relative;
  top: -1px;
}

.language-lab-outer .tabrgtContent p {
  color: #212433;
  font-size: 16px;
  font-weight: 400;
}

.language-lab-outer .pipe,
.audio-course-product-outer .pipe {
  display: inline-block;
  margin: 0 5px 0 4px;
}

.language-lab-outer .readMoreLink {
  margin-top: 30px;
  margin-bottom: 0;
}

.language-lab-outer .readMoreLink span {
  color: #da145a;
  text-decoration: none;
}

.language-lab-outer .readMoreLink span:hover {
  color: #212433;
  text-decoration: none;
}

.language-lab-outer .btnPlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.language-lab-outer .btnPlay img {
  width: 32px;
}

#myCookies button.close.custom-close-button {
  position: absolute;
  top: 0px;
  right: 5px;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background-color: transparent;
  padding: 0px;
}
.language-lab-outer .imgThmb {
  width: 192px;
  margin: 0px;
}
/* Category details page style starts here */

.audio-course-product-outer .breadCrumbs {
  margin: 20px 0 35px 0;
  padding: 0 0 13px 0;
  color: #212433;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #d2d2d2;
  text-transform: none;
}

.audio-course-product-outer .breadCrumbs a {
  color: #da145a;
  text-decoration: none;
  display: inline;
  padding: 0;
}

.audio-course-product-outer .breadCrumbs a:hover {
  color: #000;
}

.institute-name {
  line-height: 30px;
}

.audio-course-product-outer .contentRgtCatDtls {
  max-width: 450px;
  padding: 0 0 0 0px;
  position: relative;
}

.audio-course-product-outer .contentRgtCatDtls p {
  font-size: 15px;
}

.audio-course-product-outer .authorInfo {
  margin-top: 28px;
}

.audio-course-product-outer .authorInfo p {
  margin: 0 0 3px 0;
  padding: 0;
  font-size: 14px;
  color: #212433;
  font-weight: 400;
}

.audio-course-product-outer .authorInfo p strong {
  width: 50px;
  display: inline-block;
}

.audio-course-product-outer .starWrpr {
  border-bottom: 1px solid #656565;
  border-top: 1px solid #656565;
  position: relative;
  margin: 20px 0 55px;
}

.audio-course-product-outer .starRatePnl {
  color: #656565;
  margin: 12px 0 12px 0;
}

.audio-course-product-outer .starRatePnl .checked {
  color: #f3a111;
}

.audio-course-product-outer .starRatePnl span {
  cursor: pointer;
  margin-right: 5px;
}

.audio-course-product-outer .starRatePnl .star-ratings .star-container {
  vertical-align: top !important;
}

.audio-course-product-outer .shareIcn {
  display: inline-block;
  position: absolute;
  right: 0;
  margin: 0px 0 0 0;
  z-index: 100;
  top: 10px;
}

.audio-course-product-outer .btnPnl {
  margin: 0 0 0 0;
}

.audio-course-product-outer .btnPnl a {
  margin: 0 10px 10px 0;
  background-color: #fff;
  display: inline-block;
  /* width: 142px; */
  padding: 8px 28px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #da145a;
  color: #da145a;
}

.audio-course-product-outer .btnPnl a:hover {
  background-color: #da145a;
  border: 2px solid #da145a;
  color: #fff;
}

.audio-course-product-outer .btnPnl a.active {
  background-color: #da145a;
  border: 2px solid #da145a;
  color: #fff;
}

/* Progressbar style starts here */
.audio-course-product-outer .CircularProgressbar {
  width: 45px;
  margin: 6px 6px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
}

.audio-course-product-outer .CircularProgressbar .CircularProgressbar-text {
  font-weight: bolder;
  fill: #212433;
  font-size: 30px;
}

.audio-course-product-outer .CircularProgressbar .CircularProgressbar-path {
  stroke: #da145a;
}

.audio-course-product-outer .circle {
  margin: 6px 6px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
}

.audio-course-product-outer .circle canvas {
  vertical-align: top;
  width: 45px !important;
}

.audio-course-product-outer .circle strong {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  color: 212433;
}

.audio-course-product-outer .circle strong i {
  font-style: normal;
  font-size: 0.6em;
  font-weight: normal;
}

.audio-course-product-outer .circle span {
  color: #212433;
  position: absolute;
  font-size: 14px;
  margin-top: 0;
  margin-left: 0;
  left: 0;
}

/* .audio-course-product-outer .progressbar {
  position: absolute;
  right: -110px;
} */

/* Progressbar style ends here */

.audio-course-product-outer .titleLessions {
  color: #212433;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 10px;
}

.audio-course-product-outer .playList {
  margin: 0 0 18px 0;
  padding: 0;
  border: 1px solid #bcbdc1;
  border-bottom: 0;
}

.audio-course-product-outer .playList ul {
  margin: 0;
  padding: 0;
}

.audio-course-product-outer .playList ul li {
  margin: 0;
  padding: 15px 0 15px 80px;
  list-style: none;
  position: relative;
  border-bottom: 1px solid #bcbdc1;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.audio-course-product-outer .playList ul li:hover {
  background: #f6f6f6;
}

.audio-course-product-outer .playList ul li.active {
  background-color: #f8dbe5;
}

/* .audio-course-product-outer .playList ul li.active .timeDetails {
  color: #f6f6f6;
} */

.audio-course-product-outer .plyPause {
  position: absolute;
  left: 28px;
}

.audio-course-product-outer .timeDetails {
  font-size: 16px;
  color: #212433;
  font-weight: 400;
  right: 20px;
  position: absolute;
}

.audio-course-product-outer .downloadCertLnk {
  font-size: 16px;
  color: #da145a;
  text-decoration: none !important;
  position: absolute;
  right: 14px;
}

.audio-course-product-outer .downloadCertLnk:hover {
  color: #212433;
}

.audio-course-product-outer .lastExpPnl {
  background: #f2f2f2;
  padding: 25px 0 50px;
  margin: 25px 0 0;
}

.audio-course-product-outer .lastExpPnl h3 {
  color: #212433;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}

.audio-course-product-outer .lastExpPnl img {
  width: 100%;
  height: auto;
  margin: 0px;
}

.audio-course-product-outer .playBlk {
  width: 100%;
  margin: 0px;
}

.audio-course-product-outer .reviewSection {
  margin: 50px 0 0;
}

.audio-course-product-outer .reviewBlock {
  background-color: #212433;
  height: 170px;
  margin-left: -15px;
  margin-right: -15px;
}

.audio-course-product-outer .reviewBlock .reviewCount {
  display: inline-block;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  padding-top: 4px;
  margin: 45px 0 0 75px;
}

.audio-course-product-outer .reviewBlock h4 {
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin-top: 8px;
}

#reviewCarousel.owl-carousel .owl-nav .owl-next::after,
#reviewCarousel.owl-carousel .owl-nav .owl-prev::before {
  content: none;
}

#reviewCarousel.owl-carousel .owl-nav .owl-next {
  background: url("/../../images/arrow-rgt.png") no-repeat !important;
  width: 24px;
  height: 30px;
  font-size: 0px !important;
  position: absolute;
  right: -50px;
  top: 70px;
}

#reviewCarousel.owl-carousel .owl-nav .owl-prev {
  background: url("/../../images/arrow-lft.png") no-repeat !important;
  width: 24px;
  height: 30px;
  font-size: 0px !important;
  position: absolute;
  left: -50px;
  top: 70px;
}

.audio-course-product-outer .reviewTxt {
  padding: 35px 0px 0;
}

.audio-course-product-outer .owl-carousel {
  padding: 0 0px;
}

.audio-course-product-outer .carouselWrpr {
  max-width: 750px;
  margin: 0 auto;
}

.audio-course-product-outer .owl-theme .owl-nav {
  margin-top: 0 !important;
  position: absolute;
  width: 100%;
  top: 43%;
}

.audio-course-product-outer .reviewDte {
  font-size: 12px;
  color: #da145a;
}

.audio-course-product-outer .reviewTxt p {
  margin-bottom: 3px;
}

.audio-course-product-outer .reviewsMobile {
  display: none;
}

.no-transform-text {
  text-transform: none !important;
}

.review-carousel-outer {
  background-color: #f3f3f3;
}

/* Category details page style ends here */
.audio-course-add-review {
  padding-top: 5px;
}

/* audioCourseProdut pop up btn */

.subscribe_btn {
  background-color: #ddd;
  border: "2px solid #da145a";
  color: black;
  padding: 9px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}

.exerciseText {
  margin-top: 10px;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.subscribe_btn_excersise {
  color: #da145a;
  padding: 13px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 40px;
  border: 2px solid #da145a;
  background-color: white;
}

.subscribe_btn:hover {
  background-color: #da145a;
  color: white;
}

.btnPnl .active {
  cursor: pointer;
}

.inner_img {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* .subscribe_btn_audio {
  position: relative;
  left: 94%;
  border: none;
  outline: none;
  border: 2px solid #da145a;
  border-radius: 20px;

  padding: 2.5px 15px;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
} */

.text-audio {
  text-decoration-line: none !important;
  color: #da145a !important;
}
/* @media (max-width: 767px) {
  .inner_img {
    flex-direction: column;
    row-gap: 15px;
  }
  .hidden-sm {
    display: none !important;
  }
  .third-slide {
    height: auto !important;
  }
  .inner_img .col-md-6 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
} */

.footer-banner {
  background-image: url("/images/snovel web home 1-07.png");
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
  padding: 20px 15px;
  background-repeat: no-repeat;
  margin: 0 auto !important;
}

.footer-banner h3 {
  color: #fff;
  position: absolute;
  top: 18%;
  left: 19%;
  text-align: center;
  text-transform: initial;
}

.collabrate-wrapper {
  font-size: 20px;
}

.footer-banner h5 {
  position: absolute;
  top: 82%;
  left: 20%;

  font-size: 20px;
  color: #fff;
  text-align: center;
  text-transform: initial;
}

.collabrate-inner-text {
  position: absolute;
  top: 45%;
  left: 87px;
}

.collabrate-inner-text h6 {
  font-size: 18px !important;
  text-transform: capitalize;
}

h6 {
  font-size: 15px;
}

.stellar-banner {
  background-image: url("/images/snovel web home without text-06.png");
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
  padding: 20px 15px;
  background-repeat: no-repeat;
  margin: 0 !important;
}

.stellar-text {
  position: absolute;
  left: 65%;
  color: white;
}

.stellar-cotation {
  margin-top: 60px;
  color: wheat;
  text-transform: initial;
}

.stellar-cotation1 {
  margin-top: 20px;
  color: white;
  text-transform: initial;
}

.audio-banner-wrapper {
  width: 100%;
  display: flex;
  margin: 0 !important;
  align-items: center;
}

.audio-banner {
  background-image: url("/images/snovel web home without text-02.png");
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center center;
  /* padding: 20px 15px; */
  background-repeat: no-repeat;
}

.audio-img {
  font-size: 18px !important;
  text-transform: capitalize;
  text-align: center;
  margin-top: 52%;
  color: #484848;
  text-decoration: none;
}

.audio-img-course {
  font-size: 18px !important;
  text-transform: capitalize;
  text-decoration-line: none !important;
  color: #484848;
  text-align: center;
  margin-top: 52.6%;
}

.audio-banner2 {
  background-image: url("/images/snovel web home without text-03.png");
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center center;
  /* padding: 20px 15px; */
  background-repeat: no-repeat;
}

.audio-banner3 {
  background-image: url("/images/snovel web home without text-04.png");
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center center;
  /* padding: 20px 15px; */
  background-repeat: no-repeat;
}

.audio-banner4 {
  background-image: url("/images/snovel web home without text-05.png");
  width: 100%;
  height: 200px;
  background-size: contain;
  background-position: center center;
  /* padding: 20px 15px; */
  background-repeat: no-repeat;
}

.audio-img1 {
  font-size: 18px !important;
  text-transform: capitalize;
  text-align: center;
  margin-top: 52%;
  color: #484848;
  text-decoration: none;
}

.audio-img1:hover {
  color: #484848;
}

/* .snovel-subscription-img {
  width: 100% !important ;
  height: 501px;
} */

.snovel-enhacment {
  background-image: url("/images/snovel web home 1-09-09.png");
  width: 100% !important ;
  height: 501px;
  position: relative;
  background-size: contain;
  background-position: center center;
  margin: 0 auto !important;
  background-repeat: no-repeat;
}

.snovel-text-wrapper {
  position: absolute;
  left: 43%;
}

.snovel-text2-wrapper {
  position: absolute;
  left: 44%;
}

.snovel-text-value {
  margin-top: 120px;
  color: white;
}

.snovel-value {
  position: absolute;
  left: 2;
  left: 45%;
  top: 25%;
  font-size: 32px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-value1 {
  position: absolute;

  left: 38%;
  top: 34%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-text1-wrapper {
  position: absolute;
  left: 37%;
}

.snovel-text2-wrapper {
  position: absolute;
  left: 43%;
}

.snovel-value-ready {
  position: absolute;

  left: 44%;
  top: 43%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-text3-wrapper {
  position: absolute;
  left: 38%;
}

.snovel-value-ready1 {
  position: absolute;

  left: 38%;
  top: 49%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-ready {
  position: absolute;
  left: 46%;
  top: 43%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-ready1 {
  position: absolute;
  left: 40%;
  top: 49%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-ready2 {
  position: absolute;
  left: 43%;
  top: 55%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-text4-wrapper {
  position: absolute;
  left: 41%;
}

.snovel-value-ready2 {
  position: absolute;

  left: 41%;
  top: 55%;
  font-size: 18px !important;
  text-transform: capitalize;
  color: white;
}

.snovel-wrapper-btn {
  display: flex;
}

.snovel-btn1 {
  left: 33%;
  top: 74%;
  color: white !important;
  position: absolute;

  text-decoration-line: none !important;
  font-size: 23px;
}

.snovel-btn2 {
  position: absolute;

  left: 55.2%;
  top: 74%;

  text-decoration-line: none !important;
  color: darkmagenta !important;
  font-size: 23px;
}

/* .view-all-button{
  display:none;
} */

.subscribe_btn-audio {
  color: black !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 40px;
  position: absolute;
  left: 20%;
  bottom: 10px;
  /* margin-top: 255px; */
  background: #da145a;
  text-decoration-line: none !important;
}

.sliderTrack1_Wrap {
  position: absolute;
  /* margin-left: 129px; */
  padding: 0;
  /* float: right; */
  /* left: 26%;
  top: 126%; */
  bottom: 10px;
  left: 135px;
}

.owl-stage-outer {
  margin-bottom: 20px;
}

/* .authorSlider .owl-carousel .owl-item .sliderTrack_Wrap {
  top: 69%;
  left: 127px;
} */

.audio_story_img {
  width: 100%;
  height: 150px;
}

.snovel-btn1-marathi {
  left: 36%;
  top: 74%;
  color: white !important;
  position: absolute;
  text-decoration-line: none !important;
  font-size: 23px;
}

.snovel-btn2-marathi {
  position: absolute;
  left: 56.2%;
  top: 74%;
  text-decoration-line: none !important;
  color: darkmagenta !important;
  font-size: 23px;
}

.langauge-lab-img {
  width: 100%;
  margin: 0 auto;
  /* height: 350px; */
}

.home-collbrate-img {
  width: 100%;
  height: 300px;
}

.home-stellar-img {
  width: 100%;
  height: 300px;
}

.home-stellar-kids-img {
  width: 100%;
  height: 450px;
  margin-top: 20px;
  margin: 0 auto;
  background-color: #111;
}

/* .homepage-design {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.home-education-img {
  width: 100%;
  height: 350px;
}

/* .home-education-img1 {
  width: 100%;
  height: 350px;
} */

.audio-courses-main-tag {
  display: flex;
  margin-top: 20px;
}

.stellar-text-wrapper-content {
  margin-top: 20px;
}

.stellar-text-wrapper-content1 {
  margin-top: 20px;
}

.audio-story-media {
  width: 287.72px;
  height: 200px;
}

.column {
  float: left;
  width: 25%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.audio-courses-main-tag .row {
  margin: 0px;
}

.circularProgress-language-two {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 20%;
  top: -100%;
}

.subscribe_btn_excersise_lab11 {
  position: absolute;
  left: 29%;
  top: -30%;
}

.subscribe_btn_excersise_lab {
  position: absolute;
  left: 93%;
  top: -91%;
  cursor: pointer;
  background-color: white;

  /* color: #da145a;
  padding: 13px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 40px;
  border: 2px solid #da145a;
  background-color: white; */
}

.dowmload-cert {
  width: 50px;
  height: 50px;
}

/* 
.row{
  flex-wrap: nowrap;
} */

.stellar-text-wrapper-content {
  padding: 0px !important;
}

.stellar-text-wrapper-content {
  padding: 0px;
}

/* .stellar-text-wrapper-content1{
  padding: 0px !important;
} */

/* .audio-course-product-outer .progressbar{
  position: absolute;
  left: 102%;
} */

.stellar-wrapper-footer {
  position: relative;
  padding: 0px !important;
}

.audio-course-product-outer .circle {
  margin: 6px 6px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  left: 100%;
}
.audio-course-product-outer .CircularProgressbar {
  width: 45px;
  margin: 6px 6px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  left: 100%;
}

.homepage-audio-wrapper {
  position: relative;
}

.gift-stellar-img {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  background-color: #111;
}

.stellar-subscribe-img {
  width: 100%;
  height: 450px;
  margin: 0 auto;
}

.popularProduct_Slider .owl-carousel .owl-item .sliderTrack_Wrap {
  top: 81%;
  /* top: 3%; */
  left: 78%;
}

.owl-carousel .owl-item .sliderTrack_Wrap {
  position: absolute;
  /* bottom: 10px; */
  /* float: right; */
  /* right: 10px; */
  margin: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.owl-carousel .owl-item .new_sliderTrack_Wrap{
    position: absolute;
    bottom: 10px;
    float: right;
    right: 10px;
    margin: 0;
    width: 34px;
    height: 34px;
    cursor: pointer;
}

/* . stellar-wrapper-footer{
  padding: 0px;
} */

.library-text {
  text-align: left !important;
  font-weight: 400;
  font-size: 24px !important;
  text-transform: initial !important;
  color: #000;
}

.library-text1 {
  text-align: left !important;
  font-weight: 400;
  font-size: 28px !important;
  text-transform: initial !important;
  color: #000;
}

@media (min-width: 1941px) and (max-width: 2560px) {
  .contentMainWrap p {
    font-size: 17px;
    font-weight: bold;
  }

  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 27%;
    top: -30%;
  }
}

@media (min-width: 1027px) and (max-width: 1440px) {
  .subscribe_btn-audio {
    color: black !important;
    padding: 5px 23px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    left: 26%;
    margin-top: 269px;
    background: #da145a;
    text-decoration-line: none !important;
    font-size: 20px;
  }
  .sliderTrack1_Wrap {
    position: absolute;
    /* margin-left: 129px; */
    padding: 0;
    /* float: right; */
    /* left: 18%;
    top: 126%; */
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 27%;
    top: -30%;
  }
  .contentMainWrap p {
    font-size: 17px;
  }
  /* .audio-course-product-outer .progressbar{
    position: absolute;
    left: 102%;
  } */
}

.audio-course-carousel {
  position: relative;
}

.subscribe_btn_audio {
  position: absolute;
  bottom: -5px;
  right: 0;
  border: none;
  outline: none;
  border: 2px solid #da145a;
  border-radius: 20px;
  padding: 2.5px 15px;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
}

.home_btn_audio {
  position: absolute;
  bottom: 4px;
  right: 0;
  border: none;
  outline: none;
  border: 2px solid #da145a;
  border-radius: 20px;
  padding: 2.5px 15px;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
}

.button-tag {
  margin-left: "5px";
  border: "2px solid #da145a";
  padding: "10px 20px";
  border-radius: "30px";
  background-color: "#da145a";
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .audio-img {
    margin-top: 60%;
  }
  .audio-img1 {
    margin-top: 60%;
  }
  .collabrate-inner-text h6 {
    font-size: 14px !important;
  }
  .snovel-btn2 {
    left: 54.2%;
    top: 69%;
  }
  .snovel-btn1 {
    left: 31%;
    top: 69%;
  }
  .stellar-subscribe-img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .subscribe_btn-audio {
    color: black !important;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    left: 20%;
    margin-top: 22%;
    background: #da145a;
    text-decoration-line: none !important;
  }
  .home-stellar-kids-img {
    height: auto;
    margin-top: 20px;
  }
  .sliderTrack1_Wrap {
    position: absolute;
    /* margin-left: 129px; */
    padding: 0;
    /* float: right; */
    /* left: 17%;
    top: 137%; */
  }
  .android-app-btn {
    width: 280px;
    float: left !important;
    border: 2px solid #a4c639;
    border-radius: 50px;
    height: 64px;
    display: flex;
    align-items: center;
  }
  .subscribe_btn_audio {
    position: absolute;
    bottom: -4px;
    /* left: 90%; */
    border: none;
    outline: none;
    border: 2px solid #da145a;
    border-radius: 20px;
    padding: 2.5px 15px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }
  .audio-story-media {
    width: 230px;
  }
  .row {
    flex-wrap: nowrap !important;
  }

  .subscribePack_Wrapper .row {
    flex-wrap: wrap !important;
  }
  /* .audio-course-product-outer .progressbar{
    position: absolute;
    left: 74%;
  } */
  .audio-course-product-outer .circle {
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 68%;
  }
  .audio-course-product-outer .CircularProgressbar {
    width: 45px;
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 68%;
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 31%;
    top: -50%;
  }
}

@media (min-width: 965px) and (max-width: 1023px) {
  .audio-img {
    margin-top: 64%;
  }
  .audio-img1 {
    margin-top: 61%;
  }
  /* .sliderTrack1_Wrap {
    left: 18%;
    top: 183%;
  } */
  .collabrate-inner-text h6 {
    font-size: 12px !important;
  }
  .snovel-btn2 {
    left: 54.3%;
    top: 69%;
  }
  .snovel-btn1 {
    left: 31%;
    top: 69%;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .unlimiTedAnnul {
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 964px) {
  .audio-banner-wrapper {
    column-gap: 10px;
  }
  .home-stellar-img {
    height: auto !important;
  }
  .gift-stellar-img {
    height: auto !important;
  }
  .audio-img {
    margin-top: 74%;
    font-size: 14px !important;
  }
  .audio-img1 {
    margin-top: 72.5%;
    font-size: 12px !important;
  }
  .footer-banner h3 {
    font-size: 22px !important;
    left: 22%;
    top: 19%;
  }
  .footer-banner h5 {
    font-size: 14px !important;
    top: 86%;
    left: 22%;
  }
  .collabrate-inner-text h6 {
    font-size: 12px !important;
  }
  .stellar-cotation1 {
    font-size: 15px !important;
  }
  .stellar-cotation {
    font-size: 12px !important;
  }
  .snovel-value {
    font-size: 25px !important;
    left: 45%;
    top: 33%;
  }
  .snovel-value1 {
    font-size: 14px !important;
    left: 36%;

    top: 38.5%;
  }
  .snovel-value-ready {
    font-size: 14px !important;
    left: 44%;
    top: 43%;
  }
  .snovel-value-ready1 {
    font-size: 14px !important;
  }
  .snovel-value-ready2 {
    font-size: 14px !important;
  }
  .snovel-btn1 {
    font-size: 16px !important;
    left: 32%;
    top: 65.2%;
  }
  .snovel-btn2 {
    font-size: 16px !important;
    left: 55.2%;
    top: 65.2%;
  }
  /* .sliderTrack1_Wrap {
    top: 126%;
    left: 20%;
} */
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  .stellar-subscribe-img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .subscribe_btn-audio {
    color: black !important;
    padding: 6px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    left: 20%;
    background: #da145a;
    text-decoration-line: none !important;
    font-size: 12px;
  }
  .home-stellar-kids-img {
    height: auto;
    margin-top: 20px;
  }
  .subscribe_btn_audio {
    position: absolute;
    bottom: -2px;
    /* left: 87.4%; */
    border: none;
    outline: none;
    border: 2px solid #da145a;
    border-radius: 20px;
    padding: 2.5px 15px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }
  .sliderTrack1_Wrap {
    position: absolute;
    /* margin-left: 129px; */
    padding: 0;
    /* float: right; */
    bottom: 54px;
    left: 135px;
  }
  .audio-story-media {
    width: 172px;
    height: 120px;
  }
  .contentMainWrap p {
    font-size: 14px;
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 35%;
    top: -58%;
  }
  .circularProgress-language-two {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 22%;
    top: -100%;
  }

  .language-lab-out {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }

  .modal-text {
    font-size: 24px;
    color: #212433;
    font-weight: 500;
    line-height: 21px;
    text-transform: initial;
  }
  /* .audio-course-product-outer .progressbar{
  position: absolute;
  left: 59%;
} */
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
  .audio-course-product-outer .circle {
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 58%;
  }
  .audio-course-product-outer .CircularProgressbar {
    width: 45px;
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 58%;
  }
  .profileDrop .dropdown-menu {
    top: 62px !important;
    left: -63px !important;
    transform: none !important;
    background-color: #fff;
    min-width: 173px;
    border-radius: 0px;
  }
}

@media (min-width: 850px) and (max-width: 962px) {
  .audio-img {
    margin-top: 65%;
    font-size: 14px !important;
  }
  .audio-img1 {
    margin-top: 62%;
    font-size: 14px !important;
  }
  .snovel-btn1 {
    font-size: 16px !important;
    left: 33.7%;
    top: 69.2% !important;
  }
  .snovel-btn2 {
    font-size: 16px !important;
    left: 56%;
    top: 69.2% !important;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
}

@media (max-width: 767px) {
  .sliderTrack1_Wrap {
    font-size: 8px !important;
    /* left: 20%;
    top: 176%; */
  }

  .audio-courses-main-tag .column {
    width: 50%;
  }
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
  .stellar-cotation {
    font-size: 15px;
  }
  .stellar-cotation1 {
    font-size: 18px;
  }
  .footer-banner {
    height: 300px;
    background-size: contain;
  }
  .footer-banner h3 {
    top: -38%;
    left: 57%;
    font-size: 14px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -7%;
    left: 389px;
    font-weight: 900;
  }
  .collabrate-inner-text h6 {
    font-size: 9px !important;
  }
  .footer-banner h5 {
    position: absolute;
    top: 43%;
    left: 58%;
    font-size: 11px;
  }
  .stellar-banner {
    background-size: contain;
  }
  .stellar-cotation1 {
    margin-top: 52px;
  }
  .snovel-value-ready2 {
    left: 38%;
    top: 55%;
    font-size: 15px !important;
  }
  .snovel-value-ready1 {
    left: 35%;
    top: 51%;
    font-size: 15px !important;
  }
  .snovel-value-ready {
    left: 41%;
    top: 46%;
  }
  .snovel-value1 {
    left: 32%;
    top: 40%;
    font-size: 16px !important;
  }
  .snovel-value {
    left: 44%;
    top: 33%;
    font-size: 27px !important;
  }
  .snovel-btn1 {
    left: 32%;
    top: 65% !important;
    font-size: 16px;
  }
  .snovel-btn2 {
    left: 54.3%;
    top: 64.3%;
    font-size: 17px;
  }
  .audio-img1 {
    font-size: 7px !important;
    margin-top: 52%;
  }
  .audio-img {
    font-size: 7px !important;
    margin-top: 70%;
  }
  .audio-banner {
    height: auto;
  }
  .audio-banner2 {
    height: auto;
  }
  .audio-banner3 {
    height: auto;
  }
  .audio-banner4 {
    height: auto;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -41px;
  }
  .profileImg {
    margin-left: 95px;
    float: left;
    width: 50px;
  }
  .headerProfileContainer {
    margin-left: 0px;
    padding-top: 0px;
    position: absolute;
    right: 41px;
    top: 55px;
    width: 27%;
    border-left: none;
  }
  .headerProfileContainer .nav .nav-item a {
    font-size: 12px;
    padding: 3px 25px;
  }
}

@media (max-width: 748px) {
  .footer-banner {
    height: 150px;
    background-size: contain;
  }
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
  .footer-banner h3 {
    top: -65%;
    left: 57%;
    font-size: 14px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -7%;
    left: 255px;
    /* margin: 0px; */
    font-weight: 900;
  }
  .collabrate-inner-text h6 {
    font-size: 9px !important;
    margin: 0px;
  }
  .footer-banner h5 {
    position: absolute;
    top: 68%;
    left: 58%;
    font-size: 10px;
  }
  .stellar-banner {
    background-size: contain;
    height: 150px;
  }
  .stellar-cotation {
    font-size: 7px;
    margin-top: 40px;
  }
  .stellar-cotation1 {
    margin-top: 8px;
    font-size: 9px;
  }
  .snovel-enhacment {
    height: 250px;
  }
  .snovel-btn2 {
    left: 56%;
    top: 72%;
    font-size: 9px;
  }

  .snovel-btn1 {
    left: 33%;
    top: 71% !important;
    font-size: 10px;
  }
  .snovel-value-ready2 {
    left: 40%;
    top: 57%;
    font-size: 10px !important;
  }
  .snovel-value-ready1 {
    left: 36%;
    top: 51.5%;
    font-size: 10px !important;
  }
  .snovel-value-ready {
    left: 43%;
    top: 46%;
    font-size: 10px !important;
  }
  .snovel-value1 {
    left: 35%;
    top: 35%;
    font-size: 10px !important;
  }
  .snovel-value {
    left: 46%;
    top: 26%;
    font-size: 15px !important;
  }
  .audio-img {
    font-size: 7px !important;
    margin-top: 64%;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
}

@media (max-width: 667px) {
  .footer-banner h3 {
    top: -55%;
    left: 65%;
    font-size: 14px;
  }
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
  .audio-courses-main-tag .column {
    width: 50%;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -20%;
    left: 290px;
  }
  .collabrate-inner-text h6 {
    font-size: 11px !important;
  }
  .footer-banner {
    height: 100px;
  }
  .footer-banner h3 {
    top: -93%;
    left: 59%;
    font-size: 11px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -30%;
    left: 212px;
  }
  .collabrate-inner-text h6 {
    font-size: 5px !important;
  }
  .footer-banner h5 {
    top: 93%;
    left: 56%;
    font-size: 8px;
  }

  .stellar-cotation {
    font-size: 6px;
    margin-top: 21px;
  }
  .stellar-cotation1 {
    margin-top: -2px;
    font-size: 8px;
  }
  .stellar-text {
    position: absolute;
    left: 63%;
  }
  .stellar-banner {
    height: 100px;
  }
  .snovel-btn1 {
    left: 33%;
    top: 77%;
    font-size: 8px;
  }
  .snovel-btn2 {
    left: 56%;
    top: 77%;
    font-size: 8px;
  }
  .snovel-value-ready2 {
    left: 38%;
    top: 56%;
    font-size: 8px !important;
  }
  .snovel-value-ready1 {
    left: 35%;
    top: 49%;
    font-size: 8px !important;
  }
  .snovel-value-ready {
    left: 43%;
    top: 42%;
    font-size: 8px !important;
  }
  .snovel-value1 {
    left: 34%;
    top: 31%;
    font-size: 8px !important;
  }
  .snovel-value {
    left: 46%;
    top: 21%;
    font-size: 12px !important;
  }
  .snovel-enhacment {
    height: 150px;
  }
  .snovel-app-subscription-section {
    margin-top: 0px;
  }
  .audio_story_img {
    height: 80px;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
}

@media (max-width: 425px) {
  .snovel-btn2 {
    left: 55.5%;
    top: 78% !important;
    font-size: 8px;
  }
  .audio-story-media {
    width: 93px;
    height: 60px;
  }
  .headerProfileContainer ul li {
    margin-right: 20px;
    display: inline-block;
    border: none;
    /* margin-right: 59px; */
    z-index: 2;
    right: 48%;
    position: relative;
  }
  .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -45px !important;
    left: -15%;
    /* left: 9%; */
  }
  .profileDrop {
    margin-left: 6px;
  }
  .headerProfileContainer {
    /* margin-left: 31px; */
    padding-top: 0px;
    position: absolute;
    right: 0px;
    top: 55px;
    width: 27%;
    border-left: none;
  }
  .btmHeader .search-container {
    width: 83%;
    position: relative;
  }
  .audio-img1 {
    font-size: 12px !important;
    margin-top: 49.5% !important;
    font-size: 6px !important;
  }
  .audio-banner {
    margin: 10px !important;
    width: 70%;
  }
  .audio-banner2 {
    width: 70%;
  }
  .audio-banner3 {
    margin: 10px !important;
    width: 70%;
  }
  .audio-banner4 {
    width: 70%;
  }
  .snovel-btn1 {
    left: 33%;
    top: 78% !important;
    font-size: 8px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -15%;
    left: 201px;
  }
  .collabrate-inner-text h6 {
    font-size: 6px !important;
    margin: 0px;
  }
  .footer-banner h5 {
    top: 98%;
    left: 58%;
    font-size: 7px;
  }

  .audio-img {
    font-size: 7px !important;
    margin-top: 71.5%;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  /* .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -37px;
    left: 22%;
  } */
  .profileDropdown .nav .nav-item a {
    font-size: 12px;
    padding: 5px 48px !important;
  }
  .profileImg {
    margin-left: 39px !important;
    float: left;
    width: 35px !important;
  }
  /* .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -45px !important;
    left: 1;
  } */
  .snovelapp-left {
    margin-top: 30px;
  }
  .stellar-subscribe-img {
    width: 100%;
    height: auto;
  }
  .subscribe_btn-audio {
    color: black !important;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* margin: 4px 2px; */
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    left: 16%;
    bottom: 1px;
    background: #da145a;
    text-decoration-line: none !important;
    font-size: 9px;
  }
  .sliderTrack1_Wrap {
    position: absolute;
    font-size: 8px !important;
    bottom: 56px;
    left: 135px;
  }
  .subscribe_btn_audio {
    position: absolute;
    bottom: -4px;
    /* left: 76%; */
    border: none;
    outline: none;
    border: 2px solid #da145a;
    border-radius: 20px;
    padding: 2.5px 15px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }
  .footer_top .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  /* .stellar-text-wrapper-content1{
    padding: 0px !important;
  } */
  .tabrgtContent contentRgtCatDtls {
    position: relative;
  }
  /* .progressbar{
    position: absolute;
    left: 62% !important;
  } */
  /* .homepage-design{
    padding-left: 0px !important;
    padding-right:0px !important;
  } */
  .audio-course-product-outer .circle {
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 63%;
  }
  .audio-course-product-outer .CircularProgressbar {
    width: 45px;
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 63%;
  }
  .subscribe_btn_excersise_lab {
    position: absolute;
    left: 90%;
    top: -46%;
    background-color: white;
  }
  .dowmload-cert {
    width: 40px;
    height: 40px;
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 44%;
    top: 0%;
  }
  .circularProgress-language-two {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 31%;
    top: -53%;
  }
  .contentMainWrap p {
    font-size: 9px;
  }
  .subscribe_btn {
    background-color: #ddd;
    border: "2px solid #da145a";
    color: black;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px -17px;
    cursor: pointer;
    border-radius: 40px;
  }
  .button-tag {
    /* margin-left: 5px; */
    border: 2px solid rgb(218, 20, 90);
    /* padding: 10px 20px; */
    border-radius: 30px;
    background-color: rgb(218, 20, 90);
  }
  .profileDrop {
    margin-left: 6px;
  }
}

/* @media (max-width: 375px) {
  .snovel-btn2 {
    left: 55%;
    top: 74%;
    font-size: 8px;
  }
  .snovel-btn1 {
    left: 33.5%;
    top: 74%;
    font-size: 6px;
  }
  .snovel-value {
    left: 46%;
    top: 29%;
    font-size: 10px !important;
  }
  .snovel-value1 {
    left: 33%;
    top: 36%;
    font-size: 6px !important;
  }
  .snovel-value-ready {
    left: 43%;
    top: 45%;
    font-size: 6px !important;
  }
  .snovel-value-ready1 {
    left: 35%;
    top: 51%;
    font-size: 6px !important;
  }
  .snovel-value-ready2 {
    left: 38%;
    top: 56%;
    font-size: 6px !important;
  }
  .footer-banner h3 {
    top: -70%;
    left: 59%;
    font-size: 8px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -13%;
    left: 134px;
  }
  .footer-banner h5 {
    top: 83%;
    left: 60%;
    font-size: 6px;
  }
  .audio-img1 {
    font-size: 4px !important;
    margin-top: 55%;
  }
  .audio-img {
    font-size: 5px !important;
    margin-top: 77%;
  }
  .snovel-app-subscription-section {
    margin-top: 0px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -13%;
    left: 175px;
  }
} */

@media (max-width: 375px) {
  .audio-img {
    font-size: 7px !important;
    margin-top: 72%;
  }
  .audio-img1 {
    font-size: 5px !important;
    margin-top: 55% !important;
  }
  .collabrate-inner-text h6 {
    font-size: 5px !important;
    margin: 0px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -13%;
    left: 182px;
  }
  .footer-banner h3 {
    top: -84%;
    left: 59%;
    font-size: 8px;
  }
  .footer-banner h5 {
    top: 85%;
    left: 60%;
    font-size: 6px;
  }
  .snovel-btn2 {
    left: 55%;
    top: 73% !important;
    font-size: 8px;
  }
  .snovel-btn1 {
    left: 32%;
    top: 72.7% !important;
    font-size: 8px;
  }
  .snovel-value1 {
    left: 31%;
    top: 33%;
    font-size: 8px !important;
  }
  .snovel-value {
    left: 46%;
    top: 24%;
    font-size: 11px !important;
  }
  .snovel-app-subscription-section {
    margin-top: 0px;
  }
  .snovel-btn2-marathi {
    position: absolute;
    left: 54%;
    top: 74%;
    text-decoration-line: none !important;
    color: darkmagenta !important;
    font-size: 9px;
  }
  .snovel-btn1-marathi {
    left: 34%;
    top: 72%;
    color: white !important;
    position: absolute;
    text-decoration-line: none !important;
    font-size: 10px;
  }
  .snovel-ready2 {
    position: absolute;
    left: 39%;
    top: 55%;
    font-size: 8px !important;
    text-transform: capitalize;
    color: white;
  }
  .snovel-ready1 {
    position: absolute;
    left: 36%;
    top: 49%;
    font-size: 8px !important;
    text-transform: capitalize;
    color: white;
  }
  .snovel-ready {
    position: absolute;
    left: 45%;
    top: 43%;
    font-size: 8px !important;
    text-transform: capitalize;
    color: white;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  .stellar-text-wrapper-content {
    margin-top: 10px !important;
    padding: 0px !important;
  }
  .snovelapp-left {
    margin-top: 35px !important;
  }
  .sliderTrack1_Wrap {
    font-size: 8px !important;
    /* left: 40%;
    top: 124%; */
  }
  .profileImg {
    margin-left: 11px;
    float: left;
    width: 30px !important;
  }
  .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -45px !important;
    left: 25% !important;
  }
  .audio_story_img {
    height: auto !important;
  }
  .audio-courses-main-tag {
    margin-top: 5px;
  }
  .audio-banner {
    margin: 10px 10px 10px 5px !important;
    width: 70%;
  }
  .stellar-subscribe-img {
    width: 100%;
    height: auto;
  }
  .subscribe_btn-audio {
    color: black !important;
    padding: 4px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 40px;
    position: absolute;
    left: 17%;
    margin-top: 89px;
    background: #da145a;
    text-decoration-line: none !important;
    font-size: 7px;
  }
  .subscribe_btn_audio {
    position: absolute;
    bottom: -4px;
    /* left: 73%; */
    border: none;
    outline: none;
    border: 2px solid #da145a;
    border-radius: 20px;
    padding: 2.5px 15px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }
  /* .sliderTrack1_Wrap {
    font-size: 8px !important;
    left: 40%;
    top: 161%;
  } */
  .stellar-text-wrapper-content1 {
    margin-top: 10px;
    padding: 15px !important;
  }
  .homepage-design {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -45px !important;
    left: -9% !important;
  }
  .profileDrop {
    margin-left: 11px !important;
  }
  .audio-story-media {
    width: 165px !important;
    height: 110px !important;
    margin: 2px;
  }
  .audio-banner-wrapper {
    justify-content: center;
  }
  /* .audio-course-product-outer .progressbar{
  position: absolute;
  left: 75%;
} */
  .audio-course-product-outer .circle {
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 56%;
  }
  .audio-course-product-outer .CircularProgressbar {
    width: 45px;
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 56%;
  }
  .contentMainWrap p {
    font-size: 9px;
    font-weight: bold;
  }
  .circularProgress-language-two {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 36%;
    top: -25%;
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 46%;
    top: 0%;
  }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
    border-radius: 41px;
    background-color: rgb(218, 20, 90);
    border: 2px solid rgb(218, 20, 90);
    padding: 7px 10px !important;
    font-size: 14px !important;
  }
  .subscribe_btn {
    background-color: #ddd;
    border: "2px solid #da145a";
    color: black;
    padding: 9px 9px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px -17px;
    cursor: pointer;
    border-radius: 40px;
  }
}

@media (max-width: 320px) {
  .footer-banner h5 {
    top: 73%;
    left: 60%;
    font-size: 6px;
  }
  .collabrate-inner-text h6 {
    font-size: 5px !important;
    margin: 0px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -13%;
    left: 135px;
  }
  .collabrate-inner-text {
    position: absolute;
    top: -13%;
    left: 135px;
  }
  .snovel-btn2 {
    left: 55.5%;
    top: 69% !important;
    font-size: 6px;
  }
  .snovel-btn1 {
    left: 33%;
    top: 70% !important;
    font-size: 6px;
  }
  .sliderTrack1_Wrap {
    font-size: 8px !important;
    /* left: 48%;
    top: 121%; */
  }
  .snovel-btn2-marathi {
    position: absolute;
    left: 54%;
    top: 70%;
    text-decoration-line: none !important;
    color: darkmagenta !important;
    font-size: 8px;
  }
  .snovel-btn1-marathi {
    left: 34%;
    top: 69%;
    color: white !important;
    position: absolute;
    text-decoration-line: none !important;
    font-size: 9px;
  }
  .snovel-ready2 {
    position: absolute;
    left: 39%;
    top: 55%;
    font-size: 8px !important;
    text-transform: capitalize;
    color: white;
  }
  .snovel-ready1 {
    position: absolute;
    left: 36%;
    top: 49%;
    font-size: 6px !important;
    text-transform: capitalize;
    color: white;
  }
  .snovel-ready {
    position: absolute;
    left: 45%;
    top: 43%;
    font-size: 8px !important;
    text-transform: capitalize;
    color: white;
  }
  .snovel-value1 {
    left: 31%;
    top: 33%;
    font-size: 7px !important;
  }
  .snovel-value1 {
    left: 31%;
    top: 33%;
    font-size: 7px !important;
  }
  .langauge-lab-img {
    height: auto;
  }
  .home-collbrate-img {
    height: auto;
  }
  .home-stellar-img {
    height: auto;
  }
  .gift-stellar-img {
    height: auto;
  }
  .home-stellar-kids-img {
    height: auto;
  }
  .home-education-img {
    height: auto;
  }
  .stellar-text-wrapper-content {
    margin: 10px;
    padding: 0px !important;
  }
  .snovelapp-left {
    margin-top: 35px !important;
  }
  .audio-img1 {
    font-size: 3px !important;
    margin-top: 55% !important;
  }
  .audio-banner {
    margin: 5px !important;
    width: 70%;
  }
  .audio-banner3 {
    margin: 5px !important;
    width: 70%;
  }
  .audio_story_img {
    height: auto !important;
  }
  .audio-courses-main-tag {
    margin-top: 5px;
  }
  .ksliderContentWrapper .kidsCategorySliders {
    margin: 0px !important;
    padding: 0px !important;
  }
  .kidsCategoryMain-wrapper {
    margin: 0px !important;
  }
  .headerProfileContainer .dropdownMenu {
    width: 100%;
    float: right;
    top: -45px !important;
    left: 16% !important;
  }
  .headerProfileContainer ul li {
    display: inline-block;
    border: none;
    /* margin-top: 110%; */
  }
  .headerProfileContainer .nav .nav-item a {
    font-size: 10px !important;
    /* padding: 1px 6px !important; */
  }
  .profileImg {
    margin-left: 0px !important;
    float: left;
    width: 30px !important;
  }

  .headerProfileContainer ul {
    padding: 0px 0px;
    margin: 0px;
    margin-top: 22px;
  }
  .stellar-subscribe-img {
    width: 100%;
    height: auto;
  }
  .subscribe_btn-audio {
    color: black !important;
    padding: 2.5px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 42px;
    position: absolute;
    left: 16%;
    margin-top: 79px;
    background: #da145a;
    text-decoration-line: none !important;
    font-size: 8px;
  }
  .subscribe_btn_audio {
    position: absolute;
    bottom: -3px;
    /* left: 68%; */
    border: none;
    outline: none;
    border: 2px solid #da145a;
    border-radius: 20px;
    padding: 2.5px 15px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }
  .audio-story-media {
    width: 140px !important;
    height: 110px !important;
    margin: 2px;
  }
  .stellar-text-wrapper-content1 {
    padding: 15px !important;
  }
  .audio-course-product-outer .circle {
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 48%;
  }
  .audio-course-product-outer .CircularProgressbar {
    width: 45px;
    margin: 6px 6px 20px;
    display: inline-block;
    position: relative;
    text-align: center;
    line-height: 1.2;
    left: 48%;
  }
  .contentMainWrap p {
    font-size: 8px;
  }
  .subscribe_btn_excersise_lab11 {
    position: absolute;
    left: 57%;
    right: 3%;
    top: -11%;
    font-weight: bold;
  }
  .circularProgress-language-two {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 44%;
    top: -25%;
  }
  .subscribe_btn_excersise_lab {
    position: absolute;
    left: 92%;
    top: -20%;
    background-color: white;
  }
  .dowmload-cert {
    width: 30px;
    height: 30px;
  }
  .modal-footer > :not(:first-child) {
    border: 1px solid white;
    border-radius: 30px;
    color: rgb(218, 20, 90);
    padding: 3px;
    font-size: 13px;
    padding: 10px;
    background-color: white;
  }
  .modal-footer > :not(:last-child) {
    border-radius: 30px;
    background-color: rgb(218, 20, 90);
    border: 2px solid rgb(218, 20, 90);
    font-size: 11px !important;
  }
  /* .audio-course-product-outer .circle span {
  color: #212433;
  position: absolute;
  font-size: 11px;
  margin-top: 0;
  margin-left: 0;
  left: 0;
} */
  /* .audio-course-product-outer .CircularProgressbar {
  width: 32px;
  margin: 6px 6px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
} */
}

.footer_member {
  width: 70px;
  height: 70px;
  margin-top: 20px;
  margin-left: 5px;
}

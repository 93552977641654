.video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-control {
    width: 3em
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
    padding: 0 .4em .3em
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
    display: none !important;
}

video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button,.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
    display: none !important;
}

.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -1.5em;
    margin-top: -1em
}

.video-js .vjs-big-play-button {
    background-color: rgba(0,0,0,0.9);
    font-size: 1em;
    border-radius: 0%;
    height: 1em !important;
    line-height: 1em !important;
    margin-top: -0.5em !important
}

.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: rgba(0,0,0,0)
}

.video-js .vjs-loading-spinner {
    border-color: rgba(255,255,255,0.7)
}

.video-js .vjs-control-bar2 {
    background-color: transparent
}

.video-js .vjs-control-bar {
    background-color: rgba(0,0,0,0) !important;
    color: rgba(255,255,255,254.99);
    font-size: 10px;
    margin-bottom: 0.55rem;
}

.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
    background-color: #db3f5d;
}

.video-js .vjs-big-play-button {
    height: 2em !important;
    width: 2em !important;
    line-height: 1.9em !important;
    margin-top: -1em !important;
    margin-left: -1em;
    border-width: 3px
}

.video-js .vjs-icon-play:before, .video-js .vjs-big-play-button:before {
    font-size: 50px;
}

.video-js  .vjs-progress-holder {
    font-size: 1.7em;
    border-radius: 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress, .video-js .vjs-progress-holder .vjs-load-progress, .video-js .vjs-progress-holder .vjs-load-progress div, .video-js .vjs-slider,.vjs-volume-level {
    border-radius: 10px;
}

.video-js .vjs-load-progress {
    background: rgba(255,255,255,0.5);
}

.video-js  .vjs-seek-to-live-control.vjs-control.vjs-at-live-edge {
    display: none !important;
}

.video-js .vjs-fullscreen-control.vjs-control.vjs-button{
    visibility: hidden !important;
}

.video-js .vjs-picture-in-picture-control.vjs-control.vjs-button {
    display: none !important;
}
/* Media styles for Mobile view */

@media (max-width: 1199.98px) {
    .language-lab-outer .tabrgtContent {
        max-width: 490px;
    }
}

@media (max-width: 950.98px) {
	.language-lab-outer .tabrgtContent p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .language-lab-outer .timeCounterPnl, .audio-course-product-outer .timeCounterPnl {
        font-size: 13px;
        margin: 10px 0 7px 0;
    }

    .language-lab-outer .readMoreLink {
        margin-top: 0px;
        margin-bottom: 0;
    }

    .language-lab-outer .tabrgtContent h4 {
        font-size: 18px !important;
    }

    .language-lab-outer .tabrgtContent {
        max-width: 302px;
    }
}

@media (max-width: 767px) {
	.browseLatest_Lauch .videoPurchase .subsCribeButton {
		width: 40%;
		margin-right: 10px;
	}
	.audition-wrapper .form-group {
		width: 100%;
		margin-right: 0px;
	}
	.audition-wrapper .loginErrorMsg {
		bottom: 6px;
		left: -5px;
		font-size: 12px;
	}
	.project-audition-wrapper {
		flex-direction: column;
	}
	#loading-center-absolute {
		position: absolute;
		left: 50%;
		top: 50%;
		height: 150px;
		width: 150px;
		margin-top: -75px;
		margin-left: -75px;
	}
	.headerLeft_Section {
		width: 56%;
	}
	.headerRight_Section {
		width: 40%;
	}
	.headerLanguageSelection {
		width: 68%;
		top: -4px;
	}
	.headerProfileContainer::before {
		height: 25px;
		top: 2px;
	}
	.profileImg img {
		width: 35px;
		height: 35px;
	}
	.profileDrop .dropdown-toggle {
		padding:0px;
	}
	.profileDrop .dropdown-menu {
		top: 38px !important;
		min-width: 80px;
		width: 75px;
		left: 6px !important;
	}
	.headerProfileContainer .signup_Wrapper {
        width: 100%;
		float: right;
		position: relative;
        top: -7px;
	}
	.dropdownMenu:hover .profileDropdown {
		top: 55px;
		left: 0px;
		width: 88px;
	}
	h1 {
		font-size: 32px;
		line-height: 35px;
	}
	.list-inline.info {
		width: 60%;
	}
	.list-inline.social_icon {
		width: 40%;
		text-align: right;
	}
	nav.navbar.bootsnav .navbar-toggle {
		margin-left: 15px;
	}
	.carousel-inner>.item>a>img,
	.carousel-inner>.item>img {
		height: 450px;
	}
	.carousel-caption>h3 {
		font-size: 26px;
	}
	.carousel-caption>p {
		font-size: 18px;
		line-height: 22px;
	}
	.carousel-caption .btn.know_btn {
		margin-top: 35px;
	}
	.carousel-caption .btn.know_btn:last-child {
		margin-left: 30px;
	}
	.about_content>h2 {
		font-size: 26px;
		margin-top: 70px;
	}
	.about_content>h3 {
		font-size: 26px;
	}
	.aboutUs-videoWrap {
		width:100%;
	}
	.service_item .btn.know_btn {
		margin-bottom: 80px;
	}
	#contact_form h2 {
		margin-top: 10px;
	}
	#contact_form .second_heading {
		margin: 8px 0 20px;
	}
	#contact_form form.form-inline {
		margin: 0;
	}
	#contact_form .form-inline .form-control,
	#contact_form .form-inline textarea.form-control {
		margin: 0;
		width: 100%;
	}
	#contact_form .btn.submit_btn {
		float: left;
		margin: 0;
		width: 145px;
	}
	
	.headerProfileContainer {
		margin-left:0px;
		padding-top: 0px;
		position: absolute;
   		right: 0px;
		top: 55px;
		width:27%;
		border-left: none;
	}
	.headerProfileContainer.dropdown-toggle {
		height: 25px;
        margin-left: 0px;
	}
	.headerProfileContainer .dropdownMenu {
		width: 100%;
        float: right;
        top: 0px;
	}
	.headerProfileContainer .signup_Wrapper .nav {
		justify-content: unset;
		margin-right: 0px;
	}
	.languageDropdown .dropdown-toggle {
		padding:0px;
	}
	.searchSuggetion_Wrapper {
		top: 42px;
	}
	.headerProfileContainer .dropdownMenu::before {
		top: 6px;
	}
	.search-container .searchButton_Wrapper {
		top: 9px;
	}
	.search-container input[type=text] {
		padding: 4px 6px 4px 12px;
	}
	.btmHeader .search-container {
		padding-left: 15px;
		margin-bottom:10px;
	}
	.profileDrop .dropdown-toggle .fa-caret-down {
		padding-top: 4px;
	}
	.profileDrop {
		margin-left: 0px;
	} 
	.headerProfileContainer .nav .nav-item a {
		font-size: 12px;
        padding: 3px 6px;
	}
	.mobileRemovePadding {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.navbar {
		padding: 0px 0rem 0px !important;
	}
	.navbar-brand {
		margin-right: 0rem !important;
		float: right;
        width: 70%;
	}
	.popularInner_Wrapper .active img {
		width:100%;
	}
	.navbar-toggler {
		float: left;
		width: 15%;
		display: block;
	}
	.headerRightCont {
		width: 52%;
		float: right;
	}
	.btmHeader {
		display: none;
	}
	.headerRightCont .navbar {
		display: initial;
	}
	.topLanguage {
		width: 80%;
		float: right;
		border-left: none;
		padding-right: 0px;
	}
	.libraryBskDiv img {
		margin-right: 4px;
	}
	.navbar-brand .logo {
		max-width: 60%;
	}
	.mobile-show-content,
	.mobile-show-content .btmHeader,
	.mobile-show-content .headerProfileContainer {
		display: block !important;
	}
	.headerProfileContainer .profileText {
		display: none;
	}
	.mobile-show-content .headerProfileContainer {
		width: 24%;
        float: left;
	}
	.mobile-show-content {
		width:74%;
	}
	.mobile-show-content .btmHeader {
		width:100%;
	}
	header .navbar.bootsnav .container {
		padding-left: 0px;
		padding-right: 0px;
		justify-content: unset;
	}
	.headerLeft_Section .navbarLeft {
		float: left;
		position: inherit !important;
	}
	.language_tranlation {
		width: 10%;
		float: left;
	}

	.btn.know_btn {
		font-size: 12px;
		padding: 8px 30px;
	}
	.mainSlider_button {
		bottom: 18px;
	}
	header {
		padding-top: 10px;
        padding-bottom: 5px;
	}
	.carousel-inner {
		height: 200px;
	}
	.carousel-item {
		height: 100%;
	}
	.carousel-inner .carousel-item img {
		height: 100%;
	}
	.homeTabs {
		padding: 0px 0px;
	}
	.homeTabs li.nav-item {
		width: 33.33%;
		margin-right: 0px !important;
	}
	.homeTabs li.nav-item a {
		display: unset;
		position: relative;
		padding: 10px 8px;
	}
	.tabIcon_image {
		width: 100%;
		float: left;
		text-align: center;
	}
	.tabRight_Content {
		text-align: center;
		width: 100%;
		font-size: 12px;
	}
	.homeTabs li.nav-item a .tabRight_Content span {
		position: absolute;
		font-size: 14px;
		right: 6px;
		top: 1px;
	}
	.tabIcon_image img {
		width: 28%;
		margin-bottom: 4px;
	}
	
	.homeTabs .nav-pills .nav-item:nth-child(1) .tabIcon_image img {
		width: 33%;
	}
	.audioDiv h4 {
		font-size: 12px !important;
	}
	.browseLatest_Lauch .videoPurchase .purchaseButton {
		width: 50%;
	}
	.react-multi-carousel-list {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.react-multi-carousel-list ul li {
		margin-left: 0px;
		width: 170px !important;
	}
	.innerSlider_Wrapper .react-multi-carousel-list {
		padding-left: 0px;
	}
	.videoDesc {
		padding-left: 8px;
		padding-top: 10px;
	}
	.snovelapp-left {
		padding: 20px 16px;
		margin-bottom: 20px;
	}
	.iphone-app-btn {
		width: 100%;
	}
	.android-app-btn {
		width: 100%;
		margin-top: 14px;
	}
	.videoPurchase .subsCribeButton {
		width: 46%;
		margin-right: 16px;
	}
	.videoPurchase a {
		padding: 11px 14px;
		font-size: 14px;
	}
	.videoPurchase .purchaseButton {
		width: 46%;
		float: left;
		margin-right:5px;
	}
	.mobileRemovePadding .nav-pills li:nth-child(2) .tabIcon_image img {
		width: 33%;
		float: none;
	}
	.homeTabs .nav-pills a.nav-link {
		padding: 10px 6px;
	}
	.videoPurchase a {
		width: 100%;
	}
	.audioBook_Wrapper .audioDiv {
		word-break: break-all;
		margin-bottom: 0px;
	}
	.popularComponant_Wrapper .nav-pills>li>a {
		font-size: 14px;
	}
	.popularComponant_Wrapper .nav li {
		margin-right: 26px;
	}
	.each-track-cont {
		margin-top: 20px;
	}
	.collaboration-points .collab {
		width: 100%;
		float: none;
	}
	.directCircle {
		margin-right: auto;
		margin-left: auto;
		display: flex;
		background: #fff;
	}
	.collaboration-pointsmobile {
		background: url("/../../images/diagram_mobile.jpg") no-repeat;
		background-position: center;
		background-size: 80%;
		height: auto;
	}
	.collaboration-points .director {
		top: 0px;
		left: 0px;
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 0px;
	}
	.collaboration-points .writer {
		left: 0px;
		top: 0px;
		margin-bottom: 0px;
	}
	.iphone-app-btn p {
		width: 100%;
	}
	.android-app-btn p {
		width: 100%;
	}
	.collaboration-points .publisher {
		left: 0px;
		top: 0px;
		margin-bottom: 0px;
	}
	.collaboration-points .voice-artist {
		left: 0px;
		top: 0px;
		margin-bottom: 0px;
	}
	.footer_item ul li {
		display: inline-block;
	}
	.collaboration-points .director p {
		position: absolute;
		bottom: 61px;
		font-size: 14px;
		width: 20%;
		left: -2%;
		top: 35%;
	}
	.track-background .title-head h2 {
		font-size: 36px;
	}
	.collaboration-points .writer p {
		font-size: 14px;
		width: 16%;
		left: auto;
		top: 35%;
		right: 0px;
	}
	.collaboration-points .publisher p {
		font-size: 14px;
		font-weight: 400;	
		width: 20%;
		left: 0px;
		top: 35%;
	}
	.collaboration-points .voice-artist p {
		font-size: 14px;
		width: 18%;
		left: auto;
		top: 35%;
		right: 0px;
	}
	.audioDiv img {
		width: 100%;
		margin-bottom: 0px;
	}
	.homeTabs .tab-content {
		height: 124px;
		overflow: hidden;
		padding-top:0px;
	}
	.homeTabs-category-wrap .tab-content {
		height: 105px;
	}
	.popularComponant_Wrapper .nav-tabs {
		padding-top: 8px;
	}
	.mobileRemovePadding .nav-pills li:nth-child(2) {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
	}
	.browseAll {
		margin-bottom: 10px;
	}
	.sliderProductAuthor {
		display: none;
	}
	.sliderProductCategory {
		display: none;
	}
	.sliderProductTitle {
		display: none;
	}
	.collapse.show .card {
		width: 100%;
	}
	.footer_item ul li a {
		padding-left: 5px;
		padding-right: 5px;
		border-right: 1px solid #818183;
		padding-top: 0px;
		padding-bottom: 0px;
		font-size: 12px;
		line-height: 14px;
	}
	.accordian-wrap .fa-sort-down {
		position: absolute;
    	right: 6px;
    	top: 14px;
	}
	.accordian-wrap .fa-sort-up {
		position: absolute;
    	right: 6px;
    	top: 18px;
	}
	.footer_item ul li:first-child a {
		padding-left: 0px;
	}
	.footer_item ul li:last-child a {
		border-right: none;
	}
	.footer_item ul.footer_social_icon li a {
		border-right:none;
	}
	.footer_item {
		margin-top: 30px;
	}

	.navbar-toggler .navbar-toggler-icon {
		background-image: url("/../../images/Hamburger_icon.svg");
	}
	.desktopMenu {
		position: absolute;
		top: 59px;
		background-color: rgb(255, 255, 255);
		z-index: 999;
		width: 100%;
		left: 0px;
	}
	.headerRightCont .navbar-nav li {
		padding: 6px 15px 6px;
	}
	.headerRightCont .navbar-nav li:nth-child(1) {
		padding-left: 15px;
	}
	.headerRight_Section .languageDropdown {
		width: 74%;
		position: absolute;
        right: 0px;
	}
	.track-background {
		height: auto;
	}
	.track-background .title {
		max-width: 92% !important;
		margin-bottom: 16px;
	}
	.track-btn {
		display: table;
	}
	.track-btn .subsCribeButton {
		padding: 4px 40px 4px 40px;
		width: 90%;
		margin-left: 17px;
		margin-bottom: 16px;
	}
	.track-btn .purchaseButton {
		padding: 4px 40px 4px 40px;
		cursor: pointer;
		width: 90%;
		margin-left: 16px;
	}
	.starRating {
		float: left;
		width: 52%;
	}
    .billProfileLFtLogo {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	.planNameDiv {
		text-align: center;
		margin-left: 0px;
		float: none;
	}
	.billingProfile {
		text-align: center;
	}
	.billingProfile a {
		text-align: center;
		float: none;
		margin-left: 0px;
	}
	.billingBell {
		float: none;
		margin-left: auto;
		margin-right: auto;
		margin-top: 14px;
	}
	.billingTopWrp {
		margin-top: 30px;
	}
    .mySnovelFaq .emailRight {
		text-align: left;
	}
    .forgetPass .modal-body {
		max-width: 100%;
		margin: 0 auto;
	}
	.kep-login-facebook {
		font-size: 14px !important;
		padding: 10px 2% !important;
	}
	
	button.google-login-button{
		font-size: 14px;
		padding: 10px 1%;
	}

	.welcomeBack form {
		max-width: 88%;
		width: 100%;
		margin: 0 auto;
	}
	.kep-login-facebook span img {
		margin-right: 12px;
	}
	.billCheckBx.emailSign-upBox {
		left:40px;
		/* width: calc(100% - 40px); */
		width: auto;
	}
	.homeTabs.tryFree-snovel-wrapper .nav-pills .nav-link.active {
		padding: 13px 15px;
	}
	/* Library Page */
	.billProfileLFtLogo {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	.planNameDiv {
		text-align: center;
		margin-left: 0px;
		float: none;
	}
	.billingProfile {
		text-align: center;
	}
	.billingProfile a {
		text-align: center;
		float: none;
		margin-left: 0px;
	}
	.billingBell {
		float: none;
		margin-left: auto;
		margin-right: auto;
		margin-top: 14px;
	}
	.billingTopWrp {
		margin-top: 60px;
    	margin-bottom: 20px;
	}
	.mySnovelFaq .emailRight {
		text-align: left;
	}
	.common-modal.modal-dialog{
		width: 94%;
		margin-left: auto;
		margin-right: auto;
	}
	.common-modal.modal-dialog .modal-header{
		padding-bottom: 0px;
	}
	.common-modal.modal-dialog .modal-body{
		padding: 0px;
	}
	.myprofilePopLft{
		float: none;
		width: 100%;
	}
	.myprofilePopLft img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.common-modal.modal-dialog .modal-body h4.modal-title{
		float: none;
		text-align: center;
		margin-left: 0px;
	}
	.myprofilePopTitle{
		margin-left: 0px;
		text-align: center;
	}
	.myProfilePopLocDiv {
		margin-left: 11px;
		font-size: 16px;
		line-height: 24px;
	}
	.myProfilePopLocDiv span.spanConBtm {
		width: 25%;
	}
	.myProfilePopLocDiv span.spanConBtm:nth-of-type(1) img {
		margin-right: 7px;
	}
	.myProfilePopLocDiv span.spanIndBtm {
		max-width: 75%;
		width: 75%;
	}
	.myProfilePopLocDiv span.subsCribeButton {
		width: 40%;
		margin-right: 0px;
	}
	span.newSubscriptButton{
		border: none;
		background: none;
	}
	.planNameDiv p:nth-of-type(2) {
		font-size: 13px;
	}
	.planNameDiv p:nth-of-type(2) span {
		font-size: 13px;
	}
	/* Library Page */
	.browseDropDownMenu {
		width: 100%;
		z-index: 999;
		top: 0px;
	}
	.browseDropdown .fa-sort-down {
		right: 16px;
		top: 12px;
	}
	.languageDropdown .dropdown-menu {
		min-width: 5rem;
		left: -20px;
	}
	.browseType_Wrapper .homeTabs .innerProductsTab li.nav-item {
		padding-left: 4px;
		padding-right: 4px;
	}
	.topLanguage .libraryBskDiv {
		margin-top: 0px;
		float: left;
		margin-left: 4px;
		width: 60px;
	}
	.languageSelection {
		float: right;
		width: 54%;
		text-align: right;
	}
	.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link {
		padding: 0px 0px 10px !important;
	}
	.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link.active {
		padding: 0px 10px 10px !important;
	}
	.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link:hover {
		padding: 0px 0px 10px !important;
	}
	.browseType_Wrapper .homeTabs .innerProductsTab li a {
		font-size: 14px;
	}
	.browseType_Wrapper .homeTabs .tab-content {
		height: auto;
	}
	.audioBook_Wrapper .videoThumbDiv img {
		height: 100%;
	}
	.browseType_Wrapper .homeTabs {
		padding: 0px 0px 10px;
	}
	.browseTopSliderWrp {
		margin-bottom: 0px;
	}
	.browseTopSliderWrp .shareIconDiv {
		width: 15%;
	}
	.snovelOriginal {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
	}
	.popularImage_Wrapper {
		height: 100%;
	}
	.shareIconDiv {
		float: right;
		width: 70px;
		position: relative;
	}
	.browseLatest_Lauch .starRating {
		width: 60%;
	}
	.browseMenuWrapper .dropdown-menu {
		width: 100%;
		top: -76px !important;
		transform: none !important;
	}
	.aboutTopWrp {
		padding-top: 12px;
		padding-bottom: 40px;
		background-size: cover;
	}
	.aboutUsmiddlewrp {
		padding-left: 15px;
		padding-right: 15px;
	}
	.abtImg img {
		max-width: 100%;
	}
	.contactLogo img {
		margin-top: 10px;
		margin-bottom: 40px;
	}
	.formDivleft {
		width: 100%;
	}
	.formDivright {
		width: 100%;
	}
	.form-group {
		margin-bottom: 0rem;
	}
        .videoPlayList {
		padding: 15px 15px 10px;
	}
	.videoPlayP {
		width: 15px;
	}
	.videoPlayTitle {
		width: 58%;
		margin-left: 13px;
		font-size: 100%;
	}
	.videoPlayTitle span {
		margin-right: 5px;
	}
	.breadCrumbWrp {
		display: none;
	}

	#owlFaq .owl-item {
		max-width:375px;
	}
	#owlFaq .owl-nav .owl-next {
		float: right;
		margin-right: -12px;
	}
	#owlFaq .owl-nav .owl-prev {
		float: left;
		margin-left: -12px;
	}
	.owl-nav {
		display: none;
	}
	.mediaNewsLft {
		width: 100%;
		padding-right: 0%;
		border-right: none;
	}
	.alertTopDiv {
		padding: 10px;
	}
	.product-summery {
		padding-left: 0px;
        padding-top: 20px;
	}
	.productDescript_Wrapper {
		padding-left: 15px;
        padding-right: 15px;
	}
	.singlePage-productSlider {
		margin-top: 20px;
	}
	.subsCriBtnBtm {
		text-align: center;
		width: auto;
		margin: 30px auto;
		display: table;
	}
	.subBenefit_Wrapeer h2 {
		font-size: 32px;
	}
	.subsCriptionMid {
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.singleSliderDefualt img {
		height: 188px;
	}
	.popupShareButton {
		left: -123px;
	}
	.popupShareButton:before {
		left: 78%;
	}
	.forGotPass {
		font-size: 15px;
	}
	#slidecontrols {
		width: 100%;
		margin-top: 26px;
		margin-bottom: 20px;
	}
	.libraryBskDiv {
		float: right;
		width: 74%;
		margin-bottom: 10px;
	}
	.basketSection {
		float: right;
		width: 40%;
	}
	.basketSection .libraryBskDiv {
		width:100%;
	}
	.mobileButtons {
		width: 100%;
    	float: left;
    	margin-bottom: 10px;
	}
	.purchaseButton.gift-button {
		margin-left: 0px;
    	width: 24%;
	}
	.purchaseButton.product-details-gift-button{
		width: 46%;
	}
	.topcontentDiv h2 {
		font-size: 25px;
	}
	.contactFormDiv {
		margin-top: 40px;
	}
	.contactform .loginErrorMsg {
		margin-bottom: 8px;
	}
	.loginErrorMsg {
		padding-left: 0px;
	}
	.homeTabs .nav-pills .nav-link.active {
		margin-top: 0px;
		padding: 13px 15px;
	}
	.formBillLft {
		width: 100%;
	}
	.formBillRgt {
		width: 100%;
	}
	.billFrmInputLft {
		width: 100%;
	}
	.billFrmInputRgt {
		width:100%;
	}
	.profileImg {
		margin-left: 11px;
		float: left;
		width: 50px;
	}

	.billFrmInputRgt input[type="text"],
	.billFrmInputLft input[type="tel"],
	.billFrmInputLft input[type="text"],
	.billFrmInputLft #countryCode {
		max-width: 100%;
	}
	.snovelSignUpBtm .billCheckBx {
		margin-left: 8%;
		max-width: 320px;
	}
	.snovelSignUpBtm .visitFaq.emailRight {
		text-align: left;
	}
	.ScrollUpButton__Container.ScrollUpButton__Toggled {
		width: 32px;
		right: 5px;
		height: 18px;
	}
	.bottombar {
		width: 25% !important;
		float: left;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.subscriptionBox_Wrapper {
		margin-bottom: 20px;
	}
	.mySnovelFaq .visitFaq {
		width: 100%;
	}
	.giftSubsCription {
		margin-top: 22px;
	}
	.giftSubsCription a {
		border-radius: 40px;
		padding: 14px 20px 14px 20px;
	}
	.unlimiTedAnnul .subsCribeButton {
		padding: 12px 50px 12px 49px;
	}
	.authorDivWrp {
		margin-top: 0px;
		padding: 30px 20px;
	}
	.authorDivImg {
		width: 100%;
		float: left;
		margin-bottom: 10px;
	}
	.authorDivCnt {
		width: 100%;
	}
	.authorDivCnt p {
		margin-top: 14px;
	}
	.authorProdcutSlider_Wrap {
		padding-left: 15px;
		padding-right: 15px;
	}
	.authorSlider .videoThumbDiv {
		height: 160px;
	}
	.authorSliderDefualt img {
		height: 160px;
	}
	.authorSlider .videoThumbDiv img {
		height: 160px;
	}
	.authorSlider .videoThumbTitleDiv,
	.authorSlider .videoThumbCateDiv {
		display: none;
	}
	.authorSlider .owl-nav .owl-prev {
		float: left;
		margin-left: -14px;
	}
	.authorSlider .owl-nav .owl-next {
		float: right;
		margin-right: -16px;
	}
	.giftSubscriptionFrmFull input {
		width: 100%;
	}
	.giftSubscriptionFrmLftSubmit {
		float: left;
		width: 140px;
	}
	.giftSubscriptionFrmLftSubmit input {
		width: 140px;
	}
	.giftSubscriptionFrmRgtSubmit {
		float: right;
		width: 140px;
	}
	.giftSubscriptionFrmRgtSubmit input {
		width: 140px;
	}
	.browseReady_Begin .track-btn .subsCribeButton {
		padding: 10px 40px 10px 40px;
		text-align: center;
		font-weight: 600;
	}
	.browseReady_Begin .readyBrw .track-btn .purchaseButton {
		padding: 11px 40px 11px 40px;
		font-weight: 600;
	}
	.browseReady_Begin .readyBrw {
		padding: 5% 5% 10%;
	}
	.videoPurchase .purchaseButton a {
		padding: 13px 17px;
	}
	.collaborateText_Wrapper {
		margin-top: 20px;
	}
	.popularProduct_Slider .owl-nav,
	.innerSlider_Wrapper .owl-nav {
		display: none;
	}
	.popularProduct_Slider  .owl-carousel .owl-item .sliderTrack_Wrap {
		top: 50%;
	}
	.mySnovelVideo.purchasedLibraryWrap .videoThumbDiv {
		width: 100%;
		float: left;
		margin-top: 18px;
	}
	.mySnovelVideo .videoDesc {
		width: 100%;
		margin-top: 14px;
		padding-left: 0px;
	}
	#libraryTabOuter .homeTabs .innerProductsTab li a {
		font-size: 14px;
	}
	#libraryTabOuter .innerProductsTab.nav-tabs .nav-link {
		padding: 0px 0px 10px !important;
	}
	.signup-model .signupWrapper {
		width: 100%;
	}
	.changePasswordWrap {
		width: 90%;
	}
	.ftFirstCol .footer_item_link.nav-link {
		width: 30%;
	}
	.popularImage_Wrap .audioPlayer_Wrapper img {
		width: auto;
	}
	.videoPurchase .mobileButtons .subsCribeButton a {
		padding: 10px 14px;
	}
	.videoPurchase .mobileButtons .subsCribeButton {
		width: 44%;
		margin-right: 10px;
	}
	.singlePage-productSlider h2 {
		font-size: 16px;
	}
	.productDetailsMain_Wrapper .videoPurchase .purchaseButton a {
		padding: 10px 14px;
	}
	.reviewWrp {
		padding: 20px 0px 20px 20px;
	}
	.haveQuery h2 {
		font-size: 16px;
	}
	.searchLftdivWrp {
		float: left;
		margin-top: 10px;
	}
	.searchPrice {
		padding: 20px 0px;
		width: 100%;
	}
	.searchAdvRgtDiv {
		max-width: 100%;
		padding: 18px;
	}
	.searchAdvRgtDivCnt h2 {
		font-size: 18px;
		margin-top: 10px;
	}
	.searchPricDiv {
		margin-bottom: 14px;
	}
	.searchAdvRgtDivImg img {
		height: 100%;
		width:100%;
	}
	.searchAdvRgtDivImg {
		max-width: 100%;
	}
	.serachLftImg {
		width: 76%;
	}
	.contact-button-wrapper #g-recaptcha {
		width: 100%;
		float: left;
	}
	.contactTopleft {
		margin-left: 10px;
	}
	.contactusTopWrp {
		padding: 30px 50px;
	}
	.selectedTabTxtDiv {
		margin-bottom: 16px;
	}
	#libraryTabOuter .mySnovelVideo .playButn {
		left: 40%;
	}
	.searchAdvRgtDivInnerWrp {
		display: unset;
	}
	.homeTabs.tryFree-snovel-wrapper .tab-content {
		height: auto;
		margin-bottom: 30px;
	}
	.tryFree-snovel-wrapper .mySnovelVideo .videoThumbDiv {
		width: 100%;
		float: left;
		margin-top: 20px;
	}
	.tryFree-snovel-wrapper .audioBook_Wrapper .videoThumbDiv img {
		height: 240px;
	}
	.tryFree-snovel-wrapper .audioBook_Wrapper .videoThumbDiv .playButn img {
		height: auto;
	}
	.tryFree-snovel-wrapper .freeTrailMpbileVideo .playButn {
		left: 38%;
		top: 38%;
	}
	.tryFree-snovel-wrapper .mySnovelVideo .videoDesc {
		margin-top: 6px;
	}
	.authorSlider .playButn {
		top: 40%;
	}
	.mySnovel.library-subscription .unlimiTedAnnul {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.videoCnt .loopdata {
		padding-left: 0px !important;
		float: left;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: inherit;
	}
	.homeTabs.tryFree-snovel-wrapper .nav-pills a.nav-link {
		padding: 13px 15px;
	}
	.browseTopSliderWrp .owl-carousel .owl-item .topBrowseImage img {
		height: 100%;
	}
	.library-subscription .liraryBtnFav {
		margin-bottom: 30px;
	}
	.left-padding-none-mobile {
		padding-left:0px !important;
	}
	.field-section-outer {
		margin-left: 15px;
	}
	.myprofilePopLocation .myProfilePopLocDiv label {
		font-size: 16px;
		font-weight: 400;
	}
	.myProfilePopLocDiv span.subsCribeNews {
		margin-left: 3px;
	}
	.myProfilePopLocDiv .billCheckBx {
		margin-top: 15px;
	}
	.myprofilePopLocation span.purchaseButton.changePasswordBtn {
		margin-left: 0px;
		margin-top: 10px;
	}
	.pagenot-found-wrapper h5 {
		font-size: 14px;
	}
	.pagenot-found-wrapper h2 {
		font-size: 20px;
	}
	.giftSubsCription .subscriptionLink {
		width: 75%;
	}
	.checkoutIndBtnRgt a {
		float: left;
	}
	.checkoutIndBtnLft {
		float: left;
		width: 100%;
		margin-bottom: 10px;
	}
	.checkoutIndBtnRgt {
		float: left;
		width: 100%;
	}
	.cart-modal.modal-dialog {
		margin:0px;
	}
	.videoCnt .headingText, .productDetails_content .headingText {
		width: 60px;
	}
	.productDetails_content .loopdata {
		float: left;
		width: 74%;
	}
	.videoPrice.videoPrice-mobile {
		margin-top: 0px;
		height: 0px;
		line-height: 0px;
	}
	.tabIcon_image:first-child {
		width: 100%;
	}
	.owl-carousel .owl-item .sliderTrack_Wrap {
		top: 50%;
    	transform: translateY(-50%);
	}
	.ebook-wrapper .ebook-button {
		padding: 5px 10px;
		font-size: 0.75rem;
	}
	.ebook-wrapper {
		margin-top: 15.3%;
	}
	.language-lab-outer .catLinks {
        width: 100%;
    }

    .language-lab-outer .tabrgtContent {
        max-width: 100%;
    }

    .language-lab-outer .catContentPnl {
        margin-top:20px;
    }

    .language-lab-outer .thmbWrp {
      float: none;
      margin:0 auto 15px;
      width: 100%;
    }

    .language-lab-outer .imgThmb {
        width: 100%;
    }

    .language-lab-outer .categoryCommonTitle, .catTabsPnl h3 {
        font-size: 18px;
    }

    .language-lab-outer .contentMainWrap p {
        font-size: 14px;
    }

    .language-lab-outer .purpleStrip {
        padding: 13px 10px 18px;
        font-size: 20px;
        margin: 25px 0;
    }

    .language-lab-outer .catLinks {
        margin: 15px 0 0 0;
    }

    .language-lab-outer .tabrgtContent h4 {
        font-size: 20px;
    }

    .language-lab-outer .readMoreLink {
        margin-top: 15px;
    }

    .language-lab-outer .tabrgtContent {
        padding-left: 5px;
    }
}

@media screen and (device-aspect-ratio: 40/71) {
	.headerProfileContainer .nav .nav-item a {
		font-size: 12px;
		padding: 3px 6px;
	}
	.desktopMenu {
		top: 66px;
		width: 320px;
		left:0px;
	}
	.headerRight_Section {
		width: 42%;
	}
	.browseType_Wrapper .homeTabs .innerProductsTab li a {
		font-size: 11px;
	}
	.snovelSignUpBtm .billCheckBx {
		margin-left: 8%;
		max-width: 270px;
	}
	.topLanguage {
		width: 88%;
	}
	.headerRightCont {
		width: 48%;
		float: right;
	}
	.profileImg {
		margin-left: 2px;
	}
}

@media only screen 
and (device-width : 414px) 
and (device-height : 736px) 
and (-webkit-device-pixel-ratio : 3) {
    .desktopMenu {
		position: absolute;
		top: 83px;
		background-color: rgb(255, 255, 255);
		z-index: 999;
		width: 414px;
		left: 0px;
	}
	.topLanguage {
		width: 61%;
	}
}

/*****  Media style for Tablet  ******/

@media (max-width: 991px) {
	.footer_top .ftFirstCol {
		width: 100% !important;
		flex: 100%;
	}
	.footer_top .ftFirstCol:nth-child(2) {
		width: 100% !important;
		flex: 100%;
	}
	.footer_top .ftSecCol {
		width: 100%;
		flex: 100%;
		max-width: 100%;
	}
	.videoCnt .loopdata {
		padding-left: 0px !important;
		float: left;
		width: 70%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: inherit;
		height: 26px;
	}
	.videoCnt a {
		display: inline-block;
	}
	
}

@media (min-width: 992px) and (max-width: 1024px) {
	.tabBrowseTabMobile {
		display: none;
	}
	.mobileProfileDiv {
		display: none;
	}
	.nav.navbar-nav.menu li:nth-child(6) {
		display: none;
	}
	.search-containerMobile {
		display: none;
	}
	.search-toggleMobile {
		display: none;
	}
	.snovelColLogo {
		display: none !important;
	}
	p.mobileDevice {
		display: none;
	}
	.browseAllTab1 span {
		display: none;
		float: right;
	}
	.browseAllTab1 a span {
		color: #da145a;
	}
	.browseLessTab1 {
		display: none;
	}
	.browseAllTab2 span {
		display: none;
		float: right;
	}
	.browseAllTab2 a span {
		color: #da145a;
	}
	.browseLessTab2 {
		display: none;
	}
	.browseAllTab3 span {
		display: none;
		float: right;
	}
	.browseAllTab3 a span {
		color: #da145a;
	}
	.browseLessTab3 {
		display: none;
	}
	.tabliCntMobile {
		display: none;
	}
	.tabliCntMobile {
		display: none;
	}
	.mySnovelVideo .videoDesc {
		width: 76%;
	}
	.loggedUserWrap {
		width: 52%;
	}
	.try-free-snovel-nav{
		width: 48%;
	}
	.homeTabs li.nav-item {
		width: 22%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.productDetailsMain_Wrapper .videoPurchase .purchaseButton.gift-button a {
		width: 120px;
	}
	.collaboration-pointsmobile {
		background: url("/../../images/diagram_mobile.jpg") no-repeat;
		background-position: center;
		background-size: 40% !important;
		height: auto;
	}
	.collaboration-points .collab img {
		width: 45px;
		margin-top: 50px;
	}
	.search-containerMobile button {
		float: right;
		width: 28px;
	}
	.search-containerMobile button img {
		width: 18px;
	}
	.headerProfileContainer .signup_Wrapper li:nth-child(2) a {
		padding-right: 8px;
	}
	.headerProfileContainer ul li a {
		color: #000;
		font-size: 13px;
		padding: 6px 4px;
	}
	.languageDropdown .dropdown-menu {
		min-width: 6rem;
	}
	nav.navbar.bootsnav {
		background: transparent;
		width: 100%;
		float: left;
		padding-left: 0px;
    	padding-right: 0px;
	}
	.footer_item .footer_item_link {
		padding: 0px;
		text-align: left;
		width: 98px;
	}
	.footer_item .footer_item_link img.logo {
		margin-left: 32px;
	}
	.footer_item .footer_item_link .logo-footer {
		width: 88%;
	}
	nav.navbar.bootsnav .navbar-nav {
		background: #fff;
		margin-top: 10px;
	}
	nav.navbar.bootsnav .navbar-toggle {
		margin-top: 15px !important;
		background-color: transparent !important;
		color: #fff;
		margin-left: 0px !important;
	}
	.subsCriptionMid {
		border-left: 0px solid #7b6f91;
		border-right: 0px solid #7b6f91;
	}
	.search-containerMobile {
		position: relative;
		margin-top: -35px !important;
	}
	.search-containerMobile button {
		background: none;
		border: none;
	}
	.search-containerMobile input {
		display: none;
		float: left;
		width: 100%;
		border: 1px solid #b4b4b4;
		border-radius: 20px;
		padding: 6px;
	}
	.search-icon {
		border: 0;
		outline: none;
	}
	.searchMobileRight {
		position: absolute;
		z-index: 1;
		right: 0px;
		border-radius: 3%;
		border-top-left-radius: 3%;
		border-top-right-radius: 3%;
		border-bottom-right-radius: 3%;
		border-bottom-left-radius: 3%;
		padding: 5px 5px 9px;
	}
	.nav-pills {
		margin-top: 0px;
	}
	.search-container {
		margin-top: 0px;
		margin-left: 0%;
		width: 100% !important;
	}
	.search-container input[type=text],
	.search-container button {
		float: left;
		display: block;
		text-align: left;
		width: 100%;
		margin: 0;
		padding: 14px;
	}
	.loggedUserWrap {
		width: 77%;
	}
	.try-free-snovel-nav{
		width: 23%;
	}
	.btmHeader .search-container .searchToggle {
		padding: 6px 14px;
		margin-top:8px;
	}
	.search-container input[type=text] {
		border: 1px solid #ccc;
	}
	.search-container button {
		top: 5px;
		width: 20px;
		right: 10%;
		padding: 0px;
	}
	.search-container .searchButton_Wrapper {
		top: 12px;
	}
	.headerProfileContainer {
		margin-left: 0px;
		border: none;
		margin-bottom: 0px;
		padding-top: 0px;
	}
	.headerProfileContainer .profileText {
		display:none;
	}
	.profileImg {
		margin-left: 15px;
	}
	.headerRightCont {
		width: 74%;
		float: none;
	}
	.headerRightCont .navbar {
		display: initial;
		width: 100%;
    	float: left;
		padding-bottom: 8px;
		padding-top: 4px;
	}
	.topLanguage {
		width: 36%;
		border-left: none;
	}
	
	nav.navbar.bootsnav .navbar-toggle {
		position: absolute;
		left: 8%;
		z-index: 999;
		color: #000;
	}
	nav.navbar.bootsnav .navbar-toggle .fa-bars::before {
		color: #fff !important;
		left: 0%;
	}
	nav.navbar.bootsnav .navbar-toggle .fa-times::before {
		color: #fff !important;
	}
	.navbar-brand>img {
		display: block;
    	width: 70%;
    	margin-left: 15px;
	}
	nav.navbar.bootsnav .navbar-header {
		width: 100%;
	}
	.container>.navbar-header {
		margin-left: 0px !important;
	}
	.navbar-brand {
		padding: 14px 0px 0px;
	}
	.home .carousel {
		height: auto;
	}
	.nav-pills>li a {
		float: left;
		display: block;
		width: 100%;
	}
	.nav-pills>li {
		float: left;
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}
	.nav-pills {
		margin-left: 0px;
	}
	#snovel-app {
		margin-top: 85px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.snovelapp-left {
		width: 100% !important;
		margin-left: 0px;
		padding: 30px 10px;
		float: left;
	}
	span.cateTitle {
		margin-left: 0px;
	}
	.videoDesc {
		margin-top: 20px;
		padding-left: 0px;
		margin-left: -10px;
	}
	.nav-pills>li a {
		text-align: center;
	}
	ul.tabtop>li.active>a {
		top: auto !important;
		height: auto !important;
	}
	.videoPurchase a {
		float: none;
		width: 100%;
		display: block;
	}
	.videoPurchase {
		margin-top: 40px;
		margin-bottom: 30px;
	}
	.videoThumbDiv {
		float: none;
		margin-top: 0px;
	}
	.videoThumbTitleDiv {
		float: none;
		margin-top: 20px;
		text-align: center;
	}
	.videoThumbCateDiv {
		float: none;
		margin-top: 20px;
		text-align: center;
	}
	.latest-launch {
		float: left;
	}
	#services .latest-launch h2 {
		margin: 0px 15px 5px;
	}
	#services .editor-pick h2 {
		margin: 30px 15px 5px;
	}
	#services h2 {
		text-align: left;
		font-size: 22px;
	}
	#services .trending-week h2 {
		margin: 30px 15px 5px;
	}
	.trending-week {
		float: left;
	}
	.editor-pick {
		float: left;
	}
	#snovel-app {
		margin-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 30px;
		border-bottom: 1px solid #dddddd;
	}
	.snovelapp-right {
		width: 100% !important;
		margin-left: 0px;
		float: left;
	}
	.snovel-gift {
		float: left;
	}
	.gift-text {
		width: 182px;
	}
	.collaboration-points .director {
		top: 0px;
		left: 0px;
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 0px;
	}
	.directCircle {
		margin-right: auto;
		margin-left: auto;
		display: block;
		background: #fff;
	}
	.collaboration-points .collab {
		float: none;
	}
	.collaboration-points .director p {
		top: 58px;
    	font-size: 16px;
		left: -74px;
		width:30%;
	}
	.collaboration-points .writer {
		top: 0px;
		left: 0px;
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 0px;
	}
	.collaboration-points .writer p {
		top: 70px;
    	font-size: 14px;
    	left: 104%;
    	width: 30%;
	}
	.collaboration-points .publisher {
		top: 0px;
		left: 0px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0px;
	}
	.collaboration-points .publisher p {
		top: 90px;
    	font-size: 14px;
    	left: -74px;
    	width: 30%;
	}
	.collaboration-points .voice-artist {
		top: 0px;
		left: 0px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.collaboration-points .voice-artist p {
		top: 76px;
    	font-size: 14px;
    	width: 40%;
    	left: 100%;
	}
	.track-background {
		height: auto;
	}
	.track-background .title {
		margin-top: 20px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 100% !important;
	}
	.carousel-caption>p {
		line-height: 10px;
	}
	nav.navbar.bootsnav .navbar-collapse.collapse.in {
		width: 100%;
		background: #fff;
	}
	.audioBooks {
		margin-left: 0px;
	}
	.snovelOriginal {
		margin-left: 0px;
	}
	.prodcasts {
		margin-left: 0px;
	}
	span.subsCribeButton {
		float: none;
	}
	.each-track-cont h2 {
		text-align: center;
	}
	.each-track-cont p {
		text-align: center;
	}
	.track-btn .subsCribeButton {
		display: block;
		text-align: center;
		margin-bottom: 10px;
		float: none;
		width: 100%;
	}
	.track-btn .purchaseButton {
		display: block;
		text-align: center;
		margin-bottom: 30px;
		float: none;
		width: 100%;
	}
	.border-box {
		border-right: none;
	}
	.footer_item {
		margin-top: 25px;
		text-align: left;
	}
	.ftFirstCol {
		width: 100%;
	}
	.ftSecCol {
		width: 100%;
	}
	.list-unstyled.footer_menu>li {
		display: inline;
		padding: 0px 3px;
	}
	.list-unstyled.footer_menu>li a {
		display: inline;
		border-right: 1px solid #818183;
		padding: 0px 5px;
	}
	.list-unstyled.footer_menu>li:first-child a {
		padding-left: 0px;
	}
	.list-unstyled.footer_menu>li:last-child a {
		padding-right: 0px;
		border-right: none;
	}
	.border-box {
		height: auto;
		border-right: none;
		border-left: none;
		padding-left: 0%;
		padding-right: 0%;
		width: 100%;
	}
	.list-unstyled.footer_menu>li a:last-child {
		padding-left: 0px;
	}
	.nav-pills>li+li {
		margin-left: 0px;
	}
	.carousel-indicators {
		bottom: 0px;
	}
	.videoPrice {
		font-size: 25px;
		margin-top: 25px;
	}
	p.mobileDevice {
		display: block;
	}

	.footer_item p {
		float: right;
	}
	.list-inline.footer_social_icon {
		float: left;
		margin: -10px 0px;
	}
	.footer_social_icon .fa {
		height: 30px;
		width: 30px;
		font-size: 12px;
		padding: 10px;
	}
	.nav-pills>li {
		float: left;
		display: block;
		width: 33.33%;
		margin-bottom: 5px;
	}
	.nav-pills {
		margin-left: 0px;
	}
	
	.tabliCntDesktop {
		display: none;
	}
	.tabTitleMobile {
		position: relative;
	}
	span.scoreTab {
		position: absolute;
		right: 5px;
		top: 0px;
		color: #da145a;
	}
	span.tabTitleMobile {
		text-align: center;
	}
	.snovelOriginal {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
	}
	.deskTopViewTab1 {
		display: none;
	}
	.deskTopViewTab2 {
		display: none;
	}
	.deskTopViewTab3 {
		display: none;
	}
	.browseAllTab1 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseAllTab1 span {
		float: right;
	}
	.browseLessTab1 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseLessTab1 span {
		float: right;
	}
	.browseAllTab2 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseAllTab2 span {
		float: right;
	}
	.browseLessTab2 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseLessTab2 span {
		float: right;
	}
	.browseAllTab3 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseAllTab3 span {
		float: right;
	}
	.browseLessTab3 {
		display: block;
		width: 100%;
		color: #da145a;
		cursor: pointer;
		float: left;
		font-size: 12px;
	}
	.browseLessTab3 span {
		float: right;
	}
	.bottombar {
		width: 25%;
		float: left;
		padding-left: 0px;
		padding-right: 0px;
	}
	.browseLessTab1 span,
	.browseLessTab2 span,
	.browseLessTab3 span {
		display: none;
	}
	.hrTab {
		display: none;
	}
	.audioDiv p {
		display: none;
	}
	.audioDiv h4 {
		font-size: 12px !important;
		margin: 0px 0px 20px;
	}
	span.subsCribeButton {
		float: left;
		width: 86%;
		margin-right: 0px;
	}
	span.purchaseButton {
		float: left;
		width: 46%;
	}
	.browseLatest_Lauch .videoDesc {
		margin-top: 10px;
	}
	.videoPurchase a {
		font-size: 12px;
		padding: 5px;
	}
	.browseTopSliderWrp .popupShareButton {
		left: -102px;
	}
	.tabliCnt {
		display: none;
	}
	.browseDropDownShow {
		top: 21px;
		right: 10px;
	}
        .videoPlayList {
		padding: 15px 15px 10px;
	}
	.videoPlayP {
		width: 15px;
	}
	.videoPlayTitle {
		width: 58%;
		margin-left: 13px;
		font-size: 100%;
	}
	.videoPlayTitle span {
		margin-right: 5px;
	}
	.product-summery {
		padding-left: 5px;
	}
	.productDetailsMain_Wrapper .popularImage_Wrap {
		padding:0px;
	}
	.popularImage_Wrapper {
		height: 100%;
	}
	.homeTabs .container.mobileRemovePadding {
		padding: 0px !important;
		max-width: 100%;
	}
	.homeTabs li.nav-item {
		width: 33.333%;
	}
	.homeTabs {
		padding: 0px 0px 40px;
	}
	.homeTabs .nav-pills .nav-item {
		margin-right: 0px;
	}
	.mobileRemovePadding .nav-pills li:nth-child(2) {
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
	}
	.popularComponant_Wrapper .nav li {
		margin-right: 0px;
	}
	.starRating {
		width: 44%;
	}
	.browseLatest_Lauch .videoPurchase span.subsCribeButton,
	.popularComponant_Wrapper .videoPurchase span.subsCribeButton {
		width: 42%;
	}
	.browseLatest_Lauch .videoPurchase .purchaseButton a,
	.popularComponant_Wrapper .videoPurchase .purchaseButton a {
		padding: 8px 17px;
	}
	.browseLatest_Lauch .videoPurchase {
		margin-top: 10px;
		margin-bottom: 15px;
	}
	.track-btn {
		display: inherit;
	}
	.browseTopSliderWrp {
		margin-bottom: 0px;
	}
	.browseType_Wrapper .homeTabs {
		padding: 0px 0px 10px;
	}
	.browseReady_Begin .track-btn span.subsCribeButton {
		width: 45%;
    	float: left;
	}
	.readyBrw .track-btn .purchaseButton {
		width: 45%;
		padding: 11px 36px 12px 36px;
	}
	.owl-item .videoThumbTitleDiv {
		margin-top: 14px;
		text-align: left;
	}
	.browseType_Wrapper .videoThumbCateDiv {
		float: left;
		margin-top: 2px;
		text-align: left;
		margin-bottom: 20px;
		display: none;
	}
	.browseType_Wrapper .videoThumbDiv {
		margin-bottom: 30px;
	}
	.browseType_Wrapper .videoThumbTitleDiv {
		margin-top: 10px;
		text-align: left;
		display: none;
	}
	.unlimiTedAnnul {
		margin-bottom: 20px;
	}
	.subsCriBtnBtm {
		width: 38%;
		display: table;
	}
	.toplogoDiv {
		width: 20%;
		max-width: 19%;
	}
	topcontentDiv{
		max-width: 80%;
	}
	.contactLogo img {
		margin-top: 10px;
		width: 94px;
	}
	.topcontentDiv h4 {
		margin-bottom: 10px;
	}
	.topcontentDiv h2 {
		margin-bottom: 20px;
	}
	.contactFormDiv {
		margin-top: 36px;
	}
	.snovelSignUpBtm .visitFaq {
		width: 50%;
	}
	.unlimiTedAnnul h2 {
		font-size: 23px;
	}
	.subscriptionBox_Wrapper {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.subscriptionBox_Wrapper:nth-child(2) {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.unlimiTedAnnulBx p, .unlimiTedAnnulBx {
		background: #212433;
		color: #fff;
		font-size: 21px;
		font-family: 'Roboto', sans-serif;
		margin-bottom: 0px;
		font-weight: 400;
		line-height: 24px;
	}
	.unlimiTedAnnulBx {
		min-height: 84px;
	}
	.unlimiTedAnnul span.subsCribeButton {
		padding: 14px 25px 14px 25px;
		border-radius: 20px;
	}
	.mySnovelFaq .visitFaq {
		width: 50%;
	}
	#owlFaq.owl-carousel .owl-nav .owl-prev {
		left: 8px;
	}
	#owlFaq.owl-carousel .owl-nav .owl-next {
		right: 8px;
	}
	.authorDivWrp {
		margin-top: 0px;
		padding: 50px 20px;
	}
	.authorDivImg img {
		max-width: 80%;
	}
	.authorDivCnt p {
		margin-top: 2%;
	}
	.authorProdcutSlider_Wrap {
		padding-left: 15px;
		padding-right: 15px;
	}
	.authorSlider .videoThumbDiv img {
		height: 180px;
	}
	.homeTabs .tab-content {
		height: 180px;
		overflow: hidden;
	}
	.audioDiv img {
		margin-bottom: 8px;
	}
	.app-btn {
		max-width: 100%;
		padding-top: 25px;
		display: table;
		width: 100%;
	}
	.iphone-app-btn {
		width: 48%;
	}
	.android-app-btn {
		width: 48%;
	}
	.iphone-app-btn p {
		width: 100%;
	}
	.android-app-btn p {
		width: 100%;
	}
	.browseType_Wrapper .homeTabs .tab-content {
		height: auto;
		overflow: hidden;
	}
	.browseType_Wrapper .homeTabs .nav-pills .nav-link.active {
		margin-top: 0px;
		padding: 20.2px 15px;
	}
	.popularProduct_Slider .owl-nav .owl-next {
		float: right;
		margin-right: -14px;
	}
	.popularProduct_Slider .owl-nav .owl-prev {
		float: left;
		margin-left: -14px;
	}
	.browseLatest_Lauch .owl-nav {
		top: 60px;
	}
	.billingProfile a {
		margin-left: 0px;
	}
	.planRight .billingProfile {
		width: 100%;
    	float: left;
    	padding-top: 15px;
	}
	.libraryBskDiv {
		width: 144px;
		padding-top: 0px;
		margin-top: 2px;
	}
	.react-multi-carousel-list ul li {
		margin-left: 12px;
   		margin-right: 12px;
    	width: 218px !important;
	}
	.popupShareButton {
		left: -66px;
	}
	.popupShareButton:before {
		border-width: 0px 6px 10px 0px;
    	top: -6px;
    	left: auto;
    	right: 6px;
	}
	.mySnovelVideo .videoThumbDiv {
		margin-top: 30px;
	}
	.myprofilePopRgt span.subsCribeButton {
		float: left;
		width: 18%;
		margin-right: 20px;
	}
	.myprofilePopLocation span.subsCribeButton a {
		padding: 10px 17px;
		width: 100%;
	}
	.myProfilePopLocDiv span.spanIndBtm {
		max-width: 460px;
	}
	.browseMenuWrapper .dropdown-menu {
		width: 96%;
	}
	.aboutMedia {
		padding-left: 15px;
    	padding-right: 15px;
	}
	.aboutTopWrp {
		padding-top: 34px;
	}
	.browseMenuWrapper .dropdown-menu.show {
		position: absolute;
    	top: 0px !important;
    	left: 0px !important;
    	transform: none !important;
    	width: 100%;
	}
	.category_section {
		width: 32%;
		float: left;
	}
	.homeTabs.tryFree-snovel-wrapper .nav-pills .nav-link.active {
		padding: 21.4px 15px;
		margin-top: 0px;
	}
	.homeTabs .audioBook-Wrapper-freeSnovel {
		height: auto;
	}
	.freeSubscription-wrap .billingTopWrp {
		margin-bottom: 20px;
	}
	.browseType_Wrapper .innerProductsTab.nav-tabs .nav-link {
		padding: 0px 10px 10px !important;
	}
	#libraryTabOuter .libraryBskDiv {
		margin-top: 13px;
		width: 127px;
		float:left;
	}
	.library-subscription .liraryBtnFav {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.mySnovelVideo {
		padding-bottom: 10px;
	}
	.library-player {
		margin-bottom: 40px;
	}
	.signup_Wrapper .nav {
		justify-content: flex-end;
		margin-right: 0px;
	}
	.headerLeft_Section {
		width: 70%;
	}
	.headerRight_Section {
		width: 30%;
	}
	button.google-login-button {
		padding: 10px 2%;
	}
	.mySnovelVideo.purchasedLibraryWrap .videoThumbDiv {
		width: 30%;
	}
	.mySnovelVideo .videoDesc {
		width: 70%;
		margin-top: 32px;
		padding-left: 26px;
	}
	.headerLeft_Section  .navbarLeft {
		display: initial;
		width: 50%;
	}
	.headerLeft_Section  .navbar-toggler {
		float: left;
	}
	.navbar-toggler {
		display: block !important;
	}
	.navbar.bootsnav .container {
		justify-content: unset;
	}
	.owl-nav {
		display: none;
	}
	.desktopMenu {
		position: absolute;
		left: 0px;
		z-index: 99;
		width: 100%;
		top: 100px;
	}
	.headerRightCont .navbar-nav li:nth-child(1) {
		padding-left: 24px;
	}
	.headerRightCont .navbar-nav li {
		padding: 7px 25px 6px;
	}
	.productDetailsMain_Wrapper span.subsCribeButton {
		width: 28%;
	}
	.productDetailsMain_Wrapper .videoPurchase .purchaseButton a {
		padding: 5px 4px;
	}
	.productDetailsMain_Wrapper .mobileButtons span.purchaseButton {
		width: 32%;
	}
	.productDetails-container {
		padding-left:15px;
		padding-right:15px;
	}
	.productDetailsMain_Wrapper span.purchaseButton {
		width: 30%;
	}
	.browseDetailsVideo span {
		margin: 2px 4px;
	}
	.productDetailsMain_Wrapper .videoPurchase {
		margin-bottom:10px;
	}
	.haveQuery h2 {
		font-size: 18px;
	}
	.formBillLft {
		width: 100%;
	}
	.formBillRgt {
		width: 100%;
	}
	.applyCoupen {
		margin-left: 12px;
	}
	.myprofilePopLft {
		width: 120px;
		margin-bottom: 20px;
	}
	.field-section-outer {
		margin-left: 15px;
	}
	.common-modal.modal-dialog .modal-body h4.modal-title {
		margin-left: 15px;
	}
	.myProfilePopLocDiv {
		margin-left: 15px;
	}
	.myprofilePopLocation span.purchaseButton {
		padding: 12px 17px;
	}
	.react-datepicker-wrapper {
		width: 100%;
	}
	.myprofilePopLft img {
		margin-top: 0px;
	}
	.planNameDiv {
		float: left;
		margin-left: 17px;
		width: 76%;
	}
	.pagenot-found-wrapper span.subsCribeButton {
		width: 33%;
		text-align: center;
	}
	.owl-carousel .owl-item .sliderTrack_Wrap {
		top: 50% !important;
		transform: translateY(-50%);
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.mySnovelBx p.snovel-run-time{
		margin-left: 20px;
	}
}

@media(min-width:768px){
	.autoRenewalColumn .billCheckBx{
		margin-left: 13px !important;
	}
}

@media (max-width: 997px) and (min-width: 750px) {
	.container {
		width: 100% !important;
		max-width: 100% !important;
	}
}

@media screen and (max-width: 991px) { 
	.videoThumbTitleDiv {
		display: none;
	}
	.videoThumbCateDiv {
		display: none;
	}
	.sliderProductTitle, .sliderProductAuthor, .sliderProductCategory {
		display: none;
	}
	.navbar-toggler .navbar-toggler-icon {
		background-image: url(/../../images/Hamburger_icon.svg);
	}

}


@media screen and (device-aspect-ratio: 375/667) {
	.desktopMenu {
		top: 96px;
	}
}
@media screen and (device-aspect-ratio: 375/812) {
	.desktopMenu {
		top: 82px;
    	width: 375px;
	}
}

@media(max-width:480px){
	.yourPrdNameCheckLft.left__wrapper {
		width: 43%;
		color: #FFFFFF;
		padding-top: 3px;
	}
	.yourPrdNameCheckRgt.right__wrapper{
		width: 57%;
	}
	.right__wrapper input[type="text"] {
		width: 90%;
	}
	.right__wrapper .applyCoupen {
		margin: 10px 0 0 0px;
		width: 90%;
	}
	.yourPrdNameCheckRgt.right__wrapper .cuponErrorMessage{
		width:90%;
	}
	.yourGiftProceed,
	.yourGiftProceed a,
	.yourGiftCancel,
	.yourGiftCancel a{
		width: 270px;
	}
}

@media (min-width: 1280px) and (min-width: 1399px) {
	#bannerModel-wrapper {
	    max-width: 500px;
	}
}
@media (min-width: 1280px) and (min-width: 1399px) {
	#bannerModel-wrapper {
	    max-width: 500px;
	}
}